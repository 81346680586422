<template>
  <v-expansion-panel-content v-if="!isLocal">
    <div slot="header">
      <b>{{ indexPrefix ? indexPrefix + '.' : '' }} Select {{ displayText }} questions</b>
    </div>
    <v-card class="bg-gray">
      <v-card-text>
        <div>
          <v-layout>
            <v-flex xs12>
              <v-card class="card-box-shadow-none">
                <v-card-title class="pt-0 pb-0">
                  <v-text-field
                    v-model="filterValue"
                    single-line
                    label="Search available questions..."
                    prepend-icon="search"
                  ></v-text-field>
                  <v-tooltip v-if="!isUserRoleClientManager" top>
                    <template #activator="{ on }">
                      <v-icon
                        color="primary"
                        :style="{ cursor: 'pointer' }"
                        v-on="on"
                        @click.stop="addEmergencyQuestion()"
                      >
                        add
                      </v-icon>
                    </template>
                    <span>Add Question</span>
                  </v-tooltip>
                  <v-dialog
                    v-if="showEmergencyQuestionDialog"
                    v-model="showEmergencyQuestionDialog"
                    content-class="v-dialog--scrollable"
                    max-width="650"
                    persistent
                  >
                    <EmergencyQuestion
                      :item="getEmergencyQuestion"
                      :trades="trades"
                      :submited-policy-question-list="submitedPolicyQuestionList"
                      :job-type="jobType"
                      :third-party-detail="thirdPartyDetail"
                      @closeEmergencyQuestion="showEmergencyQuestionDialog = !showEmergencyQuestionDialog"
                      @saveEmergencyQuestion="onSaveEmergencyQuestion"
                    ></EmergencyQuestion>
                  </v-dialog>
                </v-card-title>
                <v-container fluid pt-0 class="drag-container">
                  <v-layout wrap class="questions-list-p">
                    <v-flex
                      v-for="question in emergencyQuestionsList"
                      v-show="String(question.text).toLowerCase().indexOf(filterValue.toLowerCase()) >= 0"
                      :key="question.id"
                      xs6
                      md6
                      lg4
                      class="mb-2"
                      :class="question.isSelected ? 'selected' : ''"
                    >
                      <v-card class="bg-gray ml-2 card-box-shadow-none" @click.native="onQuestionClick(question)">
                        <v-icon v-show="question.isSelected" :style="{ cursor: 'pointer' }" color="primary">
                          check_circle
                        </v-icon>
                        <v-icon v-show="!question.isSelected" :style="{ cursor: 'pointer' }" color="primary">
                          panorama_fish_eye
                        </v-icon>
                        <v-card-title>
                          {{ question.text }}
                        </v-card-title>
                      </v-card>
                      <v-card-actions v-if="!isUserRoleClientManager" class="action-que-icon py-0">
                        <v-spacer></v-spacer>
                        <v-btn flat icon @click.stop="editEmergencyQuestion(question)">
                          <v-icon color="primary">edit</v-icon>
                        </v-btn>
                        <v-btn flat icon @click="onDeleteEmergencyQuestionClick(question.id)">
                          <v-icon class="mx-1" color="primary">delete</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </v-flex>
          </v-layout>
        </div>
        <div class="sub-module">
          <v-expansion-panel class="select-options elevation-0">
            <EmergencyQuestionGenerator
              v-for="(item, index) in emergencyDetails"
              :key="item.emergencyDetailId"
              class="mb-3 elevation-1"
              :policy-id="policyId"
              :entity-type="item.entityType"
              :insurer-id="item.insurerId"
              :emergency-id="item.emergencyId"
              :emergency-detail-id="item.emergencyDetailId"
              :display-text="item.displayText"
              :index-prefix="getPrefix(index)"
              :submited-policy-question-list="submitedPolicyQuestionList"
              :is-local="item.isLocal"
              :trades="trades"
              :job-type="jobType"
              :third-party-detail="thirdPartyDetail"
            ></EmergencyQuestionGenerator>
          </v-expansion-panel>
        </div>
      </v-card-text>
    </v-card>
  </v-expansion-panel-content>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import QuestionController from '@/api/questionController'
import EmergencyQuestionModel from '@/models/policy/EmergencyQuestionModel'
import PolicyQuestionModel from '@/models/policy/PolicyQuestionModel'
import eventBus from '@/common/bus'
import { QuestionEntityType } from '@/common/enums'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import EmergencyQuestion from '@/components/EmergencyQuestion.vue'
import TradeModel from '@/models/policyHolder/TradeModel'
import { JobType } from '@/models/types'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'

@Component({
  name: 'EmergencyQuestionGenerator',
  components: { EmergencyQuestion },
})
export default class EmergencyQuestionGenerator extends Vue {
  @Prop() private entityType: string
  @Prop() private insurerId: number
  @Prop() private emergencyId: number
  @Prop() private emergencyDetailId: number
  @Prop() private displayText: string
  @Prop() private emergencyDetails: any[]
  @Prop() private indexPrefix: string
  @Prop() private policyId: number
  @Prop() private submitedPolicyQuestionList: PolicyQuestionModel[]
  @Prop() private isLocal: boolean
  @Prop() private trades: TradeModel[]
  @Prop() private jobType: JobType
  @Prop() private thirdPartyDetail: EmergencyThirdPartyTrade[]

  private filterValue = ''
  private emergencyQuestionsList: EmergencyQuestionModel[] = []
  private emergencyQuestion: EmergencyQuestionModel = new EmergencyQuestionModel()
  private showEmergencyQuestionDialog = false

  private created(): void {
    this.getQuestions()
  }

  @Watch('submitedPolicyQuestionList')
  private watch() {
    this.submitedPolicyQuestionList.forEach((element) => {
      const item: EmergencyQuestionModel | undefined = this.emergencyQuestionsList.find(
        (e: EmergencyQuestionModel) => e.id === element.questionId
      )
      if (item) {
        Vue.set(item, 'isSelected', true)
      }
    })
  }

  private getQuestions() {
    if (this.entityType) {
      if (!this.isLocal && this.entityType === QuestionEntityType[QuestionEntityType.Policy]) {
        this.GetPolicyLevelQuestions()
      } else if (!this.isLocal && this.entityType === QuestionEntityType[QuestionEntityType.Emergency]) {
        this.GetEmergencyLevelQuestions()
      } else if (!this.isLocal && this.entityType === QuestionEntityType[QuestionEntityType.EmergencyDetail]) {
        this.GetEmergencyDetailLevelQuestions()
      }
    }
  }

  @Watch('policyId')
  @Watch('insurerId')
  private onInsurerChanged() {
    this.getQuestions()
  }

  private get getEmergencyQuestion(): EmergencyQuestionModel {
    return this.emergencyQuestion
  }

  private mounted(): void {
    const self = this
    eventBus.$on('removeEmergencyQuestionSelection', (question: PolicyQuestionModel) => {
      if (question && question.questionId && question.entityType === self.entityType) {
        const item: EmergencyQuestionModel | undefined = self.emergencyQuestionsList.find(
          (e: EmergencyQuestionModel) => e.id === question.questionId && e.entityType === question.entityType
        )
        if (item) {
          Vue.set(item, 'isSelected', false)
        }
      }
    })
  }

  private GetPolicyLevelQuestions(): void {
    const self = this
    QuestionController.GetPolicyLevelQuestions(this.insurerId, this.jobType)
      .then((res: EmergencyQuestionModel[]) => {
        if (res) {
          self.emergencyQuestionsList = res
          this.setQuestionPolicyId(self.emergencyQuestionsList)
        }
        return []
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading policy question(s), please try again', true)
      })
  }

  private GetEmergencyLevelQuestions(): void {
    const self = this
    QuestionController.GetEmergencyLevelQuestions(this.insurerId, this.emergencyId)
      .then((res: EmergencyQuestionModel[]) => {
        if (res) {
          self.emergencyQuestionsList = res
          this.setQuestionPolicyId(self.emergencyQuestionsList)
        }
        return []
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading emergency question(s), please try again', true)
      })
  }

  private GetEmergencyDetailLevelQuestions(): void {
    const self = this
    QuestionController.GetEmergencyDetailLevelQuestions(this.insurerId, this.emergencyDetailId)
      .then((res: EmergencyQuestionModel[]) => {
        if (res) {
          self.emergencyQuestionsList = res
          this.setQuestionPolicyId(self.emergencyQuestionsList)
        }
        return []
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading emergency detail question(s), please try again', true)
      })
  }

  private setQuestionPolicyId(emergencyQuestionsList: EmergencyQuestionModel[]): void {
    const self = this
    emergencyQuestionsList.forEach((e: EmergencyQuestionModel) => {
      Vue.set(e, 'policyId', self.policyId)
      Vue.set(e, 'entityType', self.entityType)
      Vue.set(e, 'entityName', self.displayText)

      const question: PolicyQuestionModel | undefined = self.submitedPolicyQuestionList.find(
        (a: PolicyQuestionModel) => a.entityType === this.entityType && a.questionId === e.id
      )
      if (question) {
        Vue.set(e, 'isSelected', true)
      }
    })
  }

  private onQuestionClick(question: EmergencyQuestionModel): void {
    if (this.isUserRoleClientManager) {
      return
    }
    Vue.set(question, 'isSelected', !question.isSelected)
    Vue.set(question, 'entityType', this.entityType)
    Vue.set(question, 'entityName', this.displayText)
    if (!question.policyId) {
      Vue.set(question, 'policyId', this.policyId)
    }
    eventBus.$emit('addOrRemoveEmergencyQuestion', question)
  }

  private getPrefix(index: number): string {
    return Shared.alphabet[index]
  }

  private addEmergencyQuestion(): void {
    const emergencyQuestionItem = new EmergencyQuestionModel()
    emergencyQuestionItem.policyId = this.policyId
    emergencyQuestionItem.entityType = this.entityType
    emergencyQuestionItem.insurerId = this.insurerId
    emergencyQuestionItem.emergencyId = this.emergencyId
    emergencyQuestionItem.emergencyDetailId = this.emergencyDetailId
    // set default answertype as text for Assign Trade Type againts to Answer Comparison Value
    emergencyQuestionItem.answerTypeId = 1
    emergencyQuestionItem.jobType = this.jobType
    this.emergencyQuestion = emergencyQuestionItem
    this.showEmergencyQuestionDialog = true
  }

  private editEmergencyQuestion(question: EmergencyQuestionModel) {
    const emergencyQuestionItem = new EmergencyQuestionModel()
    emergencyQuestionItem.answerTypeId = question.answerTypeId
    emergencyQuestionItem.id = question.id
    emergencyQuestionItem.additionalValues = question.additionalValues
    emergencyQuestionItem.unit = question.unit
    emergencyQuestionItem.text = question.text
    emergencyQuestionItem.policyId = this.policyId
    emergencyQuestionItem.entityType = this.entityType
    emergencyQuestionItem.insurerId = this.insurerId
    emergencyQuestionItem.emergencyId = this.emergencyId
    emergencyQuestionItem.emergencyDetailId = this.emergencyDetailId
    emergencyQuestionItem.note = question.note
    emergencyQuestionItem.questionTrades = question.questionTrades
    emergencyQuestionItem.questionAsbestosEffects = question.questionAsbestosEffects
    emergencyQuestionItem.isSelected = question.isSelected
    emergencyQuestionItem.jobType = this.jobType
    emergencyQuestionItem.conditionalEmergencyTypeId = question.conditionalEmergencyTypeId
    emergencyQuestionItem.conditionalEmergencyDetailId = question.conditionalEmergencyDetailId
    emergencyQuestionItem.operatorId = question.operatorId
    emergencyQuestionItem.comparisonValue = question.comparisonValue
    this.emergencyQuestion = emergencyQuestionItem
    this.showEmergencyQuestionDialog = true
  }

  private onSaveEmergencyQuestion(emergencyQuestion: EmergencyQuestionModel): void {
    const self = this
    QuestionController.SaveQuestion(emergencyQuestion)
      .then((res: EmergencyQuestionModel) => {
        emergencyQuestion.id = res.id
        res.isSelected = emergencyQuestion.isSelected
        const editedItemIndex: number = self.emergencyQuestionsList.findIndex((c) => c.id === res.id)
        if (editedItemIndex >= 0) {
          self.emergencyQuestionsList.splice(editedItemIndex, 1, res)
        } else {
          self.emergencyQuestionsList.push(res)
        }
        self.showEmergencyQuestionDialog = !self.showEmergencyQuestionDialog

        // update selected emergency question list, when question set as selected and being updated
        if (res.isSelected) {
          Vue.set(res, 'entityType', this.entityType)
          Vue.set(res, 'entityName', this.displayText)
          if (!res.policyId) {
            Vue.set(res, 'policyId', this.policyId)
          }
          eventBus.$emit('editEmergencyQuestion', res)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private onDeleteEmergencyQuestionClick(questionId: number): void {
    Shared.confirmationPopup.open(
      'Do you really want to delete question? It will delete from all policy schedule for this insurer.',
      '',
      '',
      '',
      '',
      this,
      'deleteEmergencyQuestion',
      questionId
    )
  }

  private deleteEmergencyQuestion(questionId: number): void {
    if (questionId) {
      QuestionController.DeleteEmergencyQuestion(questionId.toString())
        .then((res: boolean) => {
          if (res) {
            const question: EmergencyQuestionModel | undefined = this.emergencyQuestionsList.find(
              (e: EmergencyQuestionModel) => e.id === questionId
            )
            if (question) {
              const index: number = this.emergencyQuestionsList.indexOf(question)
              this.emergencyQuestionsList.splice(index, 1)
              if (question.isSelected) {
                eventBus.$emit('deleteEmergencyQuestion', question)
              }
            }
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error deleting emergency question, please try again', true)
        })
    }
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }
}
</script>

<style scoped>
.drag-container .v-card {
  transition: all 0.3s;
  cursor: move;
}
.select-options .drag-container .v-card {
  cursor: pointer;
}
.drag-container .v-card:hover,
.drag-container .v-card.selected {
  background: #d8d8d8;
}

.sub-module {
  margin: 20px 0 0 0px;
}
.sub-module .expansion-panel__container {
  background: #eee;
}
.theme--light.v-list,
.theme--light .v-list {
  padding: 0px;
}
.questions-list-p .flex {
  position: relative;
  transition: all 0.3s linear;
}
.questions-list-p .flex .bg-gray .v-icon {
  position: absolute;
  left: 4px;
  top: 4px;
  color: #607d8c;
}
.questions-list-p .flex .v-card {
  height: 100% !important;
}
.questions-list-p .flex .v-card__title {
  padding: 16px 100px 16px 35px;
}
.questions-list-p .flex.selected {
  padding: 10px;
}
.questions-list-p .flex.selected .v-card {
  background-color: #d8d8d8 !important;
}
.questions-list-p .flex.selected .bg-gray .v-icon {
  left: -8px;
  top: -8px;
}
.questions-list-p .flex.selected .v-card__title {
  padding: 16px 100px 16px 35px;
}
.action-que-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 0px;
  top: 0px;
}
.action-que-icon >>> .v-icon {
  position: static !important;
  font-size: 20px;
}
</style>
