<template>
  <div class="dashboard">
    <v-layout wrap :class="openJobView ? 'hide-dashboard' : 'show-dashboard'">
      <v-flex xs12>
        <v-container fluid grid-list-lg pt-0 pa-0>
          <v-layout wrap job-info request-info>
            <DashboardTopTile
              v-for="key in Object.keys(getTopTiles)"
              v-bind="getTopTiles[key]"
              :key="key"
              dashboard-tile-view="FinancialSummary"
              @onTopTileFilter="onTopTileFilter"
            />
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12 class="gridView mt-4">
        <v-layout wrap>
          <v-flex xs4 request-info>
            <h3>
              {{
                getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ReadyForApproval]
                  ? 'Ready For Approvals'
                  : getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ToReview]
                  ? 'Awaiting Review from CET'
                  : getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.Disputed]
                  ? 'Disputed'
                  : 'Ready for Invoice'
              }}
              Jobs
            </h3>
          </v-flex>
          <v-flex v-if="getJobListLength > 0" class="text-xs-right search-controls">
            <div ref="search-job" class="search-job">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
            <template v-if="hasInvoicingSkill">
              <v-btn
                v-if="
                  getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ReadyForApproval]
                "
                color="primary"
                class="ma-0 ml-2 mt-2 approveJob-btn"
                :disabled="isLoading"
                @click="openConfirmationForJobApprove(financialRequestStatus[financialRequestStatus.Approve])"
              >
                Approve
              </v-btn>
              <v-btn
                v-if="getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.Disputed]"
                color="primary"
                class="ma-0 ml-2 mt-2 disputedJob-btn"
                :disabled="isLoading"
                @click="onConfirmDisputingJob"
              >
                Approve
              </v-btn>
              <v-btn
                v-if="
                  getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ReadyForApproval]
                "
                color="primary"
                class="ma-0 ml-2 mt-2 rejectJob-btn"
                :disabled="isLoading"
                @click="openJobRejectReasonDialogue()"
              >
                Reject
              </v-btn>
              <v-btn
                v-if="getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ToReview]"
                color="primary"
                class="ma-0 ml-2 mt-2 reviewComplete-btn"
                :disabled="isLoading"
                @click="openConfirmationForJobToReview(financialRequestStatus[financialRequestStatus.ReviewComplete])"
              >
                Review Complete
              </v-btn>
              <template
                v-if="
                  getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ReadyForInvoice] &&
                  selectedJobList.length > 0
                "
              >
                <v-btn color="primary" class="ma-0 ml-2 mt-2 export-bordereau" @click="openInvoiceDialog">
                  Preview Invoice
                </v-btn>
              </template>
            </template>
          </v-flex>
          <v-flex xs12 mt-2 class="elevation-1">
            <div class="job-list grey">
              <v-data-table
                ref="jobFinancialDataTable"
                :key="jobList.length"
                v-model="selectedJobList"
                :headers="headers"
                :items="jobList"
                :loading="isLoading"
                :pagination.sync="pagination"
                item-key="clientInvoiceDetailId"
                :select-all="hasInvoicingSkill"
                class="job-table jobFinancialDataTable"
                name="jobFinancialDataTable"
                :search="search"
              >
                <v-progress-linear slot="progress" :color="tileColorWithActiveFilter" indeterminate></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <tr :active="props.selected" class="record-item" @click="props.selected = !props.selected">
                    <td v-if="hasInvoicingSkill">
                      <v-checkbox :input-value="props.selected" color="primary" hide-details></v-checkbox>
                    </td>
                    <td>
                      <b>
                        <a
                          href="Javascript:void(0)"
                          name="openJobDetailButton"
                          class="secondary--text link-jobId"
                          @click="redirectToJob(props.item.jobId)"
                          @click.stop
                        >
                          {{ props.item.jobId }}
                        </a>
                      </b>
                      <span v-if="props.item.clientNotes && props.item.clientNotes.length > 0" class="pl-2">
                        <v-tooltip right content-class="yellow lighten-2 grey--text text--darken-4 body-2">
                          <template #activator="{ on }">
                            <v-icon class="icon-arrow md-icon" v-on="on">info</v-icon>
                          </template>
                          <span>
                            <div v-for="(note, index) in props.item.clientNotes" :key="index">
                              <h3 class="mb-2">Note {{ index + 1 }}:</h3>
                              <p class="mb-2">- {{ note }}</p>
                            </div>
                          </span>
                        </v-tooltip>
                      </span>
                    </td>
                    <td>{{ props.item.claimManagementCompany ? props.item.claimManagementCompany : '-' }}</td>
                    <td>{{ props.item.insurer }}</td>
                    <td>{{ props.item.productDescription ? props.item.productDescription : '-' }}</td>
                    <td class="text-right">{{ getFormatedCurrency(props.item.totalCost) }}</td>
                    <td>{{ props.item.policyHolderName }}</td>
                    <td>{{ props.item.postCode }}</td>
                    <td
                      v-if="getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.Disputed]"
                    >
                      {{ props.item.disputedReason }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <div v-if="isLoading" class="loader-content">
        <v-progress-circular
          class="loading-spinner"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-layout>
    <PartialJobView
      ref="refPartialJobView"
      :job-id="selectedJobIdToExpand"
      @closeJobView="closeJobView"
    ></PartialJobView>
    <!-- dialog - dispute job -->
    <v-dialog
      v-if="showDisputeDialog"
      v-model="showDisputeDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Dispute Job</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDisputeDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-4 pt-4">
          <v-layout wrap>
            <v-flex xs12 pr-2>
              <v-text-field
                v-model="disputedReason"
                v-validate="'required'"
                label="Dispute Reason"
                required
                class="required"
                data-vv-scope="jobFinancialSummaryScope"
                data-vv-name="Dispute Reason"
                name="disputeReason"
                :error-messages="errors.collect('Dispute Reason')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="showDisputeDialog = false">Close</v-btn>
          <v-btn
            color="primary"
            :loading="onSubmitLoading"
            :disabled="onSubmitLoading"
            class="mr-0 submitForRejectedJob-button"
            @click.native="rejectJobs"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- export to invoice dialog -->
    <v-dialog
      v-if="exportToInvoiceDialog"
      v-model="exportToInvoiceDialog"
      max-width="600"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Export to Invoice</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat @click="closeInvoiceDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog">
          <v-container class="py-0">
            <v-flex xs12>
              <span>Create a new Invoice for job {{ selectedJobList.length ? selectedJobList[0].jobId : '' }}?</span>
            </v-flex>
            <v-layout xs12 wrap>
              <v-flex xs4>
                <v-container class="px-0 pb-0">
                  <span v-if="selectedBordereauId" class="bordereau-id">{{ selectedBordereauId }}</span>
                  <v-progress-linear v-else height="4" indeterminate />
                </v-container>
              </v-flex>
              <v-flex xs4>
                <v-combobox
                  v-model="salesLedgerCode"
                  v-validate="'required'"
                  :label="salesLedgerCodeList.length > 0 ? 'Select Sales Ledger Code' : 'Create Sales Ledger Code'"
                  :items="salesLedgerCodeList"
                  maxlength="20"
                  required
                  class="required salesLedgerCode"
                  data-vv-scope="jobFinancialSummaryScope"
                  data-vv-name="Sales Ledger Code"
                  name="salesLedgerCode"
                  :error-messages="errors.collect('Sales Ledger Code')"
                ></v-combobox>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <BordereauPreviewTable
                :selected-job-list="selectedJobList"
                :selected-bordereau-id="selectedBordereauId"
              />
            </v-flex>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <template>
            <v-btn color="primary" flat @click="closeInvoiceDialog">Cancel</v-btn>
            <v-btn
              class="create-bordereau"
              color="primary"
              :disabled="bordereauGenerateLoader"
              :loading="bordereauConfirmLoader"
              @click="confirmCreateInvoice"
            >
              Create
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import Chart from 'chart.js'
import DashboardTopTile, { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import Shared from '@/common/shared'
import { JobFinancialSummaryWidget, FinancialRequestStatus } from '@/common/enums'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import SIJobFinancialSummaryDataModel from '@/models/claim/SIJobFinancialSummaryDataModel'
import DashboardController from '@/api/dashboardController'
import SIJobFinancialSummary from '@/models/claim/SIJobFinancialSummary'
import JobFinancialSummaryWidgetCount from '@/models/JobFinancialSummaryWidgetCount'
import Store from '@/store'
import moment from 'moment'
import InvoiceDetailModel from '@/models/claim/InvoiceDetailModel'
import eventBus from '../common/bus'
import InvoiceController from '@/api/invoiceController'
import ExportToInvoice from '@/models/requests/ExportToInvoice'
import BordereauPreviewTable from '@/components/tables/BordereauPreviewTable.vue'
import ApproveListOfJobsModel, { ApproveJob } from '@/models/claim/ApproveListOfJobsModel'
import PartialJobView from '@/components/PartialJobView.vue'
import { ClaimsManagementCompanySalesLedger } from '@/common/interfaces'
import ClaimsManagementCompanySalesLedgerModel from '../models/policy/ClaimsManagementCompanySalesLedgerModel'

@Component({
  components: { DashboardTopTile, BordereauPreviewTable, PartialJobView },
})
export default class JobFinancialSummaryDashboardSI extends Vue {
  @Prop() private selectedInsurerId: number
  @Prop() private claimsManagementCompanySalesLedgers: ClaimsManagementCompanySalesLedger[]
  @Prop() private selectedClaimManagementCompany: number
  private search = ''
  private pagination: any = {}
  private headers: any = []
  private financialRequestStatus = FinancialRequestStatus
  private isLoading = false
  private jobList: SIJobFinancialSummary[] = []
  private jobFinancialSummaryWidgetCount: JobFinancialSummaryWidgetCount | null = null
  private selectedJobList: SIJobFinancialSummary[] = []
  private defaultTableHeaderColor = 'blue day-counts-section white--text'
  private tileColorWithActiveFilter: string = this.defaultTableHeaderColor
  private jobFinancialSummaryWidget = JobFinancialSummaryWidget
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('siJobFinancialSummary')
  private selectedTileName: string = this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
  private lastReCalculateSignalRCallAt: moment.Moment | null = null
  private showDisputeDialog = false
  private disputedReason = ''
  private onSubmitLoading = false
  private disputedReasonTableHeader: any = {
    text: 'Dispute Reason',
    value: 'disputedReason',
    class: this.defaultTableHeaderColor,
  }

  private selectedBordereauId: string | null = null
  private exportToInvoiceDialog = false
  private bordereauGenerateLoader = false
  private bordereauConfirmLoader = false
  private salesLedgerCodeList: string[] = []
  private salesLedgerCode = ''
  private openJobView = false
  private selectedJobIdToExpand = ''

  private async created() {
    // this parameter set to show first tile color on table header
    const headerColor: string = this.defaultTableHeaderColor
    this.headers = [
      { text: 'JobId', value: 'jobId', class: headerColor },
      { text: 'Claim Management Company', value: 'claimManagementCompany', align: 'left', class: headerColor },
      { text: 'Insurer', value: 'insurer', align: 'left', class: headerColor },
      { text: 'Product Description', value: 'productDescription', align: 'left', class: headerColor },
      { text: 'Invoice Amount', value: 'totalCost', align: 'left', class: headerColor },
      { text: 'Policyholder', value: 'policyHolderName', align: 'left', class: headerColor },
      { text: 'Postcode', value: 'postCode', align: 'left', class: headerColor },
    ]
    this.pagination.rowsPerPage = Shared.rowsPerPageDefault
    // get job list
    this.getJobList()
    // handle signalR
    this.handleSignalR()
  }

  private destroyed() {
    this.signalRHub.disconnect()
  }

  private get getJobListLength() {
    return this.jobList.length
  }

  private handleSignalR() {
    // signalR on adding new jobs and SI job/visit invoice
    this.signalRHub.addHandler(
      'addNewInvoice',
      (insurerId: number, claimManagementCompanyId: number, jobId: string, invoiceDetailId: string) => {
        if (this.checkIsRecordUpdationAllowed(insurerId, claimManagementCompanyId)) {
          if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
          ) {
            // if ReadyForApproval filter activated, get and push jobs to the list
            this.getJobsFinancialDetails([jobId], invoiceDetailId)
          }
          // update widget count
          this.updateTileDataOnSignalR()
        }
      }
    )

    // signalR on approving a jobs
    this.signalRHub.addHandler(
      'approveForJobInvoice',
      (
        insurerId: number,
        claimManagementCompanyId: number,
        approveListOfJobs: string[],
        clientInvoiceDetailId: string
      ) => {
        if (this.checkIsRecordUpdationAllowed(insurerId, claimManagementCompanyId)) {
          if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
          ) {
            // if ReadyForApproval filter activated, remove selected jobs
            approveListOfJobs.forEach((jobId) => {
              const selectedJob = this.jobList.find(
                (x: SIJobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
              )
              if (selectedJob) {
                this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
              } else {
                // insert if approved by to review
                this.getJobsFinancialDetails([jobId], clientInvoiceDetailId)
              }
            })
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice]
          ) {
            // if ReadyForInvoice filter activated, get and push jobs to the list
            this.getJobsFinancialDetails(approveListOfJobs, clientInvoiceDetailId)
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ToReview] ||
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]
          ) {
            // if ToReview or Disputed filter activated, remove selected jobs
            approveListOfJobs.forEach((jobId) => {
              const selectedJob = this.jobList.find(
                (x: SIJobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
              )
              if (selectedJob) {
                this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
              }
            })
          }
          // update tiles data
          this.updateTileDataOnSignalR()
        }
      }
    )

    // signalR on rejcting a jobs
    this.signalRHub.addHandler(
      'rejectForJobInvoice',
      (
        insurerId: number,
        claimManagementCompanyId: number,
        rejectListOfJobs: string[],
        clientInvoiceDetailId: string
      ) => {
        if (this.checkIsRecordUpdationAllowed(insurerId, claimManagementCompanyId)) {
          if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
          ) {
            // if ReadyForApproval filter activated, remove selected jobs
            rejectListOfJobs.forEach((jobId) => {
              const index = this.jobList.findIndex(
                (x: SIJobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
              )
              if (index > -1) {
                this.jobList.splice(index, 1)
              }
            })
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]
          ) {
            // if Disputed filter activated, get and push jobs to the list
            this.getJobsFinancialDetails(rejectListOfJobs, clientInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR()
        }
      }
    )

    // signalR for re-calculate, update material and labour cost
    this.signalRHub.addHandler(
      'updateInvoiceDetail',
      (
        dataProcessAtUtc: moment.Moment,
        insurerId: number,
        claimManagementCompanyId: number,
        jobId: string,
        invoiceDetail: InvoiceDetailModel,
        clientInvoiceDetailId: string
      ) => {
        if (this.checkIsRecordUpdationAllowed(insurerId, claimManagementCompanyId)) {
          // save last signalr call at date, to verify the sequence
          if (this.lastReCalculateSignalRCallAt === null) {
            this.lastReCalculateSignalRCallAt = dataProcessAtUtc
            // update tiles data
            this.updateTileDataOnSignalR()
          } else if (this.lastReCalculateSignalRCallAt < dataProcessAtUtc) {
            // update tiles data
            this.updateTileDataOnSignalR()
          }
          // find job and update total cost in job
          const jobToUpdate: SIJobFinancialSummary | undefined = this.jobList.find(
            (x: SIJobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
          )
          if (jobToUpdate) {
            jobToUpdate.totalCost = invoiceDetail.totalCost
          }
        }
      }
    )

    // signalR on completing jobs
    this.signalRHub.addHandler(
      'completedJob',
      (insurerId: number, claimManagementCompanyId: number, jobId: string, clientInvoiceDetailId: string) => {
        if (this.checkIsRecordUpdationAllowed(insurerId, claimManagementCompanyId)) {
          if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
          ) {
            // if ReadyForApproval filter activated, add selected job
            this.getJobsFinancialDetails([jobId], clientInvoiceDetailId)
          }

          // update widget count
          this.updateTileDataOnSignalR()
        }
      }
    )

    // signalR on add job to Toreview
    this.signalRHub.addHandler(
      'addToReview',
      (insurerId: number, claimManagementCompanyId: number, jobId: string, clientInvoiceDetailId: string) => {
        if (this.checkIsRecordUpdationAllowed(insurerId, claimManagementCompanyId)) {
          if (this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ToReview]) {
            // if ToReview filter activated, add selected job
            this.getJobsFinancialDetails([jobId], clientInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR()
        }
      }
    )

    // signalR on rejcting a jobs
    this.signalRHub.addHandler(
      'confirmDisputeJob',
      (
        insurerId: number,
        claimManagementCompanyId: number,
        rejectListOfJobs: string[],
        clientInvoiceDetailId: string
      ) => {
        if (this.checkIsRecordUpdationAllowed(insurerId, claimManagementCompanyId)) {
          if (this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]) {
            // if Disputed filter activated, remove selected jobs
            rejectListOfJobs.forEach((jobId) => {
              const selectedJob = this.jobList.find(
                (x: SIJobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
              )
              if (selectedJob) {
                this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
              }
            })
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
          ) {
            // if ReadyForApproval, get and push jobs to the list
            this.getJobsFinancialDetails(rejectListOfJobs, clientInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR()
        }
      }
    )

    this.signalRHub.addHandler(
      'exportJob',
      (
        insurerId: number,
        claimManagementCompanyId: number,
        exportedListOfJobs: string[],
        clientInvoiceDetailId: string
      ) => {
        if (this.checkIsRecordUpdationAllowed(insurerId, claimManagementCompanyId)) {
          exportedListOfJobs.forEach((jobId) => {
            const selectedJob = this.jobList.findIndex(
              (x) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
            )
            if (selectedJob > -1) {
              this.jobList.splice(selectedJob, 1)
            }
          })
          this.updateTileDataOnSignalR()
        }
      }
    )

    this.signalRHub.connect()
  }

  private checkIsRecordUpdationAllowed(insurerId: number, claimManagementCompanyId: number) {
    // check if insurerId and claimManagementCompanyId match with signalr parameter or "All" option is selected
    return (
      (this.selectedInsurerId === 0 || this.selectedInsurerId === insurerId) &&
      (this.selectedClaimManagementCompany === 0 || this.selectedClaimManagementCompany === claimManagementCompanyId)
    )
  }

  private getJobsFinancialDetails(jobsList: string[], invoiceDetailId: string) {
    const jobList: ApproveJob[] = []
    jobsList.map((id) => jobList.push({ jobId: id, clientInvoiceDetailId: '' }))

    const listOfJobsModel: ApproveListOfJobsModel = new ApproveListOfJobsModel()
    listOfJobsModel.jobList = jobList

    DashboardController.GetSIFinancialSummaryJobs(listOfJobsModel)
      .then((res: SIJobFinancialSummary[] | null) => {
        if (res) {
          if (invoiceDetailId) {
            // find visit record using invoiceDetailId and push into joblist
            const job = res.find((r) => r.clientInvoiceDetailId === invoiceDetailId)
            if (job) {
              const index = this.jobList.findIndex(
                (x: SIJobFinancialSummary) =>
                  x.jobId === job.jobId && x.clientInvoiceDetailId === job.clientInvoiceDetailId
              )
              if (index === -1) {
                this.jobList.push(job)
              } else {
                this.jobList.splice(index, 1, job)
              }
            }
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading financial summary jobs, please try again', true)
      })
  }

  private closeInvoiceDialog() {
    this.exportToInvoiceDialog = false
    this.salesLedgerCode = ''
  }

  private updateTileDataOnSignalR() {
    this.updateHeaderColor(this.tileColorWithActiveFilter)
    DashboardController.GetSIJobFinancialSummaryWidgetCount(this.selectedInsurerId, this.selectedClaimManagementCompany)
      .then((res: JobFinancialSummaryWidgetCount | null) => {
        if (res) {
          this.jobFinancialSummaryWidgetCount = res
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading financial summary jobs widget count, please try again', true)
      })
  }

  private mounted() {
    setTimeout(() => {
      this.setSelectAllCheckboxStyle(this.defaultTableHeaderColor)
    }, 0)
  }

  @Watch('selectedInsurerId')
  @Watch('selectedClaimManagementCompany')
  private async onDropdownFilterChange() {
    this.getJobList()
  }

  private getJobList() {
    this.isLoading = true
    const self = this

    // uncheck previously selected jobs
    self.selectedJobList = []

    // select first tile automatic
    this.selectedTileName = this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
    this.tileColorWithActiveFilter = this.defaultTableHeaderColor

    DashboardController.GetSIJobFinancialSummaryData(self.selectedInsurerId, self.selectedClaimManagementCompany)
      .then((res: SIJobFinancialSummaryDataModel | null) => {
        if (res) {
          self.jobList = res.jobs
          self.jobFinancialSummaryWidgetCount = res.jobFinancialSummaryWidgetCount
        }
        // apply updated color to header
        self.updateHeaderColor(self.defaultTableHeaderColor)
        this.isLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading financial summary jobs, please try again', true)
        this.isLoading = false
      })
  }

  private get getCurrentSelectedFilter(): string {
    return this.selectedTileName
  }

  private async getJobListWithFilter(filterValue: string) {
    this.isLoading = true
    const res: SIJobFinancialSummary[] | null = await DashboardController.GetSIJobFinancialSummaryWidgetData(
      this.selectedInsurerId,
      filterValue,
      this.selectedClaimManagementCompany
    )
    this.jobList = res && res.length > 0 ? res : []
    this.pagination.page = 1
    // apply updated color to header
    this.updateHeaderColor(this.tileColorWithActiveFilter)
    this.isLoading = false
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private openConfirmationForJobApprove(status: string) {
    this.checkIsAnyJobSelected(status).then((result: boolean) => {
      if (result) {
        this.openConfirmationPopup(status.toLowerCase())
      }
    })
  }

  private openJobRejectReasonDialogue() {
    this.checkIsAnyJobSelected('reject').then((result: boolean) => {
      if (result) {
        this.disputedReason = ''
        this.showDisputeDialog = true
      }
    })
  }

  private openConfirmationForJobToReview(status: string) {
    this.checkIsAnyJobSelected('complete the review').then((result: boolean) => {
      if (result) {
        this.openConfirmationPopup(status.toLowerCase(), 'complete the review of')
      }
    })
  }

  private async checkIsAnyJobSelected(status: string): Promise<boolean> {
    if (this.selectedJobList.length === 0) {
      eventBus.$emit('showSnackbar', 'Please select job(s) to ' + status + '.')
      return false
    }
    return true
  }

  private openConfirmationPopup(actionMethod: string, displayValue?: string) {
    Shared.confirmationPopup.open(
      'Do you want to ' + (displayValue ? displayValue : actionMethod.toLowerCase()) + ' selected job(s)?',
      '',
      '',
      'No',
      'Yes',
      this,
      actionMethod + 'Jobs',
      actionMethod
    )
  }

  private onConfirmDisputingJob() {
    if (this.selectedJobList.length === 0) {
      eventBus.$emit('showSnackbar', 'Please select invoice(s) to dispute.')
      return
    }
    Shared.confirmationPopup.open(
      'Do you want to dispute the selected invoice(s)?',
      '',
      '',
      'No',
      'Yes',
      this,
      'confirmDisputeJob',
      ''
    )
  }

  private confirmDisputeJob() {
    this.isLoading = true
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    DashboardController.confirmDisputeJob(listOfJobsModel)
      .then((res: boolean) => {
        if (res) {
          this.selectedJobList = []
          this.isLoading = false
          eventBus.$emit('showSnackbar', 'selected disputed job(s) approved')
        } else {
          this.selectedJobList = []
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting confirm dispute job request, please try again', true)
        this.selectedJobList = []
        this.isLoading = false
      })
  }

  private approveJobs() {
    this.isLoading = true
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    DashboardController.ApproveForJobInvoice(listOfJobsModel)
      .then((res: boolean) => {
        if (res) {
          this.selectedJobList = []
          this.isLoading = false
          eventBus.$emit('showSnackbar', 'selected job(s) approved.')
        } else {
          this.selectedJobList = []
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting approve job invoice request, please try again', true)
        this.selectedJobList = []
        this.isLoading = false
      })
  }

  private rejectJobs() {
    const self = this
    self.validate().then((result: boolean) => {
      if (result) {
        this.isLoading = true
        const listOfJobsModel = this.getListOfJobsToBeProcessed()
        listOfJobsModel.disputedReason = this.disputedReason
        DashboardController.RejectForJobInvoice(listOfJobsModel)
          .then((res: boolean) => {
            if (res) {
              this.selectedJobList = []
              this.isLoading = false
              this.showDisputeDialog = false
              eventBus.$emit('showSnackbar', 'selected job(s) rejected')
            } else {
              this.selectedJobList = []
              this.isLoading = false
            }
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error submitting reject job invoice request, please try again', true)
            this.selectedJobList = []
            this.isLoading = false
          })
      }
    })
  }

  private getListOfJobsToBeProcessed(): ApproveListOfJobsModel {
    const listOfJobsModel: ApproveListOfJobsModel = new ApproveListOfJobsModel()
    listOfJobsModel.insurerId = this.selectedInsurerId

    const jobList: ApproveJob[] = []
    this.selectedJobList.forEach((selectedJob) => {
      const job = this.jobList.find((e) => e.jobId === selectedJob.jobId)
      if (job) {
        jobList.push({ jobId: selectedJob.jobId, clientInvoiceDetailId: selectedJob.clientInvoiceDetailId })
      }
    })
    listOfJobsModel.jobList = jobList

    return listOfJobsModel
  }

  private openInvoiceDialog() {
    if (
      this.getCurrentSelectedFilter ===
        this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice] &&
      this.selectedJobList.length !== 1
    ) {
      eventBus.$emit('showSnackbar', 'Each invoice needs to be exported individually.')
      return
    }
    this.selectedBordereauId = null
    this.exportToInvoiceDialog = true
    this.createInvoiceReference()
    this.getSalesLedgerCodeList()
  }

  private async createInvoiceReference() {
    if (
      this.getCurrentSelectedFilter !==
        this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice] ||
      !this.hasInvoicingSkill ||
      this.selectedJobList.length !== 1
    ) {
      return
    }
    this.bordereauGenerateLoader = true

    try {
      const reference = await InvoiceController.GenerateInvoiceReference(
        this.selectedJobList[0].jobId,
        this.selectedInsurerId
      )
      if (!reference) {
        throw new Error('')
      }
      this.selectedBordereauId = reference
    } catch (e) {
      eventBus.$emit(
        'errorHandler',
        'An error occurred generating the Invoice reference, please try again later.',
        true
      )
      this.exportToInvoiceDialog = false
      this.selectedBordereauId = null
    }

    this.bordereauGenerateLoader = false
  }

  private async confirmCreateInvoice() {
    const validation = await this.validate()
    if (validation) {
      if (!this.selectedBordereauId) {
        return
      }
      this.bordereauConfirmLoader = true
      const invoiceRequest: ExportToInvoice = {
        jobId: this.selectedJobList[0].jobId,
        clientInvoiceDetailId: this.selectedJobList[0].clientInvoiceDetailId,
        invoiceId: this.selectedBordereauId,
        clientId: this.selectedJobList[0].insurerId.toString(),
        salesLedgerCode: this.salesLedgerCode,
      }
      try {
        const res = await InvoiceController.ConfirmInvoice(invoiceRequest)
        if (!res) {
          throw new Error('')
        }
        if (
          this.getCurrentSelectedFilter ===
          this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice]
        ) {
          if (this.jobFinancialSummaryWidgetCount) {
            const total: number = this.selectedJobList
              .map((j) => j.totalCost)
              .reduce((prev: number, curr: number) => prev + curr, 0)
            this.jobFinancialSummaryWidgetCount.totalApprovedJobCost -= total
            this.jobFinancialSummaryWidgetCount.totalApprovedJobs -= this.selectedJobList.length
          }
          this.jobList = this.jobList.filter((j) => !this.selectedJobList.includes(j))
          this.selectedJobList = []
        }
        this.selectedBordereauId = null
      } catch (e) {
        eventBus.$emit('errorHandler', 'An error occurred creating the invoice, please try again later.', true)
      } finally {
        this.exportToInvoiceDialog = false
        this.bordereauConfirmLoader = false
      }
    }
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('jobFinancialSummaryScope')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private reviewcompleteJobs() {
    this.isLoading = true
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    DashboardController.CompleteJobReview(listOfJobsModel)
      .then((res: boolean) => {
        if (res) {
          this.selectedJobList = []
          this.isLoading = false
          eventBus.$emit('showSnackbar', 'selected job(s) reviewed.')
        } else {
          this.selectedJobList = []
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting complete job review request, please try again', true)
        this.selectedJobList = []
        this.isLoading = false
      })
  }

  private onTopTileFilter(color: string) {
    // Preventing type inferrence here because it causes a TS error in the forEach
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    const values: string = 'values'
    let elementName = ''
    let isSameFilterClicked = false
    Object[values](this.getTopTiles).forEach((element) => {
      if (element.backgroundColor === color) {
        if (element.isFilterActive) {
          isSameFilterClicked = true
          this.selectedTileName = element.name
        } else {
          this.selectedJobList = []
          element.isFilterActive = true
          this.tileColorWithActiveFilter = color + ' white--text'
          elementName = element.name
          this.selectedTileName = element.name
        }
      } else {
        element.isFilterActive = false
      }
    })

    // return of same filter clicked
    if (isSameFilterClicked) {
      return
    }

    // bind new job list as per selected tile
    if (elementName) {
      this.getJobListWithFilter(elementName).catch(() => {
        eventBus.$emit('errorHandler', 'Filter failed loading', true)
      })
    }
  }

  private updateHeaderColor(colorToUpdate: string) {
    // remove the disputed Reason header from the table
    const index = this.headers.findIndex((x) => x.value === 'disputedReason')
    if (this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]) {
      if (!(index !== -1)) {
        this.headers.push(this.disputedReasonTableHeader)
      }
    } else {
      if (index !== -1) {
        this.headers.splice(index, 1)
      }
    }

    // apply the selected color to the table
    this.headers.forEach((header) => {
      header.class = colorToUpdate
    })

    // set checkbox styling
    setTimeout(() => {
      this.setSelectAllCheckboxStyle(colorToUpdate)
    }, 0)
  }

  private setSelectAllCheckboxStyle(colorToUpdate: string) {
    this.tileColorWithActiveFilter = colorToUpdate
    const element = this.$el as any
    if (element) {
      const el: HTMLElement | null = element.querySelector('.v-datatable--select-all thead tr th')
      if (el) {
        el.className = colorToUpdate ? colorToUpdate : this.defaultTableHeaderColor
      }
    }
  }

  private get getTopTiles(): { [k: string]: TopTileProps } {
    if (this.jobFinancialSummaryWidgetCount == null) {
      return {}
    }
    return {
      jobsReadyForApprovals: {
        title: 'Ready for Approvals',
        value: this.jobFinancialSummaryWidgetCount.totalCompletedJobs,
        showChart: false,
        chartData: [],
        chartMaxValue: 100,
        backgroundColor: 'blue day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
            ? true
            : false,
        name: this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs ready for approvals',
            value:
              this.jobFinancialSummaryWidgetCount.totalCompletedJobs != null
                ? this.jobFinancialSummaryWidgetCount.totalCompletedJobs
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.jobFinancialSummaryWidgetCount.totalCompletedJobCost != null
                ? this.jobFinancialSummaryWidgetCount.totalCompletedJobCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      jobsReadyForReview: {
        title: 'Awaiting Review from CET',
        value: this.jobFinancialSummaryWidgetCount.totalToReviewJobs,
        showChart: false,
        chartData: [],
        chartMaxValue: 100,
        backgroundColor: 'purple day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ToReview]
            ? true
            : false,
        name: this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ToReview],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs ready to review',
            value:
              this.jobFinancialSummaryWidgetCount.totalToReviewJobs != null
                ? this.jobFinancialSummaryWidgetCount.totalToReviewJobs
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.jobFinancialSummaryWidgetCount.totalToReviewJobCost != null
                ? this.jobFinancialSummaryWidgetCount.totalToReviewJobCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      disputedJobs: {
        title: 'Disputed',
        value: this.jobFinancialSummaryWidgetCount.totalDisputedJobs,
        showChart: false,
        chartData: [],
        backgroundColor: 'red day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]
            ? true
            : false,
        name: this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs disputed',
            value:
              this.jobFinancialSummaryWidgetCount.totalDisputedJobs != null
                ? this.jobFinancialSummaryWidgetCount.totalDisputedJobs
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.jobFinancialSummaryWidgetCount.totalDisputedJobCost != null
                ? this.jobFinancialSummaryWidgetCount.totalDisputedJobCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      jobsReadyForInvoice: {
        title: 'Ready for Invoice',
        value: this.jobFinancialSummaryWidgetCount.totalApprovedJobs,
        showChart: false,
        chartData: [],
        backgroundColor: 'orange day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice]
            ? true
            : false,
        name: this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs ready for Invoice',
            value:
              this.jobFinancialSummaryWidgetCount.totalApprovedJobs != null
                ? this.jobFinancialSummaryWidgetCount.totalApprovedJobs
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.jobFinancialSummaryWidgetCount.totalApprovedJobCost != null
                ? this.jobFinancialSummaryWidgetCount.totalApprovedJobCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
    }
  }

  private getSalesLedgerCodeList() {
    // get Sales Ledger Code List
    this.salesLedgerCodeList = []
    if (this.claimsManagementCompanySalesLedgers && this.claimsManagementCompanySalesLedgers.length > 0) {
      const companyIndex: number = this.claimsManagementCompanySalesLedgers.findIndex(
        (x: ClaimsManagementCompanySalesLedger) => x.id === this.selectedJobList[0].claimManagementCompanyId
      )
      if (
        companyIndex !== -1 &&
        this.claimsManagementCompanySalesLedgers[companyIndex].salesLedgerCodes &&
        this.claimsManagementCompanySalesLedgers[companyIndex].salesLedgerCodes.length > 0
      ) {
        this.claimsManagementCompanySalesLedgers[companyIndex].salesLedgerCodes.map(
          (x: ClaimsManagementCompanySalesLedgerModel) => this.salesLedgerCodeList.push(x.salesLedgerCode)
        )
      }
      this.salesLedgerCode = this.salesLedgerCodeList.length === 1 ? this.salesLedgerCodeList[0] : ''
    }
  }

  private get hasInvoicingSkill() {
    return Store.Instance.state.SessionDetail.canUpdateInvoice
  }

  private redirectToJob(jobId: string) {
    this.selectedJobIdToExpand = jobId
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  private closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }
}
</script>

<style scoped>
.job-info >>> h2 {
  font-size: 38px;
  display: inline-block;
  vertical-align: top;
}
.job-info.request-info >>> .card {
  height: 78px !important;
  display: flex;
  flex-wrap: wrap;
}
.job-info.request-info >>> .card .card__text {
  display: none;
  align-self: flex-end;
}
.job-info.request-info >>> .card .card__title {
  align-self: flex-start;
}
@media only screen and (max-width: 1200px) {
  .job-info >>> h2 {
    font-size: 30px;
  }
  .job-info.request-info >>> .card {
    height: 100px !important;
  }
}
@media only screen and (max-width: 1300px) {
  .job-info.request-info >>> .card .card__title {
    font-size: 16px;
  }
}
.gridView {
  border: 0px;
}
.gridView >>> table.v-table thead th:not(:first-child),
.gridView >>> table.v-table tbody td:not(:first-child) {
  padding: 0px 15px !important;
}
.gridView >>> .v-table thead > tr > th:nth-child(1),
.gridView >>> .v-table tbody > tr > td:nth-child(1) {
  max-width: 75px;
  width: 75px;
}
.gridView >>> .v-table thead > tr > th:nth-child(2),
.gridView >>> .v-table tbody > tr > td:nth-child(2) {
  max-width: 90px;
  width: 90px;
}
.gridView >>> .v-table thead > tr > th:nth-child(3),
.gridView >>> .v-table tbody > tr > td:nth-child(3) {
  max-width: 250px;
  width: 250px;
}
.gridView >>> .v-table thead > tr > th:nth-child(4),
.gridView >>> .v-table tbody > tr > td:nth-child(4) {
  max-width: 240px;
  width: 240px;
  min-width: 240px;
}
.gridView >>> .v-table thead > tr > th:nth-child(5),
.gridView >>> .v-table tbody > tr > td:nth-child(5) {
  max-width: 260px;
  width: 260px;
}
.gridView >>> .v-table thead > tr > th:nth-child(6),
.gridView >>> .v-table tbody > tr > td:nth-child(6) {
  max-width: 140px;
  width: 140px;
}
.gridView >>> .v-table thead > tr > th:nth-child(7),
.gridView >>> .v-table tbody > tr > td:nth-child(7) {
  max-width: 250px;
  width: 250px;
}
.gridView >>> .v-table thead > tr > th:nth-child(8),
.gridView >>> .v-table tbody > tr > td:nth-child(8) {
  max-width: 170px;
  width: 170px;
}
.search-job {
  display: inline-block;
  min-width: 450px;
  margin-right: 15px;
}
.text-right {
  text-align: right;
}
.day-counts-section {
  padding: 15px 10px 8px;
}
.day-counts-section .count {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  padding-left: 5px;
}
.day-counts-section .icon {
  vertical-align: top;
}
.day-counts-section .icon img {
  width: 18px;
  padding-top: 2px !important;
}
.search-controls >>> .btn {
  vertical-align: top;
}
.job-list >>> tr.datatable__progress th {
  padding: 0px !important;
}
.icon-arrow {
  position: relative;
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.loader-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #fff;
  z-index: 205;
  opacity: 0.2;
}
.hide-dashboard {
  visibility: hidden;
}
.show-dashboard {
  visibility: visible;
}
.dashboard >>> .v-card {
  margin-bottom: 15px !important;
}
.bordereau-id {
  font-size: 22px;
  font-weight: 600;
  padding-left: 5px;
}
</style>
