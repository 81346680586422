<template>
  <div>
    <v-form v-if="insurer" autocomplete="off">
      <v-layout row wrap>
        <v-flex class="md8 lg6 offset-lg3 offset-md3 mt-3 profile-sec">
          <v-card class="elevation-2 pa-0">
            <v-layout wrap class="cntp-upper-sec primary pt-2">
              <v-flex xs12 class="text-xs-center cntp-logo-sec">
                <div class="cntp-logo-sec-content">
                  <span v-if="imageUrl" class="rm-logo elevation-1 pa-0">
                    <v-btn
                      flat
                      icon
                      color="primary"
                      class="ma-0 btn-remove-image"
                      small
                      @click.native.stop="removeImage"
                    >
                      <v-icon dark color="primary">delete</v-icon>
                    </v-btn>
                  </span>
                  <div class="cntp-logo-img mb-3" @click="pickFile">
                    <img :src="imageUrl ? imageUrl : '/img/unknownuser.png'" class="elevation-1" />
                    <span class="cntp-logo-img-edit">
                      <v-icon color="secondary" large>edit</v-icon>
                    </span>
                  </div>
                  <input
                    ref="image"
                    v-validate="{
                      rules: {
                        required: false,
                        image: true,
                        size: profileImageSize,
                        mimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
                        ext: ['jpg', 'jpeg', 'png', 'gif'],
                      },
                    }"
                    type="file"
                    style="display: none"
                    accept="image/*"
                    name="logo"
                    data-vv-scope="frmProfile"
                    @change="onFilePicked"
                  />
                  <div class="input-group__details imageError-wrapper">
                    <div class="input-group__messages input-group__error input-group--error error--text">
                      <span v-if="imageValidationMessage(errors)">
                        {{ logoErrorMsg }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="pa-3 mt-5">
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.trim="insurer.name"
                  label="Insurer Name"
                  maxlength="50"
                  required
                  class="required"
                  :readonly="true"
                  name="insurerName"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-model.trim="insurer.alternativeName"
                  v-validate="'required'"
                  label="Alternative Name"
                  maxlength="50"
                  required
                  class="required"
                  data-vv-scope="frmClientProfile"
                  data-vv-name="Alternative Name"
                  name="alternativeName"
                  :error-messages="errors.collect('Alternative Name')"
                  :readonly="isUserRoleClientManager"
                ></v-text-field>
              </v-flex>
              <v-flex v-if="insurer.insurerSalesLedgers && insurer.insurerSalesLedgers.length > 0" xs12>
                <div class="grey py-2 px-3 lighten-4 multiple-contact-field add-remove-btn">
                  <template>
                    <v-layout v-for="(item, index) in insurer.insurerSalesLedgers" :key="item.id">
                      <v-flex xs4 pr-2>
                        <v-select
                          v-model="item.jobType"
                          v-validate="'required'"
                          label="Job Type"
                          :items="businessUnit"
                          required
                          class="required jobType"
                          data-vv-scope="frmClientProfile"
                          :data-vv-name="'Job Type' + index"
                          :error-messages="validationMessage('Job Type' + index)"
                          maxlength="20"
                          :readonly="isUserRoleClientManager"
                          @change="onJobTypeChange($event, item, index)"
                        ></v-select>
                      </v-flex>
                      <v-flex xs4 pl-2>
                        <v-combobox
                          v-model.trim="item.label"
                          v-validate="'required'"
                          label="Label"
                          :items="salesLedgerCodeList"
                          maxlength="20"
                          required
                          class="required salesLedgerLabel"
                          data-vv-scope="frmClientProfile"
                          :data-vv-name="'Label' + index"
                          :error-messages="validationMessage('Label' + index, true)"
                          :readonly="isUserRoleClientManager"
                          @change="onCodeChange($event, item, index)"
                        ></v-combobox>
                        <v-btn
                          v-if="!isUserRoleClientManager"
                          icon
                          class="code-remove ma-0"
                          flat
                          color="secondary"
                          @click="deleteLedgerCode(item)"
                        >
                          <v-icon class="md-icon">delete</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex xs4 pl-2>
                        <v-text-field
                          v-model.trim="item.code"
                          v-validate="'required'"
                          label="Code"
                          maxlength="100"
                          required
                          class="required"
                          data-vv-scope="frmClientProfile"
                          :data-vv-name="'Code' + index"
                          :error-messages="validationMessage('Code' + index)"
                          :readonly="isUserRoleClientManager"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </template>
                </div>
              </v-flex>
              <v-flex
                xs12
                :class="
                  insurer.insurerSalesLedgers == null || insurer.insurerSalesLedgers.length === 0
                    ? 'grey py-2 px-3 lighten-4'
                    : 'align-add'
                "
              >
                <v-layout>
                  <v-flex v-if="insurer.insurerSalesLedgers == null || insurer.insurerSalesLedgers.length === 0" xs-9>
                    <h3 class="align-text">Add Sales Ledger Code</h3>
                  </v-flex>
                  <v-flex
                    :class="
                      insurer.insurerSalesLedgers == null || insurer.insurerSalesLedgers.length === 0 ? 'xs2' : 'xs12'
                    "
                    class="text-xs-right"
                  >
                    <v-btn
                      v-if="!isUserRoleClientManager"
                      icon
                      flat
                      class="btn-add"
                      color="primary"
                      @click="addNewSalesLedgerCode"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model.trim="insurer.heNotificationEmail"
                  v-validate="'max:50|email'"
                  label="HE Notification Email"
                  :readonly="isUserRoleClientManager"
                  data-vv-scope="frmClientProfile"
                  data-vv-name="HE Notification Email"
                  :error-messages="errors.collect('HE Notification Email')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model.trim="insurer.siNotificationEmail"
                  v-validate="'max:50|email'"
                  label="SI Notification Email"
                  :readonly="isUserRoleClientManager"
                  data-vv-scope="frmClientProfile"
                  data-vv-name="SI Notification Email"
                  :error-messages="errors.collect('SI Notification Email')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model.trim="insurer.usNotificationEmail"
                  v-validate="'max:50|email'"
                  label="US Notification Email"
                  :readonly="isUserRoleClientManager"
                  data-vv-scope="frmClientProfile"
                  data-vv-name="US Notification Email"
                  :error-messages="errors.collect('US Notification Email')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.number="insurer.bordereauMaxAmount"
                  label="Maximum Bordereau Amount"
                  maxlength="9"
                  :readonly="isUserRoleClientManager"
                  @keypress="numberKeyValidation($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-model.number="insurer.heInvoiceMaxValue"
                  label="Maximum HE Job Invoice Value"
                  maxlength="9"
                  :readonly="isUserRoleClientManager"
                  @keypress="numberKeyValidation($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.trim="insurer.addressLine1"
                  v-validate="'max:100'"
                  label="Address Line 1"
                  :readonly="isUserRoleClientManager"
                  data-vv-scope="frmClientProfile"
                  data-vv-name="Address Line 1"
                  :error-messages="errors.collect('Address Line 1')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-model.trim="insurer.addressLine2"
                  v-validate="'max:100'"
                  label="Address Line 2"
                  :readonly="isUserRoleClientManager"
                  data-vv-scope="frmClientProfile"
                  data-vv-name="Address Line 2"
                  :error-messages="errors.collect('Address Line 2')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.trim="insurer.addressLine3"
                  v-validate="'max:100'"
                  label="Address Line 3"
                  :readonly="isUserRoleClientManager"
                  data-vv-scope="frmClientProfile"
                  data-vv-name="Address Line 3"
                  :error-messages="errors.collect('Address Line 3')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-model.trim="insurer.addressLine4"
                  v-validate="'max:100'"
                  label="Address Line 4"
                  :readonly="isUserRoleClientManager"
                  data-vv-scope="frmClientProfile"
                  data-vv-name="Address Line 4"
                  :error-messages="errors.collect('Address Line 4')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.trim="insurer.postCode"
                  v-validate="'max:50'"
                  label="Postcode"
                  :readonly="isUserRoleClientManager"
                  data-vv-scope="frmClientProfile"
                  data-vv-name="Postcode"
                  :error-messages="errors.collect('Postcode')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-flex v-if="!isUserRoleClientManager" class="text-xs-right">
              <v-btn
                color="primary"
                :loading="isLoading"
                :disabled="isLoading"
                class="mr-3 mb-4 submit-btn"
                @click="saveClientProfile"
              >
                Save
              </v-btn>
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import InsurerModel from '@/models/policy/InsurerModel'
import Shared from '@/common/shared'
import Store from '@/store'
import InsurerController from '@/api/InsurerController'
import { JobType } from '@/common/enums'
import DocumentController from '@/api/documentController'
import InsurerSalesLedgerModel from '@/models/policy/InsurerSalesLedgerModel'
import eventBus from '@/common/bus'
import { BlobServiceClient } from '@azure/storage-blob'
import { logger } from '@/plugins/datadog'

@Component
export default class ClientProfile extends Vue {
  @Prop() private insurerDetail: InsurerModel | null
  @Prop({ default: false }) private isProfileTabSelected: boolean

  private isLoading = false
  private insurer: InsurerModel = new InsurerModel()
  private imageName = ''
  private imageUrl = ''
  private logoErrorMsg = ''
  private businessUnit: JobType[] = ['HE', 'SI', 'US']
  private salesLedgerCodeList: string[] = ['N-Power', 'Select', 'OOH', 'Commercial']
  private errorMessage = ''
  private errorIndex = -1
  private logoFile: Blob | null = null

  private created() {
    this.setInsurerDetail()
  }

  @Watch('insurerDetail', { deep: true })
  private onInsurerDetailChange() {
    this.setInsurerDetail()
  }

  @Watch('isProfileTabSelected')
  private onIsProfileTabSelected() {
    if (this.isProfileTabSelected) {
      this.setInsurerDetail()
      this.$validator.errors.items = []
    }
  }

  private setInsurerDetail() {
    this.insurer = JSON.parse(JSON.stringify(this.insurerDetail))
    this.imageUrl = this.insurerDetail ? this.insurerDetail.logoURL : ''
  }

  private async saveClientProfile() {
    const result = await this.validate()
    if (result) {
      this.isLoading = true
      if (this.logoFile) {
        await this.uploadLogo()
      } else {
        this.insurer.logoURL = ''
        this.saveInsurer()
      }
    }
  }

  private saveInsurer() {
    this.logoFile = null
    InsurerController.SaveInsurer(this.insurer)
      .then((res: InsurerModel) => {
        if (res) {
          this.$emit('saveClientProfile', res)
        }
        this.isLoading = false
      })
      .catch(() => {
        eventBus.$emit('errorHandler', 'Error saving insurer detail, please try again', true)
        this.isLoading = false
      })
  }

  private async validate(): Promise<boolean> {
    let result = true

    // validate duplicate ledger code existence
    if (this.insurer.insurerSalesLedgers && this.insurer.insurerSalesLedgers.length > 0) {
      for (let index = 0; index < this.insurer.insurerSalesLedgers.length; index++) {
        const element = this.insurer.insurerSalesLedgers[index]
        this.insurer.insurerSalesLedgers.filter((c, i) => {
          if (
            index !== i &&
            c.jobType &&
            c.label &&
            element.label &&
            c.jobType === element.jobType &&
            c.label.toString().toLowerCase() === element.label.toString().toLowerCase()
          ) {
            this.showErrorMessage(element, index)
            return
          }
        })
      }
    }

    // set focus to non validate field
    const profileValidation: boolean = await this.$validator.validateAll('frmClientProfile')
    if (!profileValidation || this.errorMessage) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = profileValidation && this.errorMessage ? false : true
    }

    return result
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }

  private get profileImageSize(): number {
    return Store.Instance.state.Environment.LogoSize
  }

  private pickFile() {
    const logoError = this.$validator.errors.items.find((a) => a.field === 'logo')
    if (logoError) {
      logoError.msg = ''
    }
    const image = this.$refs.image as any
    image.click()
  }

  private onFilePicked(e: any) {
    const files = e.target.files
    const self = this
    if (files[0] !== undefined) {
      self.imageName = files[0].name
      if (self.imageName.lastIndexOf('.') <= 0) {
        return
      }
      const fr: any = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        self.imageUrl = fr.result
        if (self.insurer) {
          self.insurer.logoURL = self.imageName
          if (self.logoErrorMsg === '') {
            this.logoFile = files[0]
          }
        }
      })
    }
  }

  private imageValidationMessage(err: any) {
    if (err && err.collect('logo').length > 0) {
      const message: string = err.collect('logo')[0]
      this.logoErrorMsg =
        message === 'The logo field must be an image.' ? 'Only JPEG, JPG, PNG, GIF files are allowed.' : message
      this.removeImage()
      return true
    }
  }

  private removeImage() {
    this.imageName = ''
    this.imageUrl = ''
    const image = this.$refs.image as any
    image.value = null
    if (this.insurer) {
      this.insurer.logoURL = ''
      this.logoFile = null
    }
  }

  private async uploadLogo() {
    const token = await DocumentController.GetSASTokenToWriteClientImage(
      this.insurer.id.toString(),
      this.insurer.logoURL
    )
    if (token && this.logoFile) {
      const blobUri = token.split('/clients')[0]
      const sasToken = token.split('?')[1]
      const containerName = 'clients'
      const blobName = `${this.insurer.id}/${this.insurer.logoURL}`

      try {
        await new BlobServiceClient(`${blobUri}?${sasToken}`)
          .getContainerClient(containerName)
          .getBlockBlobClient(blobName)
          .uploadData(this.logoFile)
        this.insurer.logoURL = `${blobUri}/${containerName}/${blobName}`
        this.saveInsurer()
      } catch (error) {
        const message = `Error saving client profile image`
        eventBus.$emit('errorHandler', message, true)
        logger.error(message, { error, insurerId: this.insurer.id })
      } finally {
        this.isLoading = false
      }
    }
    return ''
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private deleteLedgerCode(item: InsurerSalesLedgerModel): void {
    this.errorMessage = ''
    this.insurer.insurerSalesLedgers.splice(this.insurer.insurerSalesLedgers.indexOf(item), 1)
  }

  private addNewSalesLedgerCode() {
    if (!this.insurer.insurerSalesLedgers) {
      this.insurer.insurerSalesLedgers = []
    }
    if (!this.errorMessage) {
      this.insurer.insurerSalesLedgers.push(new InsurerSalesLedgerModel())
    }
  }

  private validationMessage(label: string, isCode = false) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    if (this.errorMessage && isCode) {
      if (errorMessage[1] === this.errorIndex.toString()) {
        return this.errorMessage
      }
    }
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private onJobTypeChange(event, item: InsurerSalesLedgerModel, index: number) {
    this.getDuplicateRecord(event, item.label, index)
  }

  private onCodeChange(event, item: InsurerSalesLedgerModel, index: number) {
    this.getDuplicateRecord(item.jobType, event, index)
  }

  private getDuplicateRecord(jobType: string, label: string, index: number): InsurerSalesLedgerModel | undefined {
    if (jobType && label && index) {
      const duplicateCode: InsurerSalesLedgerModel | undefined = this.insurer.insurerSalesLedgers.find(
        (c) => c.jobType === jobType && c.label.toString().toLowerCase() === label.toString().toLowerCase()
      )
      this.showErrorMessage(duplicateCode, index)
      return duplicateCode
    }
  }

  private showErrorMessage(duplicateCode: InsurerSalesLedgerModel | undefined, index) {
    if (duplicateCode) {
      this.errorMessage = 'The label is already exists for job type ' + duplicateCode.jobType
      this.errorIndex = index
    } else {
      this.errorMessage = ''
      this.errorIndex = -1
    }
  }
}
</script>

<style scoped>
.profile-sec {
  display: flex;
}
.profile-sec >>> .v-card {
  margin: auto;
  margin-top: 0;
}
.ck-dmimg img {
  width: 100%;
}
.cntp-logo-img {
  position: relative;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-flex;
  border: 10px solid #fff;
  background-color: #fff;
}
.cntp-logo-img img {
  max-width: 100%;
  margin: auto;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
}
.cntp-upper-sec {
  color: #fff;
}
.cntp-logo-img-edit {
  transition: 0.3s all linear;
  transform: scale(0, 0);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  border-radius: 50%;
}
.cntp-logo-img-edit i {
  margin: auto;
}
.cntp-logo-img:hover .cntp-logo-img-edit {
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1);
}
.cntp-logo-sec {
  height: 90px;
  position: relative;
}
.imageError-wrapper {
  top: -23px;
  margin: auto;
  width: 100%;
  display: inline-block;
}
.full-height {
  height: 100%;
}
.profile-sec {
  display: flex;
}
.profile-sec >>> .v-card {
  margin: auto;
  margin-top: 0;
}
.rm-logo {
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  color: #696464;
  border-radius: 50%;
  padding: 3px;
  width: 30px;
  height: 30px;
  top: 7px;
  z-index: 2;
  right: 6px;
}
.cntp-logo-sec-content {
  display: inline-block;
  position: relative;
}
.rm-logo >>> .v-icon {
  font-size: 19px;
}
.multiple-contact-field {
  position: relative;
  padding-right: 60px !important;
}
.multiple-contact-field.add-remove-btn {
  padding-right: 16px !important;
  padding-bottom: 40px !important;
  top: auto;
  bottom: 5px;
}
.multiple-contact-field.add-remove-btn .layout {
  padding-right: 40px;
  position: relative;
}
.multiple-contact-field.add-remove-btn .code-remove {
  position: absolute;
  right: 0px;
  top: 16px;
}
.multiple-contact-field .contact-add {
  position: absolute;
  right: 16px;
  top: 22px;
  bottom: 5px;
}
.multiple-contact-field >>> .add-remove-btn-icon {
  position: absolute;
  right: 15px;
}
.align-add {
  margin-top: -50px;
  margin-right: 8px;
}
.align-text {
  padding-top: 10px;
}
</style>
