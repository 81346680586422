<template>
  <v-flex sm6 md4 class="icon-validation">
    <v-textarea
      v-model="audioMessage"
      :label="audioAttachmentFieldLabel"
      placeholder="Select audio or type a message"
      :append-icon="getMessageIsText == false && audioMessage != '' ? 'close' : ''"
      :readonly="(getMessageIsText == false && audioMessage != '') || isUserRoleClientManager"
      prepend-icon="attach_file"
      :hide-details="true"
      rows="3"
      class="audio-attachment"
      name="Audio Message"
      @click:append="removeAudioAttachment"
      @click:prepend="pickFile"
      @blur="onAudioMessageChange"
      @focus="onInputFocus"
    ></v-textarea>
    <input
      ref="audio"
      v-validate="{
        rules: {
          required: false,
          size: AudioAttachmentSize,
          mimes: ['audio/mp3', 'audio/wav'],
          ext: ['mp3', 'wav'],
        },
      }"
      type="file"
      style="display: none"
      accept="audio/*"
      name="audioAttachment"
      :data-vv-scope="formReference"
      :disabled="isUserRoleClientManager"
      @change="onFilePicked"
    />
    <div class="input-group__details">
      <div class="input-group__messages input-group__error input-group--error error--text">
        <span v-if="audioFileValidationMessage(errors)">
          {{ audioErrorMsg }}
        </span>
      </div>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Store from '@/store'
import Shared from '@/common/shared'

@Component
export default class AudioUpload extends Vue {
  public audioMessage = ''

  @Prop() private audioAttachmentFieldLabel: string
  @Prop() private formReference: string
  @Prop() private message: string
  @Prop() private IsTextAudioMessage: boolean

  private audioErrorMsg = ''
  private audioFile = ''
  private audioMessageIsText = false

  private mounted() {
    this.audioMessage = this.message
  }

  @Watch('message')
  private audioMessageChanged(newValue: string) {
    this.audioMessage = newValue
  }

  private get getMessageIsText(): boolean {
    if (this.IsTextAudioMessage) {
      this.audioMessageIsText = this.IsTextAudioMessage
    }
    return this.audioMessageIsText
  }

  private set getMessageIsText(newValue: boolean) {
    this.audioMessageIsText = newValue
  }

  private get AudioAttachmentSize(): number {
    return Store.Instance.state.Environment.AudioAttachmentSize
  }

  private pickFile() {
    const audioAttachmentError = this.$validator.errors.items.find((a) => a.field === 'audioAttachment')
    if (audioAttachmentError) {
      audioAttachmentError.msg = ''
    }
    const audioFile = this.$refs.audio as any
    audioFile.click()
  }

  private onFilePicked(e: any) {
    const files = e.target.files
    const self = this
    if (files[0] !== undefined) {
      self.audioMessage = files[0].name
      if (self.audioMessage.lastIndexOf('.') <= 0) {
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        if (self.audioErrorMsg === '') {
          self.audioFile = fr.result as string
          self.getMessageIsText = false
          this.$emit('changeAudioMesssage', self.audioMessage, self.audioFile, false)
        }
      })
    }
  }

  private audioFileValidationMessage(err: any) {
    if (err && err.collect('audioAttachment').length > 0) {
      const message: string = err.collect('audioAttachment')[0]
      this.audioErrorMsg =
        message === 'The audioAttachment field must have a valid file type.'
          ? 'Only MP3, WAV files are allowed.'
          : message === 'The audioAttachment size must be less than 10 MB.'
          ? 'The file size must be less than 10 MB'
          : message
      this.removeAudioAttachment()
      return true
    }
  }

  private removeAudioAttachment() {
    this.audioMessage = ''
    this.audioFile = ''
    const audioFile = this.$refs.audio as any
    audioFile.value = null
    this.$emit('changeAudioMesssage', this.audioMessage, this.audioFile, false)
  }

  private onInputFocus() {
    this.getMessageIsText = this.audioFile !== '' ? false : true
  }

  private onAudioMessageChange() {
    if (this.audioFile === '') {
      const self = this
      this.$emit('changeAudioMesssage', self.audioMessage, self.audioFile, self.audioMessage !== '' ? true : false)
    }
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }
}
</script>
