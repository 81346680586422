<template>
  <v-menu offset-y left :close-on-content-click="true" :nudge-width="200" content-class="auth-header">
    <template #activator="{ on }">
      <v-btn icon large v-on="on">
        <v-avatar size="38px">
          <img :src="userIconUrl" :alt="userName" color="primary" />
        </v-avatar>
      </v-btn>
    </template>
    <v-card class="login-menu">
      <v-list class="clearfix">
        <div class="profile-outer-block">
          <div class="profile-detail-block">
            <div class="profile-img-block">
              <ProfileImage :image="userIconUrl" size="small" @update:image="onProfilePictureChanged" />
            </div>
            <div class="profile-right">
              <h4>{{ userName }}</h4>
              <h5 class="secondary--text">{{ userRoleDescription }}</h5>
              <a :href="mailToLink">
                {{ userEmail }}
              </a>
            </div>
          </div>
        </div>
      </v-list>
      <v-divider light></v-divider>
      <v-card-actions>
        <v-spacer />
        <router-link v-if="isUserRoleContractor" to="contractorProfile">
          <v-icon left>settings</v-icon>
          Settings
        </router-link>
        <v-btn v-if="isLoggedIn" flat :disabled="loggingOut" :loading="loggingOut" @click="logout">
          <v-icon left>logout</v-icon>
          Logout
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import TwilioController from '@/api/twilioController'
import eventBus from '@/common/bus'
import ProfileImage from '@/components/profile-image/ProfileImage.vue'
import UsersController from '@/api/users-controller'
import EngineerController from '@/api/engineerController'
import ClientUserController from '@/api/clientUserController'

@Component({
  components: { ProfileImage },
})
export default class UserMenu extends Vue {
  private loggingOut = false

  @Action('signOutOidc') private signOutOidc

  get userName(): string {
    return this.$store.getters['sessionDetail'].userName
  }

  get userRoleDescription(): string {
    return this.$store.getters['sessionDetail'].userRoleDescription
  }

  get userIconUrl(): string {
    return this.$store.getters['sessionDetail'].userProfileImageUrl
  }

  get isLoggedIn(): boolean {
    return this.$store.getters['sessionDetail'].accessToken !== ''
  }

  get userEmail(): string {
    return this.$store.getters['sessionDetail'].userEmail
  }

  get mailToLink(): string {
    return 'mailto:' + this.userEmail
  }

  private async logout() {
    this.loggingOut = true
    try {
      if (this.$store.getters['sessionDetail'].detailRecordType === 'UserDetail') {
        const result = await TwilioController.LogoutWorker()
        if (!result) {
          throw new Error('Error logging out of Twilio')
        }
      }
      await this.signOutOidc()
    } catch (error: any) {
      eventBus.$emit('errorHandler', error, true)
    } finally {
      this.loggingOut = false
    }
  }

  private get isUserRoleContractor(): boolean {
    return this.$store.getters['sessionDetail'].detailRecordType === 'EngineerDetail'
  }

  public async onProfilePictureChanged(profilePicture: File | null) {
    const userId = this.$store.getters['sessionDetail'].detailRecordId
    const userType = this.$store.getters['sessionDetail'].detailRecordType

    const profilePictureActions = {
      UserDetail: {
        set: UsersController.setProfilePicture,
        delete: UsersController.deleteProfilePicture,
      },
      EngineerDetail: {
        set: EngineerController.setProfilePicture,
        delete: EngineerController.deleteProfilePicture,
      },
    }

    const userTypeActions = profilePictureActions[userType]
    if (!userTypeActions) {
      console.error(`Unknown user type: ${userType}`)
      return
    }

    if (profilePicture) {
      const pp = await userTypeActions.set(userId, profilePicture)
      this.$store.getters['sessionDetail'].userProfileImageUrl = pp.profilePictureUrl
    } else {
      await userTypeActions.delete(userId)
    }
  }
}
</script>

<style type="text/css" scoped>
.profile-detail-block {
  display: flex;
  align-items: center;
  padding: 15px;
}

.profile-detail-block .profile-img-block {
  position: relative;
  margin: 0 10px 0 0;
}

.profile-detail-block h4 {
  font-size: 16px;
  line-height: 20px;
}

.profile-detail-block h5 {
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 0;
}

.profile-right {
  text-align: left;
}

.profile-right a {
  display: inline-block;
  color: #607d8b;
  font-size: 12px;
  line-height: 16px;
}
</style>
