<template>
  <v-layout wrap>
    <v-flex>
      <div v-if="!isUserRoleClientManager" class="text-xs-right policy-import">
        <span class="importpolicy">
          <v-autocomplete
            v-model="currentSelectedPolicyIdToCopy"
            v-validate="'required'"
            label="Select policy to Import"
            required
            class="required"
            :items="policyList"
            item-text="policyBasicDetail.name"
            item-value="policyBasicDetail.policyId"
            data-vv-name="Select policy to Import"
            :data-vv-scope="step7formRefPolicyImport"
            :error-messages="errors.collect('Select policy to Import')"
          />
        </span>
        <v-btn class="mt-0" color="primary" @click.native="openCopyPolicyQuestionConfirmationBox()">
          <v-icon>save_alt</v-icon>
          &nbsp; Import
        </v-btn>
      </div>
      <v-dialog v-model="confirmImportPolicyQuestions" max-width="500" persistent content-class="v-dialog--scrollable">
        <v-card>
          <v-card-title class="pa-3">
            <h3>Do you want to copy the policy questions from selected policy?</h3>
          </v-card-title>
          <v-card-actions class="px-3 mt-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat="flat" @click.native="confirmImportPolicyQuestions = false">No</v-btn>
            <v-btn color="primary" class="mr-0" @click.prevent="copyPolicyQuestions(currentSelectedPolicyIdToCopy)">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showQuestionOverridePopup" max-width="700" persistent content-class="v-dialog--scrollable">
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Select Question(s) to Override</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="showQuestionOverridePopup = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              v-model="selectedQuestionsToOverride"
              :headers="copyQuestion"
              :items="questionListToOverride"
              item-key="policyQuestionId"
              select-all
              class="gridView questionListTable"
            >
              <template slot="items" slot-scope="props">
                <tr :active="props.selected" @click="props.selected = !props.selected">
                  <td>
                    <v-checkbox :input-value="props.selected" color="primary" hide-details></v-checkbox>
                  </td>
                  <td>{{ props.item.overWrittenText }}</td>
                  <td>{{ props.item.entityName }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-3 mt-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat="flat" @click.native="closePolicyQuestionCopyPopups">Cancel</v-btn>
            <v-btn color="primary" class="mr-0" @click.native="overrideAndCloneQuestionList">Override</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
    <v-flex class="px-2 lighten-5 grey pa-4" xs12>
      <v-expansion-panel class="select-options elevation-0">
        <EmergencyQuestionGenerator
          v-for="(emergency, index) in selectedEntities"
          :key="emergency.emergencyId"
          class="elevation-1 mb-3"
          :policy-id="policyId"
          :entity-type="emergency.entityType"
          :insurer-id="emergency.insurerId"
          :emergency-id="emergency.emergencyId"
          :display-text="emergency.displayText"
          :emergency-details="emergency.emergencyDetails"
          :index-prefix="String(index + 1)"
          :submited-policy-question-list="localPolicyQuestionList"
          :is-local="emergency.isLocal"
          :trades="trades"
          :job-type="jobType"
          :third-party-detail="thirdPartyDetail"
        ></EmergencyQuestionGenerator>
      </v-expansion-panel>
      <div class="policyQuestionList">
        <EmergencyQuestionList
          ref="questionList"
          :policy-id="policyId"
          :policy-questions="localPolicyQuestionList"
          :is-helpline-policy="isHelplinePolicy"
          @updateEmergencyQuestion="updateEmergencyQuestion"
        ></EmergencyQuestionList>
      </div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import PolicyScheduleController from '@/api/policyScheduleController'
import PolicyModel from '@/models/policy/PolicyModel'
import PolicyQuestionModel from '@/models/policy/PolicyQuestionModel'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import { QuestionEntityType } from '@/common/enums'
import EmergencyQuestionGenerator from '@/components/EmergencyQuestionGenerator.vue'
import EmergencyQuestionList from '@/components/EmergencyQuestionList.vue'
import TradeModel from '@/models/policyHolder/TradeModel'
import { JobType } from '@/models/types'
import eventBus from '@/common/bus'

@Component({
  components: { EmergencyQuestionGenerator, EmergencyQuestionList },
})
export default class SelectedPolicyQuestionList extends Vue {
  public localPolicyQuestionList: PolicyQuestionModel[] = []
  public currentSelectedPolicyIdToCopy: number | null = null

  @Prop() private selectedEntities: any
  @Prop() private policyQuestionList: PolicyQuestionModel[]
  @Prop() private selectedEmergencies: EmergencyModel[]
  @Prop() private thirdPartyDetail: EmergencyThirdPartyTrade[]
  @Prop() private selectedEmergencyDetails: EmergencyDetailModel[]
  @Prop() private policyList: PolicyModel[]
  @Prop() private policyId: number
  @Prop() private trades: TradeModel[]
  @Prop() private jobType: JobType
  @Prop() private isHelplinePolicy: boolean

  private confirmImportPolicyQuestions = false
  private step7formRefPolicyImport = 'ps_step7formPolicyImport'
  private isLoading = false
  private questionListToOverride: PolicyQuestionModel[] = []
  private uniqueQuestionListToCopy: PolicyQuestionModel[] = []
  private showQuestionOverridePopup = false
  private copyQuestion: any = [
    { text: 'Question', value: 'quls' },
    { text: 'Question Type', value: 'qutype' },
  ]
  private selectedQuestionsToOverride: any = []

  public closePolicyQuestionCopyPopups() {
    const self = this
    // close confirmation popups
    self.confirmImportPolicyQuestions = false
    self.showQuestionOverridePopup = false
  }

  public resetTempPolicyQuestionCopyObjects() {
    // reset temp arrays used to override questions
    const self = this
    self.questionListToOverride = []
    self.selectedQuestionsToOverride = []
    self.uniqueQuestionListToCopy = []
    setTimeout(() => {
      self.$validator.errors.items = []
    }, 0)
  }

  private async openCopyPolicyQuestionConfirmationBox() {
    let result = true
    result = await this.$validator.validateAll(this.step7formRefPolicyImport)

    // set focus to non validate field
    if (!result) {
      const errorItem: any = this.$el.querySelector('.error--text')
      errorItem.focus()
    } else {
      // open import confirmation popup
      this.confirmImportPolicyQuestions = true
    }
  }

  private mounted() {
    const self = this
    this.localPolicyQuestionList = this.policyQuestionList
  }

  @Watch('policyQuestionList')
  private onPolicyQuestionListChange() {
    this.localPolicyQuestionList = this.policyQuestionList
  }

  private copyPolicyQuestions(policyId: number) {
    const self = this

    if (!policyId) {
      self.showCopyPolicyQuestionOutputMessage('Something went wrong on policy selection.')
      return
    }

    self.isLoading = false
    // clone policy questions
    PolicyScheduleController.GetPolicyQuestionByPolicyId(policyId, self.selectedEntities)
      .then((res: PolicyModel) => {
        self.isLoading = false

        self.resetTempPolicyQuestionCopyObjects()

        if (res && res !== null && res.policyQuestion !== null) {
          // check question list to override
          res.policyQuestion.forEach((element) => {
            const existingQuestion = self.localPolicyQuestionList.find((x) => x.questionId === element.questionId)
            if (existingQuestion) {
              self.questionListToOverride.push(existingQuestion)
            } else {
              self.uniqueQuestionListToCopy.push(element)
            }
          })

          if (self.questionListToOverride.length > 0) {
            // if same questions exists
            this.showQuestionOverridePopup = true
          } else {
            // add question list
            self.bindQuestionList(res.policyQuestion)
          }
        } else {
          self.showCopyPolicyQuestionOutputMessage('No Policy Questions found.')
          self.confirmImportPolicyQuestions = false
          return
        }
      })
      .catch((err: any) => {
        self.isLoading = false
        eventBus.$emit('errorHandler', 'Error loading policy question list, please try again', true)
      })
  }

  private overrideAndCloneQuestionList() {
    // bind selected questions to override into existing policy
    const self = this

    // remove all selected questions to override from existing policyQuestionList
    self.selectedQuestionsToOverride.forEach((element) => {
      const subElement: PolicyQuestionModel | undefined = self.localPolicyQuestionList.find(
        (x) => x.policyQuestionId === element.policyQuestionId
      )
      if (subElement) {
        const index: number = self.localPolicyQuestionList.indexOf(subElement)
        if (index !== -1) {
          self.localPolicyQuestionList.splice(index, 1)
        }
      }
    })

    // add all the selected questions to override into list
    self.selectedQuestionsToOverride.forEach((element) => {
      self.uniqueQuestionListToCopy.push(element)
    })

    if (this.uniqueQuestionListToCopy.length > 0) {
      // if same questions exists and user wants to override any questions
      this.bindQuestionList(this.uniqueQuestionListToCopy)
    } else {
      // if all the questions are same
      // user don't want to override any existing questions
      self.showCopyPolicyQuestionOutputMessage('Policy questions already up to date.')
      // close confirmation popups
      self.closePolicyQuestionCopyPopups()
    }
  }

  private bindQuestionList(res: PolicyQuestionModel[]) {
    const self = this
    // push new questions in exsting questions list
    res.forEach((element) => {
      const index = self.localPolicyQuestionList.findIndex((e) => e.questionId === element.questionId)
      if (index === -1) {
        self.localPolicyQuestionList.push(element)
      }
    })

    // add entity type parameter suitable to question in all the questions list
    if (res) {
      this.$emit('bindPolicyQuestions', self.localPolicyQuestionList)
    }

    // bind all the questions into exsting question list
    self.copyQuestionList()
  }

  private copyQuestionList() {
    const self = this
    // reset question list
    let oldPolicyQuestionList: PolicyQuestionModel[] = []
    oldPolicyQuestionList = self.localPolicyQuestionList
    self.localPolicyQuestionList = []
    // add policy wide question into question list
    const policyQuestions: PolicyQuestionModel[] = oldPolicyQuestionList.filter(
      (e: PolicyQuestionModel) => e.entityType === QuestionEntityType[QuestionEntityType.Policy]
    )
    policyQuestions.forEach((element: PolicyQuestionModel) => {
      const index = self.localPolicyQuestionList.findIndex((e) => e.questionId === element.questionId)
      if (index === -1) {
        self.localPolicyQuestionList.push(element)
      }
    })

    self.selectedEmergencies.forEach((emergency: EmergencyModel) => {
      const thirdPartyDetail = self.thirdPartyDetail.find((e) => e.emergencyTypeId === emergency.emergencyId)
      if (thirdPartyDetail !== undefined) {
        return
      } else {
        // add emergency level question into question list
        const emergencyQuestions: PolicyQuestionModel[] = oldPolicyQuestionList.filter(
          (e: PolicyQuestionModel) =>
            e.entityType === QuestionEntityType[QuestionEntityType.Emergency] &&
            e.question.emergencyId === emergency.emergencyId
        )
        emergencyQuestions.forEach((element: PolicyQuestionModel) => {
          const index = self.localPolicyQuestionList.findIndex((e) => e.questionId === element.questionId)
          if (index === -1) {
            self.localPolicyQuestionList.push(element)
          }
        })

        // add emergency detail
        const emergencyDetailList: EmergencyDetailModel[] = self.selectedEmergencyDetails.filter(
          (e: EmergencyDetailModel) => e.typeId === emergency.emergencyId
        )
        if (emergencyDetailList) {
          emergencyDetailList.forEach((emergencyDetail: EmergencyDetailModel) => {
            // add emergency detail level question into question list
            const emergencyDetailQuestions: PolicyQuestionModel[] = oldPolicyQuestionList.filter(
              (e: PolicyQuestionModel) =>
                e.entityType === QuestionEntityType[QuestionEntityType.EmergencyDetail] &&
                e.question.emergencyDetailId === emergencyDetail.emergencyDetailId
            )
            emergencyDetailQuestions.forEach((element: PolicyQuestionModel) => {
              const index = self.localPolicyQuestionList.findIndex((e) => e.questionId === element.questionId)
              if (index === -1) {
                self.localPolicyQuestionList.push(element)
              }
            })
          })
        }
      }
    })

    const questionList: EmergencyQuestionList = self.$refs.questionList as EmergencyQuestionList
    questionList.setList(self.localPolicyQuestionList)

    self.showCopyPolicyQuestionOutputMessage('Policy questions copied successfully.')
    self.closePolicyQuestionCopyPopups()
  }

  private showCopyPolicyQuestionOutputMessage(message: string) {
    // show message
    this.$emit('showSnackbarMessage', message)
  }

  private updateEmergencyQuestion(items: PolicyQuestionModel[]) {
    this.localPolicyQuestionList = items
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }
}
</script>

<style>
.importpolicy {
  min-width: 300px;
  display: inline-block;
}
.importpolicy >>> .v-input__control .v-text-field__details {
  padding-bottom: 5px;
}
.policy-import >>> .v-btn {
  vertical-align: baseline;
}
.questionListTable >>> table > thead > tr > th:nth-child(2) {
  width: 80%;
}
.questionListTable >>> table > thead > tr > th:nth-child(1) {
  padding-left: 8px !important;
}
</style>
