<template>
  <div class="fill-height client-section py-3">
    <div
      class="clinet-inner-content fill-height"
      :class="istRateTemplateTabSelected ? false : !insurerDropdown ? 'client-rate-tab' : ''"
    >
      <v-dialog v-model="showInsurerDialog" content-class="v-dialog--scrollable" max-width="700" persistent>
        <Insurer @CloseInsurerDialog="onInsurerDialogClose"></Insurer>
      </v-dialog>
      <v-layout
        v-if="insurerDropdown || istRateTemplateTabSelected"
        row
        wrap
        align-center
        xs12
        class="text-right insurer-search"
        :class="showSelectJobTypeDropdown || showSelectVisitTypeDropdown ? 'job-type-dropdown' : ''"
      >
        <v-select
          v-if="showSelectJobTypeDropdown"
          v-model="selectedJobType"
          :items="getJobTypes"
          label="Job Type"
          item-text="jobTypeDescription"
          item-value="jobType"
          class="pr-3 select-jobType"
        ></v-select>
        <template v-if="insurerDropdown">
          <v-switch
            v-if="showJobViewToggle"
            v-model="showEnhancedJobView"
            color="primary"
            small
            label="List View"
            hide-details
            class="enhanced-toggle"
          ></v-switch>
          <v-autocomplete
            v-if="showSelectVisitTypeDropdown"
            v-model="selectedVisitType"
            :items="getVisitTypes"
            item-text="alterText"
            item-value="description"
            label="Visit Type"
            :loading="getVisitTypeRecordsRunning"
            class="pr-3"
            @change="filterJobsBySelectedVisitType($event)"
          ></v-autocomplete>
          <v-autocomplete
            v-model="selectedInsurerId"
            v-validate="'required'"
            label="Search insurer"
            required
            class="required insurer-list"
            :items="insurers"
            :loading="insurerSearchRunning"
            item-text="name"
            item-value="id"
            data-vv-scope="frmClientManagement"
            data-vv-name="Search insurer"
            :error-messages="errors.collect('Search insurer')"
            :disabled="insurerSearchRunning"
            @change="onChangeInsurer($event)"
          >
            <template slot="item" slot-scope="data">
              <v-list-tile-content>
                <v-list-tile-title
                  :class="{
                    'bold-select': data.item.id === -1,
                    'deleted-item': data.item.isDelete,
                  }"
                >
                  {{ data.item.name }}
                </v-list-tile-title>
              </v-list-tile-content>
            </template>
          </v-autocomplete>
        </template>
      </v-layout>
      <v-tabs v-model="activeTab" icons-and-text :hide-slider="true">
        <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap">
          <v-layout>
            <v-tab :href="clientAppUrl">
              Client App
              <img src="/img/material_box.svg" width="24" alt="Client App (beta)" class="mb-1" />
            </v-tab>

            <v-tab href="#tab-1" @click.native="onSelectHomeTab">
              Home
              <v-icon>home</v-icon>
            </v-tab>
            <v-tab href="#tab-2" @click.native="onSelectClientProfile">
              Client Profile
              <svg
                class="mb-1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 297 297"
                enable-background="new 0 0 297 297"
                width="25px"
                height="25px"
              >
                <g>
                  <path
                    d="M148.51,117.216c32.317,0,58.608-26.291,58.608-58.608S180.827,0,148.51,0c-32.317,0-58.608,26.291-58.608,58.608   S116.193,117.216,148.51,117.216z"
                    fill="#757575"
                  ></path>
                  <path
                    d="m227.154,145.618c-0.025-0.008-0.073-0.026-0.098-0.032-7.631-1.864-30.999-5.133-30.999-5.133-2.638-0.812-5.457,0.585-6.406,3.188l-35.174,96.509c-2.029,5.567-9.903,5.567-11.932,0l-35.174-96.509c-0.766-2.102-2.75-3.42-4.876-3.42-0.504,0-24.531,3.369-32.53,5.358-21.858,5.435-35.645,26.929-35.645,49.329v80.302c0,12.034 9.756,21.79 21.79,21.79h184.782c12.034,0 21.79-9.756 21.79-21.79v-80.569c-0.001-22.303-14.328-42.096-35.528-49.023z"
                    fill="#757575"
                  ></path>
                  <path
                    d="m161.775,138.613c-1.404-1.53-3.456-2.299-5.532-2.299h-15.485c-2.076,0-4.129,0.77-5.532,2.299-2.173,2.368-2.489,5.789-0.946,8.462l8.278,12.479-3.875,32.69 7.631,20.3c0.744,2.042 3.631,2.042 4.375,0l7.631-20.3-3.875-32.69 8.278-12.479c1.541-2.673 1.225-6.094-0.948-8.462z"
                    fill="#757575"
                  ></path>
                </g>
              </svg>
            </v-tab>
            <v-tab href="#tab-3" @click.native="onSelectPolicyTab">
              Policy Schedule
              <v-icon>insert_drive_file</v-icon>
            </v-tab>
            <v-tab href="#tab-4" @click.native="onSelectClientTemplateTab">
              Template
              <v-icon>color_lens</v-icon>
            </v-tab>
            <v-tab href="#tab-5" class="btn-rateTemplate" @click.native="onSelectRateTemplateTab">
              Rate Template
              <img src="/img/pound-symbol-grey.svg" alt="" class="menu-icon menu-images" />
              <img src="/img/pound-symbol-pink.svg" alt="" class="active-menu-icon menu-images" />
            </v-tab>
            <v-tab v-if="!isUserRoleClientManager" href="#tab-6" @click.native="showInsurerDropdown">
              Phone Numbers
              <v-icon>call</v-icon>
            </v-tab>
            <v-tab href="#tab-7" @click.native="onSelectFinancialSummaryTab">
              Financial Summary
              <img src="/img/finance-grey.svg" alt="" class="menu-icon menu-images" />
              <img src="/img/finance-pink.svg" alt="" class="active-menu-icon menu-images" />
            </v-tab>
            <v-tab href="#tab-8" @click.native="showInsurerDropdown">
              Reports
              <v-icon>bar_chart</v-icon>
            </v-tab>
            <v-tab href="#tab-9" @click.native="showInsurerDropdown">
              Client Users
              <v-icon size="30">person</v-icon>
            </v-tab>
          </v-layout>
        </div>
        <v-tab-item v-show="activeTab === 'tab-1'" value="tab-1" class="px-4">
          <EnhancedAccessJobView v-if="showEnhancedJobView" />
          <ClientJobs
            v-else
            :previous-insurer-id="previousInsurerId"
            :insurer-id.sync="selectedInsurerId"
            :client-jobs="clientJobs"
            :visit-type="selectedVisitType"
          />
        </v-tab-item>
        <v-tab-item v-show="activeTab === 'tab-2'" value="tab-2" class="px-4 py-1">
          <ClientProfile
            v-if="selectedInsurerId"
            class="client-profile-content"
            :insurer-detail="insurerDetail"
            :is-profile-tab-selected="isProfileTabSelected"
            @saveClientProfile="onSaveClientProfile"
          ></ClientProfile>
        </v-tab-item>
        <v-tab-item v-show="activeTab === 'tab-3'" value="tab-3" class="px-4 py-1">
          <PolicySchedule
            :all-client-rate-package-list.sync="
              selectedJobType === 'US' ? allUSClientRatePackageList : allClientRatePackageList
            "
            :selected-insurer-id="selectedInsurerId"
            :polices="getPolicyList"
            :policy-search-running="policySearchRunning"
            :job-type="selectedJobType"
          />
        </v-tab-item>
        <v-tab-item v-show="activeTab === 'tab-4'" value="tab-4" class="py-1 make-portal-templates">
          <ClientTemplate
            :selected-insurer-id="selectedInsurerId"
            :polices="getPublishedPolicies"
            :policy-search-running="policySearchRunning"
            :templates="clientTemplates"
          />
        </v-tab-item>
        <v-tab-item v-show="activeTab === 'tab-5'" value="tab-5" class="px-4 py-1">
          <ClientRateUS
            v-if="selectedJobType === 'US'"
            :all-client-rate-package-list.sync="allUSClientRatePackageList"
            class="elevation-1"
          ></ClientRateUS>
          <ClientRate
            v-else
            :all-client-rate-package-list.sync="allClientRatePackageList"
            class="elevation-1"
          ></ClientRate>
        </v-tab-item>
        <v-tab-item v-show="activeTab === 'tab-6'" value="tab-6" class="px-4 py-1">
          <CustomerPhoneNumbers
            v-if="selectedInsurerId && !isUserRoleClientManager"
            :selected-insurer-id="selectedInsurerId"
          ></CustomerPhoneNumbers>
        </v-tab-item>
        <v-tab-item v-show="activeTab === 'tab-7'" value="tab-7" class="px-4 py-1">
          <v-card class="pa-3 elevation-0">
            <JobFinancialSummaryDashboard
              v-if="selectedInsurerId"
              :selected-insurer-id="selectedInsurerId"
              :selected-job-type="selectedJobType"
              :insurer-sales-ledgers="insurerSalesLedgers"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item v-show="activeTab === 'tab-8'" value="tab-8" class="px-4 py-1">
          <ClientReports
            v-if="selectedInsurerId"
            :insurer-id="selectedInsurerId"
            :bordereau-id-to-open-details="bordereauIdToOpenDetails"
          ></ClientReports>
        </v-tab-item>
        <v-tab-item v-show="activeTab === 'tab-9'" value="tab-9" class="px-4 py-1">
          <ClientUserList
            v-if="selectedInsurerId"
            :client-users="clientUsers"
            :insurer-id="selectedInsurerId"
            :policies="polices"
            :policy-search-running="policySearchRunning"
          ></ClientUserList>
        </v-tab-item>
      </v-tabs>
    </div>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import PolicySchedule from '@/components/PolicySchedule.vue'
import ClientTemplate from '@/components/ClientTemplate.vue'
import eventBus from '@/common/bus'
import InsurerModel from '@/models/policy/InsurerModel'
import Insurer from '@/components/Insurer.vue'
import PolicyModel from '@/models/policy/PolicyModel'
import ClientRate from '@/components/ClientRate.vue'
import ClientRatePackageModel from '@/models/client/ClientRatePackageModel'
import RateController from '@/api/rateController'
import CustomerPhoneNumbers from '@/components/CustomerPhoneNumbers.vue'
import JobFinancialSummaryDashboard from '@/views/JobFinancialSummaryDashboard.vue'
import InsurerController from '@/api/InsurerController'
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import { JobType } from '@/models/types'
import PolicyScheduleController from '@/api/policyScheduleController'
import ClientUserList from '@/components/client/ClientUserList.vue'
import ClientUserController from '@/api/clientUserController'
import ClientUserDetailModel from '@/models/client/ClientUserDetailModel'
import ClientProfile from '@/components/client/ClientProfile.vue'
import Store from '@/store'
import ClientJobs from '@/components/client/ClientJobs.vue'
import ClientJobModel from '@/models/client/ClientJobModel'
import { VisitTypeEnum } from '@/common/enums'
import ClientReports from '@/components/ClientReports.vue'
import InsurerSalesLedgerModel from '@/models/policy/InsurerSalesLedgerModel'
import Shared from '../common/shared'
import ClientRateUS from '@/components/ClientRateUS.vue'
import ClientRatePackageUSModel from '../models/client/ClientRatePackageUSModel'
import EnhancedAccessJobView from '@/components/client/enhanced-access/EnhancedAccessJobView.vue'
import storeGetters from '@/storeGetters'

interface IJobTypeEnum {
  jobType: JobType
  jobTypeDescription: string
}

interface IDropdownType {
  id: number
  description: string
  alterText?: string
}

@Component({
  components: {
    PolicySchedule,
    Insurer,
    ClientTemplate,
    ClientRate,
    CustomerPhoneNumbers,
    JobFinancialSummaryDashboard,
    ClientUserList,
    ClientProfile,
    ClientJobs,
    ClientReports,
    ClientRateUS,
    EnhancedAccessJobView,
  },
})
export default class ClientManagement extends Vue {
  private selectedInsurerId: number | null = null
  private insurers: InsurerModel[] = []
  private insurerSearchRunning = false
  private showInsurerDialog = false
  private polices: PolicyModel[] = []
  private policySearchRunning = false
  private allClientRatePackageList: ClientRatePackageModel[] = []
  private insurerDropdown = true
  private clientTemplates: ClientTemplateModel[] = []
  private jobtype: JobType = 'HE'
  private showSelectJobTypeDropdown = false
  private selectedJobType: JobType = this.jobtype
  private clientUsers: ClientUserDetailModel[] = []
  private insurerDetail: InsurerModel = new InsurerModel()
  private isProfileTabSelected = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private clientJobs: ClientJobModel | null = null
  private previousInsurerId: number | null = null
  private selectedVisitType: string = VisitTypeEnum[VisitTypeEnum.AllVisit]
  private getVisitTypeRecordsRunning = false
  private showSelectVisitTypeDropdown = true
  private activeTab = 'tab-1'
  private insurerSalesLedgers: InsurerSalesLedgerModel[] = []
  private bordereauIdToOpenDetails = ''
  private istRateTemplateTabSelected = false
  private allUSClientRatePackageList: ClientRatePackageUSModel[] = []

  private showEnhancedJobView = false

  private async created() {
    const self = this

    self.insurerSearchRunning = true
    InsurerController.GetInsurerList()
      .then((res: InsurerModel[]) => {
        self.insurers = res
        // set default selected insurer
        if (self.insurers && self.insurers.length > 0) {
          // if route name is 'clientManagementwithBordereauDetail', get insurerId from route's parameter
          const insurerId = this.$route.params.insurerId ? Number(this.$route.params.insurerId) : self.insurers[0].id
          this.selectedInsurerId = insurerId
        }
        const configureNameOfInsurer: InsurerModel = new InsurerModel()
        configureNameOfInsurer.id = -1
        configureNameOfInsurer.name = 'Configure'
        self.insurers.push(configureNameOfInsurer)
        self.insurerSearchRunning = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading insurer list, please try again', true)
        this.insurerSearchRunning = false
      })
    this.getAllClientRatePackages()
  }

  private mounted() {
    // open financial summary tab from URL
    if (this.$route && this.$route.name === 'clientManagementwithfinancialSummary') {
      setTimeout(() => {
        this.activeTab = 'tab-7'
      }, 0)
    } else if (this.$route.name === 'clientManagementwithBordereauDetail') {
      // open bordereau tab from URL
      this.bordereauIdToOpenDetails = this.$route.params.bordereauName ? this.$route.params.bordereauName : ''
      if (this.bordereauIdToOpenDetails) {
        setTimeout(() => {
          this.activeTab = 'tab-8'
          this.showInsurerDropdown() // hide visit type dropdown
        }, 0)
      }
    }
  }

  private showInsurerDropdown() {
    this.insurerDropdown = true
    this.showSelectJobTypeDropdown = false
    this.showSelectVisitTypeDropdown = false
    this.isProfileTabSelected = false
    this.istRateTemplateTabSelected = false
  }

  private onSelectFinancialSummaryTab() {
    this.showInsurerDropdown()
    this.showSelectJobTypeDropdown = true
  }

  private onSelectPolicyTab() {
    this.resetPolicySchedule()
    this.selectedJobType = this.jobtype
    this.showSelectJobTypeDropdown = true
    this.showSelectVisitTypeDropdown = false
    this.isProfileTabSelected = false
    this.istRateTemplateTabSelected = false
  }

  private onSelectHomeTab() {
    this.resetPolicySchedule()
    this.showSelectJobTypeDropdown = false
    this.showSelectVisitTypeDropdown = true
    this.isProfileTabSelected = false
    this.istRateTemplateTabSelected = false
  }

  // reset the policy schedule, on tab menu click.
  private resetPolicySchedule(istRateTemplateTabSelected = false): void {
    this.insurerDropdown = istRateTemplateTabSelected ? false : true
    eventBus.$emit('resetPolicySchedule')
  }

  private onSelectClientTemplateTab() {
    this.resetClientTemplate()
    this.showSelectJobTypeDropdown = false
    this.showSelectVisitTypeDropdown = false
  }

  // reset the client template, on tab menu click.
  private resetClientTemplate(): void {
    this.insurerDropdown = true
    this.istRateTemplateTabSelected = false
    eventBus.$emit('resetClientTemplate')
  }

  private onChangeInsurer(items: any) {
    this.previousInsurerId = this.selectedInsurerId
    if (items === -1) {
      this.showInsurerDialog = true
    }
  }

  private onInsurerDialogClose(items: InsurerModel[]) {
    this.insurers = items.slice(0)
    this.insurers = Shared.SortData(this.insurers, 'name')
    const insurer: any = {}
    insurer.id = -1
    insurer.name = 'Configure'
    this.insurers.push(insurer)
    if (this.insurers && this.insurers.length > 0) {
      this.selectedInsurerId = this.insurers[0].id
    }
    this.showInsurerDialog = false
  }

  @Watch('selectedJobType')
  private onPolicyScheduleTypeChange() {
    const self = this
    self.resetPolicySchedule(this.istRateTemplateTabSelected)
    if (!self.selectedJobType) {
      return
    } else {
      if (!this.selectedInsurerId || this.selectedInsurerId === -1) {
        self.polices = []
      } else {
        self.policySearchRunning = true
        PolicyScheduleController.GetPoliciesByInsurerId(this.selectedInsurerId, this.selectedJobType)
          .then((res: PolicyModel[]) => {
            self.polices = res
            self.policySearchRunning = false
          })
          .catch((err: any) => {
            self.policySearchRunning = false
            eventBus.$emit('errorHandler', 'Error loading policy list, please try again', true)
          })
      }

      if (this.selectedJobType === 'US' && this.allUSClientRatePackageList.length === 0) {
        this.getAllUSClientRatePackages()
      }
    }
  }

  @Watch('selectedInsurerId')
  private insurerItemSelected() {
    const self = this

    this.$store.dispatch('clientModule/submitSetSelectedClient', this.selectedInsurerId)

    self.insurerSearchRunning = true
    self.resetPolicySchedule()
    self.resetClientTemplate()
    self.getClientTemplates()
    self.insurerSearchRunning = false
    self.onPolicyScheduleTypeChange()
    self.getClientUsers()
    self.setInsurerDetail()

    // get insurer jobs
    self.selectedVisitType = VisitTypeEnum[VisitTypeEnum.AllVisit]
    self.getInsurerJobs()
    // get Insurer's Sales Ledger Code
    const index = this.insurers.findIndex((x) => x.id === this.selectedInsurerId)
    this.insurerSalesLedgers =
      index !== -1 && this.insurers[index].insurerSalesLedgers ? this.insurers[index].insurerSalesLedgers : []
  }

  @Watch('showJobViewToggle')
  private onShowJobViewToggleChange() {
    if (!this.showJobViewToggle) {
      this.showEnhancedJobView = false
    }
  }

  private getAllClientRatePackages() {
    RateController.GetAllClientRatePackages()
      .then((res: ClientRatePackageModel[]) => {
        if (res && res.length > 0) {
          this.allClientRatePackageList = res
        } else {
          this.allClientRatePackageList = []
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading rate packages, please try again', true)
      })
  }

  private getAllUSClientRatePackages() {
    RateController.GetAllUSClientRatePackages()
      .then((res: ClientRatePackageUSModel[]) => {
        if (res && res.length > 0) {
          this.allUSClientRatePackageList = res
        } else {
          this.allUSClientRatePackageList = []
        }
      })
      .catch((err: any) => {
        this.allUSClientRatePackageList = []
      })
  }

  private async getClientTemplates() {
    if (this.selectedInsurerId) {
      try {
        this.clientTemplates = await InsurerController.GetClientTemplateByInsurer(this.selectedInsurerId)
      } catch (err) {
        eventBus.$emit('errorHandler', 'Error loading templates, please try again', true)
      }
    } else {
      this.clientTemplates = []
    }
  }

  private get getJobTypes(): IJobTypeEnum[] {
    return Shared.jobTypeList
  }

  private getClientUsers() {
    if (this.selectedInsurerId) {
      ClientUserController.GetClientUsers(this.selectedInsurerId)
        .then((res: ClientUserDetailModel[]) => {
          this.clientUsers = res
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading client users, please try again', true)
        })
    }
    this.clientUsers = []
  }

  private onSelectClientProfile() {
    this.showInsurerDropdown()
    this.isProfileTabSelected = true
    this.istRateTemplateTabSelected = false
  }

  private setInsurerDetail() {
    const insurerDetail = this.insurers.find((e) => e.id === this.selectedInsurerId)
    if (insurerDetail) {
      this.insurerDetail = insurerDetail
    }
  }

  private onSaveClientProfile(insurerDetail: InsurerModel) {
    this.snackbarText = 'Client Profile Detail Saved Successfully.'
    this.snackbar = true
    const index = this.insurers.findIndex((e) => e.id === insurerDetail.id)
    if (index !== -1) {
      this.insurers[index].alternativeName = insurerDetail.alternativeName
      this.insurers[index].insurerSalesLedgers = insurerDetail.insurerSalesLedgers
      this.insurers[index].logoURL = insurerDetail.logoURL
      this.insurers[index].heNotificationEmail = insurerDetail.heNotificationEmail
      this.insurers[index].siNotificationEmail = insurerDetail.siNotificationEmail
      this.insurers[index].usNotificationEmail = insurerDetail.usNotificationEmail
      this.insurers[index].bordereauMaxAmount = insurerDetail.bordereauMaxAmount
      this.insurers[index].heInvoiceMaxValue = insurerDetail.heInvoiceMaxValue
      this.insurers[index].addressLine1 = insurerDetail.addressLine1
      this.insurers[index].addressLine2 = insurerDetail.addressLine2
      this.insurers[index].addressLine3 = insurerDetail.addressLine3
      this.insurers[index].addressLine4 = insurerDetail.addressLine4
      this.insurers[index].postCode = insurerDetail.postCode
      this.insurers = [...this.insurers]
    }
  }

  private get isUserRoleClientManager() {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }

  private getInsurerJobs() {
    const self = this
    self.clientJobs = new ClientJobModel()
    if (this.selectedInsurerId) {
      eventBus.$emit('clearSearchInput', '', true)
      const insuererId: number = this.selectedInsurerId
      setTimeout(() => {
        this.insurerSearchRunning = !this.getVisitTypeRecordsRunning ? true : false
        InsurerController.GetAllClientJobs(insuererId, this.selectedVisitType)
          .then((res: ClientJobModel | null) => {
            if (res) {
              self.clientJobs = res
            }
            this.insurerSearchRunning = false
            this.getVisitTypeRecordsRunning = false
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error loading client job(s), please try again', true)
            this.insurerSearchRunning = false
            this.getVisitTypeRecordsRunning = false
          })
      }, 100)
    }
  }

  private get getVisitTypes(): IDropdownType[] {
    return [
      {
        id: 0,
        description: VisitTypeEnum[VisitTypeEnum.AllVisit],
        alterText: 'All',
      },
      {
        id: 1,
        description: VisitTypeEnum[VisitTypeEnum.FirstVisit],
        alterText: 'First',
      },
      {
        id: 2,
        description: VisitTypeEnum[VisitTypeEnum.ReturnVisit],
        alterText: 'Return',
      },
    ]
  }

  private filterJobsBySelectedVisitType(visitType: string) {
    this.getVisitTypeRecordsRunning = true
    this.getInsurerJobs()
  }

  private get getPublishedPolicies(): PolicyModel[] {
    // get only published policies
    return this.polices && this.polices.length > 0
      ? this.polices.filter((p: PolicyModel) => p.policyBasicDetail.isNewPolicyPublished)
      : []
  }

  private onSelectRateTemplateTab() {
    this.showSelectJobTypeDropdown = true
    this.istRateTemplateTabSelected = true
    this.insurerDropdown = false
    this.showSelectVisitTypeDropdown = false
    this.isProfileTabSelected = false
  }

  private get getPolicyList(): PolicyModel[] {
    // get policies with isDeleted flag false
    return this.polices.filter((p: PolicyModel) => !p.policyBasicDetail.isDeleted)
  }

  private get showJobViewToggle(): boolean {
    return this.activeTab === 'tab-1'
  }

  private get clientAppUrl(): string {
    return Store.Instance.state.Environment.ClientAppUrl
  }
}
</script>

<style scoped>
.client-section >>> .clinet-inner-content > .v-tabs {
  height: 100%;
}
.client-section >>> .clinet-inner-content > .v-tabs > .v-tabs__bar {
  z-index: 1;
  position: relative;
  width: 115px;
  display: inline-block;
  vertical-align: top;
}
.client-section >>> .clinet-inner-content > .v-tabs > .v-window {
  width: calc(100% - 115px);
  height: 100%;
  display: inline-block;
  padding-top: 55px;
  left: 115px;
  position: relative;
}
.client-section >>> .v-tabs__bar > i {
  display: none;
}
.client-section >>> .clinet-inner-content > .v-tabs > .v-tabs__bar > .v-tabs__wrapper {
  overflow: visible;
  display: block;
}
.client-section >>> .clinet-inner-content > .v-tabs > .v-tabs__bar > .v-tabs__wrapper > .v-tabs__container {
  text-align: center;
  white-space: inherit;
  position: static;
  display: block;
  transform: inherit !important;
  height: auto;
}
.client-section >>> .clinet-inner-content > .v-tabs .v-tabs__div {
  width: 100%;
  margin-bottom: 10px;
  min-width: auto;
  height: auto;
}
.client-section {
  background-color: #eeeeee;
  height: 100%;
  position: relative;
}
.client-section >>> .clinet-inner-content > .v-tabs .v-tabs__div > .v-tabs__item--active,
.client-section >>> .clinet-inner-content > .v-tabs .v-tabs__item--active i {
  color: #e91e63;
}
.client-section >>> .clinet-inner-content > .v-tabs .v-tabs__bar {
  background-color: #eeeeee;
  position: fixed;
}
.client-section >>> .clinet-inner-content > .v-tabs .v-window-item {
  transition: none !important;
  height: 100%;
}
.client-section >>> .clinet-inner-content > .v-tabs .v-window__container {
  height: 100% !important;
}
.insurer-search {
  position: absolute;
  right: 24px;
  top: 16px;
  min-width: 300px;
  width: 30%;
  z-index: 2;
  align-items: baseline;
}
.insurer-search.job-type-dropdown {
  min-width: 600px;
  width: 50%;
}
.insurer-search.job-type-dropdown >>> .v-select {
  width: 300px;
  display: inline-block;
}
.enhanced-toggle {
  justify-content: right;
  margin-right: 3rem;
}
.bold-select {
  font-weight: 600;
}
.client-rate-tab >>> .v-window {
  padding-top: 0px !important;
  margin-top: 12px;
}
.client-rate-tab >>> .tabs__items {
  padding-top: 0px !important;
  margin-top: 12px;
}
.client-section >>> .clinet-inner-content > .v-tabs .v-tabs__div > .v-tabs__item .menu-images {
  padding-bottom: 10px;
}
.client-section >>> .clinet-inner-content > .v-tabs .v-tabs__div > .v-tabs__item .active-menu-icon {
  display: none;
}
.client-section >>> .clinet-inner-content > .v-tabs .v-tabs__div > .v-tabs__item--active .menu-icon {
  display: none;
}
.client-section >>> .clinet-inner-content > .v-tabs .v-tabs__div > .v-tabs__item--active .active-menu-icon {
  display: block;
}
.client-section >>> .v-tabs .v-tabs__bar {
  position: fixed;
  left: 0;
  width: 135px;
  z-index: 1;
  overflow: auto;
  top: 60px;
}
.client-section >>> .v-tabs .v-tabs__bar .tab-wrap {
  height: calc(100vh - 80px);
  padding: 0 15px 90px 0;
  margin-top: 25px;
}
.client-section >>> .v-tabs__item--active svg path,
.client-sectionn >>> .v-tabs__item--active svg polygon {
  fill: #e91e63;
}
@media (max-width: 1400px) {
  .client-profile-content >>> .profile-sec {
    max-width: 80% !important;
    flex-basis: 80%;
    margin-left: 10% !important;
  }
}
@media (max-width: 1200px) {
  .mid-section {
    width: 94%;
    margin: auto;
  }
  .client-profile-content >>> .profile-sec {
    max-width: 100% !important;
    flex-basis: 100%;
    margin-left: 0% !important;
  }
}
</style>
