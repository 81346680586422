<template>
  <div>
    <v-stepper v-model="currentStep" vertical>
      <v-stepper-step :step="1" :complete="step1validated && currentStep > 1">
        {{ !isUserRoleClientManager ? 'Select / Create a Policy' : 'Select a Policy' }}
      </v-stepper-step>
      <v-stepper-content :step="1">
        <v-form autocomplete="off">
          <v-layout wrap>
            <v-flex class="pr-3 pl-2" xs6>
              <v-autocomplete
                v-if="isUserRoleClientManager"
                v-model="selectedPolicy"
                v-validate="'required'"
                label="Search policy"
                maxlength="100"
                required
                class="required"
                return-object
                :items="polices"
                :loading="policySearchRunning"
                item-text="policyBasicDetail.name"
                item-value="policyBasicDetail.policyId"
                :data-vv-scope="step1formRef"
                data-vv-name="Search policy"
                :error-messages="errors.collect('Search policy')"
              ></v-autocomplete>
              <v-combobox
                v-else
                v-model="selectedPolicy"
                v-validate="'required'"
                label="Search policy"
                maxlength="100"
                required
                class="required searchPolicy"
                return-object
                :items="polices"
                :loading="policySearchRunning"
                item-text="policyBasicDetail.name"
                item-value="policyBasicDetail.policyId"
                :data-vv-scope="step1formRef"
                data-vv-name="Search policy"
                name="searchPolicy"
                :error-messages="errors.collect('Search policy')"
              ></v-combobox>
            </v-flex>
            <v-flex xs12>
              <v-btn
                color="primary"
                :disabled="isLoading"
                :loading="isLoading"
                class="btn-continue"
                @click.prevent="onCompleteStep1"
              >
                Continue
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-stepper-content>
      <v-stepper-step
        :step="2"
        :complete="step2validated && currentStep > 2"
        :editable="step1validated && currentStep > 2"
        class="switch-to-step2"
        @click.native.stop="switchToStep(2)"
      >
        Policy Details
      </v-stepper-step>
      <v-stepper-content :step="2">
        <v-form autocomplete="off">
          <v-layout wrap>
            <v-flex class="pl-2 pr-2" xs12>
              <PolicySubDetail
                ref="policySubDetail"
                :policy-model="policyBasicDetailModel"
                :all-client-rate-package-list="allClientRatePackageList"
                :job-type="jobType"
                :selected-policy-is-new="selectedPolicyIsNew"
              ></PolicySubDetail>
            </v-flex>
          </v-layout>
          <v-btn
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            class="btn-savePolicyBasicDetail"
            @click.native="processCurrentPage"
          >
            {{ isUserRoleClientManager ? 'Next' : 'Save And Continue' }}
          </v-btn>
        </v-form>
      </v-stepper-content>
      <v-stepper-step
        :step="3"
        :complete="step3validated && currentStep > 3"
        :editable="step1validated && step2validated && currentStep > 3 && jobType === 'HE'"
        class="switch-to-step3"
        @click.native.stop="switchToStep(3)"
      >
        Communication
      </v-stepper-step>
      <v-stepper-content :step="3">
        <v-form autocomplete="off">
          <v-container fluid grid-list-xl class="pa-0">
            <v-layout wrap communication-content>
              <AudioUpload
                ref="announcementMessageRef"
                audio-attachment-field-label="Announcement Message"
                :form-reference="step3formRef"
                :is-text-audio-message="policyCommunicationDetail.announcementMessageIsText"
                :message="announcementMessage"
                @changeAudioMesssage="OnAnnouncementMessageChange"
              ></AudioUpload>
              <AudioUpload
                ref="reassuranceMessageOneRef"
                audio-attachment-field-label="Reassurance Message one"
                :form-reference="step3formRef"
                :is-text-audio-message="policyCommunicationDetail.reassuranceMessageOneIsText"
                :message="reassuranceMessageOne"
                @changeAudioMesssage="OnReassuranceMessageOneChange"
              ></AudioUpload>
              <AudioUpload
                ref="reassuranceMessageTwoRef"
                audio-attachment-field-label="Reassurance Message two"
                :form-reference="step3formRef"
                :is-text-audio-message="policyCommunicationDetail.reassuranceMessageTwoIsText"
                :message="reassuranceMessageTwo"
                @changeAudioMesssage="OnReassuranceMessageTwoChange"
              ></AudioUpload>
              <AudioUpload
                ref="surgeMessageRef"
                audio-attachment-field-label="Surge Message"
                :form-reference="step3formRef"
                :is-text-audio-message="policyCommunicationDetail.surgeMessageIsText"
                :message="surgeMessage"
                @changeAudioMesssage="OnSurgeMessageChange"
              ></AudioUpload>
              <v-flex sm6 md4>
                <div class="link-portal-text">
                  <v-select
                    v-model="policyCommunicationDetail.portalLink"
                    :items="customerPortals"
                    label="Link to online portal"
                    item-text="name"
                    hide-details
                    item-value="link"
                    class="portalLink"
                    name="portalLink"
                    :readonly="isUserRoleClientManager"
                  ></v-select>
                  <div class="link-switch">
                    <span class="grey--text">Enabled?</span>
                    <span>
                      <v-switch
                        v-model="policyCommunicationDetail.isPortalLinkEnabled"
                        :disabled="isHelplinePolicy"
                        color="primary"
                        small
                        name="isPortalLinkEnabled"
                        :readonly="isUserRoleClientManager"
                      ></v-switch>
                    </span>
                  </div>
                </div>
              </v-flex>
              <v-flex sm6 md4>
                <div class="average-hold-text">
                  <span class="grey--text">Read out the average hold time to customers?</span>
                  <v-switch
                    v-model="policyCommunicationDetail.readAverageHoldTime"
                    class="average-hold-switch"
                    color="primary"
                    small
                    name="readAverageHoldTime"
                    :readonly="isUserRoleClientManager"
                  ></v-switch>
                </div>
                <div class="average-hold-text">
                  <span class="grey--text">Offer callback option to customers?</span>
                  <v-switch
                    v-model="policyCommunicationDetail.callbackEnabled"
                    class="average-hold-switch"
                    color="primary"
                    small
                    name="callbackEnabled"
                    :readonly="isUserRoleClientManager"
                  ></v-switch>
                </div>
                <div class="average-hold-text">
                  <span class="grey--text">Digital Assistant Enabled</span>
                  <v-switch
                    v-model="policyCommunicationDetail.voiceFNOLEnabled"
                    class="average-hold-switch"
                    color="primary"
                    small
                    name="voiceFNOLEnabled"
                    :readonly="isUserRoleClientManager"
                  ></v-switch>
                </div>
              </v-flex>
              <v-flex sm6 md4>
                <div class="average-hold-text">
                  <span class="grey--text mr-2 ml-2">Effort Score</span>
                  <v-switch
                    v-model="policyCommunicationDetail.effortScore"
                    class="effort-score-switch"
                    color="primary"
                    small
                    name="effortScore"
                    :readonly="isUserRoleClientManager"
                  ></v-switch>
                </div>
              </v-flex>
              <v-flex sm6 md4>
                <v-text-field
                  v-model.number="policyCommunicationDetail.totalSurveysPerJobPerDay"
                  label="Total surveys against a job per day"
                  color="primary"
                  name="totalSurveysPerJobPerDay"
                  :readonly="isUserRoleClientManager"
                  :disabled="!policyCommunicationDetail.effortScore"
                  @keypress="numberKeyValidation($event)"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 md4>
                <v-text-field
                  v-model.number="policyCommunicationDetail.totalSurveysPerJob"
                  label="Total surveys against a job"
                  color="primary"
                  name="totalSurveysPerJob"
                  :readonly="isUserRoleClientManager"
                  :disabled="!policyCommunicationDetail.effortScore"
                  @keypress="numberKeyValidation($event)"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-btn
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            class="btn-savePolicyCommunicationDetail"
            @click.native="processCurrentPage"
          >
            {{ isUserRoleClientManager ? 'Next' : 'Save And Continue' }}
          </v-btn>
        </v-form>
      </v-stepper-content>
      <v-stepper-step
        :step="4"
        :complete="step4validated && currentStep > 4"
        :editable="step1validated && step2validated && step3validated && currentStep > 4"
        class="switch-to-step4"
        @click.native.stop="switchToStep(4)"
      >
        Linked to policy Holder Database
      </v-stepper-step>
      <v-stepper-content :step="4">
        <v-form autocomplete="off">
          <PolicyRouteToMarket
            ref="policyRouteToMarket"
            :policy-routeto-market="policyRoutetoMarket"
          ></PolicyRouteToMarket>
          <v-btn
            class="mt-3 ml-0 btn-savePolicyRouteToMarket"
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            @click.native="processCurrentPage"
          >
            {{ isUserRoleClientManager ? 'Next' : 'Save And Continue' }}
          </v-btn>
        </v-form>
      </v-stepper-content>
      <v-stepper-step
        :step="5"
        :complete="step5validated && currentStep > 5"
        :editable="step1validated && step2validated && step3validated && step4validated && currentStep > 5"
        class="switch-to-step5"
        @click.native.stop="switchToStep(5)"
      >
        Accommodation
      </v-stepper-step>
      <v-stepper-content :step="5">
        <v-form autocomplete="off">
          <v-layout wrap>
            <v-flex class="pl-2 pr-2" xs12>
              <v-checkbox
                v-model="policyAccommodationDetail.includesAccommodation"
                color="primary"
                label="If the home is not safe to live in, does the policy offer alternative accommodation?"
                :readonly="isUserRoleClientManager"
                name="includesAccommodation"
              />
              <v-checkbox
                v-model="policyAccommodationDetail.includesTransportToAccommodation"
                color="primary"
                label="Does the policy also offer transport to the alternative accommodation?"
                :readonly="isUserRoleClientManager"
                name="includesTransportToAccommodation"
              />
              Is there a separate value limit for alternative accommodation, in addition to the policy limit?
              <v-text-field
                v-model.number="policyAccommodationDetail.accommodationExtraValue"
                v-validate="'max:8'"
                label="Additional value"
                placeholder="0"
                type="number"
                :data-vv-scope="step5formRef"
                data-vv-name="Additional value"
                :error-messages="errors.collect('Additional value')"
                name="Additionalvalue"
                :readonly="isUserRoleClientManager"
              />
            </v-flex>
          </v-layout>
          <v-btn
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            class="btn-saveAccommodationDetail"
            @click.native="processCurrentPage"
          >
            {{ isUserRoleClientManager ? 'Next' : 'Save And Continue' }}
          </v-btn>
        </v-form>
      </v-stepper-content>
      <v-stepper-step
        :step="6"
        :complete="step6validated && currentStep > 6"
        :editable="
          jobType === 'HE'
            ? step1validated &&
              step2validated &&
              step3validated &&
              step4validated &&
              step5validated &&
              currentStep > 6 &&
              !isHelplinePolicy
            : step1validated && step2validated && currentStep > 6
        "
        class="switch-to-step6"
        @click.native.stop="switchToStep(6)"
      >
        Select {{ jobTypeLabel('emergency') }}
      </v-stepper-step>
      <v-stepper-content :step="6">
        <v-layout wrap>
          <v-flex class="pl-2 pr-2" xs12>
            <PolicyQuestionSelection
              v-show="!isHelplinePolicy"
              ref="policyQuestionSelection"
              :policy-id="
                selectedPolicy
                  ? selectedPolicy.policyBasicDetail && selectedPolicy.policyBasicDetail.policyId
                    ? selectedPolicy.policyBasicDetail.policyId
                    : 0
                  : 0
              "
              :insurer-id="selectedInsurerId"
              :third-party-detail="getThirdPartyDetail"
              :selected-emergencies.sync="selectedEmergencies"
              :selected-emergency-details.sync="selectedEmergencyDetails"
              :is-reset-current-index="isResetCurrentIndex"
              :job-type="jobType"
            ></PolicyQuestionSelection>
          </v-flex>
        </v-layout>
        <v-btn
          class="ml-2 btn-savePolicyEmergencyCoverage"
          color="primary"
          :disabled="isLoading"
          :loading="isLoading"
          @click.native="processCurrentPage"
        >
          {{ isUserRoleClientManager ? 'Next' : 'Save And Continue' }}
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        :step="7"
        :complete="step7validated && currentStep > 7"
        :editable="
          jobType === 'HE'
            ? step1validated &&
              step2validated &&
              step3validated &&
              step4validated &&
              step5validated &&
              (!isHelplinePolicy ? step6validated : true) &&
              currentStep > 7
            : step1validated && step2validated && step6validated && currentStep > 7
        "
        class="switch-to-step7"
        @click.native.stop="switchToStep(7)"
      >
        Select {{ jobTypeLabel('emergency') }} Questions
      </v-stepper-step>
      <v-stepper-content :step="7">
        <SelectedPolicyQuestionList
          ref="selectedPolicyQuestionList"
          :selected-entities="selectedEntityListForQuestion"
          :policy-question-list="policyQuestionList"
          :selected-emergencies="selectedEmergencies"
          :selected-emergency-details="selectedEmergencyDetails"
          :third-party-detail="getThirdPartyDetail"
          :policy-list="policyList"
          :policy-id="
            selectedPolicy
              ? selectedPolicy.policyBasicDetail && selectedPolicy.policyBasicDetail.policyId
                ? selectedPolicy.policyBasicDetail.policyId
                : 0
              : 0
          "
          :trades="trades"
          :job-type="jobType"
          :is-helpline-policy="isHelplinePolicy"
          @bindPolicyQuestions="bindPolicyQuestions"
          @showSnackbarMessage="onShowSnackbarMessage"
        ></SelectedPolicyQuestionList>
        <v-btn
          class="mt-4 btn-savePolicyQuestionList"
          color="primary"
          :disabled="isLoading"
          :loading="isLoading"
          @click.native="processCurrentPage"
        >
          {{ isUserRoleClientManager ? 'Next' : 'Save And Continue' }}
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        :step="8"
        :complete="step8validated && currentStep > 8"
        :editable="
          step1validated &&
          step2validated &&
          step3validated &&
          step4validated &&
          step5validated &&
          (!isHelplinePolicy ? step6validated : true) &&
          step7validated &&
          currentStep > 7
        "
      >
        Select Document Template
      </v-stepper-step>
      <v-stepper-content :step="8">
        <v-layout wrap>
          <v-flex class="pl-2 pr-2" xs12>
            <PolicyDocumentTemplate
              ref="policyDocumentTemplate"
              :policy-document-templates="policyModel.policyDocumentTemplates"
            ></PolicyDocumentTemplate>
            <v-btn
              v-if="!isUserRoleClientManager"
              class="mt-4 btn-savePolicyDocumentTemplate"
              color="primary"
              :disabled="isLoading"
              :loading="isLoading"
              @click.native="processCurrentPage"
            >
              Save
            </v-btn>
            <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
              {{ saveSnackbarText }}
              <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
            </v-snackbar>
          </v-flex>
        </v-layout>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PolicyModel from '@/models/policy/PolicyModel'
import PolicyRouteToMarketModel from '@/models/policy/PolicyRouteToMarketModel'
import PolicyHolderController from '@/api/policyHolderController'
import Shared from '@/common/shared'
import EmergencyQuestionGenerator from '@/components/EmergencyQuestionGenerator.vue'
import EmergencyQuestionList from '@/components/EmergencyQuestionList.vue'
import PolicyQuestionSelection from '@/components/PolicyQuestionSelection.vue'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import { QuestionEntityType, Complexity, Priority } from '@/common/enums'
import EmergencyCoverageModel from '@/models/policyHolder/EmergencyCoverageModel'
import PolicyQuestionModel from '@/models/policy/PolicyQuestionModel'
import CustomerPortalListItemModel from '@/models/client/CustomerPortalListItemModel'
import CustomerPortalsController from '@/api/customerPortalsController'
import Store from '@/store'
import AudioUpload from '@/components/AudioUpload.vue'
import storeMutations from '@/storeMutations'
import storeGetters from '@/storeGetters'
import eventBus from '@/common/bus'
import TradeModel from '@/models/policyHolder/TradeModel'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'
import ClientRatePackageModel from '@/models/client/ClientRatePackageModel'
import PolicySubDetail from '@/components/PolicySubDetail.vue'
import PolicyRouteToMarket from '@/components/PolicyRouteToMarket.vue'
import SelectedPolicyQuestionList from '@/components/SelectedPolicyQuestionList.vue'
import PolicyDocumentTemplate from '@/components/PolicyDocumentTemplate.vue'
import PolicyBasicDetailModel from '@/models/policy/PolicyBasicDetailModel'
import { JobType } from '@/models/types'
import PolicyScheduleController from '@/api/policyScheduleController'
import PolicyDocumentTemplateModel from '@/models/policy/PolicyDocumentTemplateModel'
import PolicyCommunicationDetailModel from '@/models/policy/PolicyCommunicationDetailModel'
import PolicyAccommodationDetailModel from '@/models/policy/PolicyAccommodationDetailModel'
import SavePolicyRouteToMarketModel from '@/models/policy/SavePolicyRouteToMarketModel'
import SavePolicyEmergencyCoverageModel from '@/models/policy/SavePolicyEmergencyCoverageModel'
import SavePolicyEmergencyQuestionsModel from '@/models/policy/SavePolicyEmergencyQuestionsModel'
import SavePolicyDocumentTemplatesModel from '@/models/policy/SavePolicyDocumentTemplatesModel'
import ClientRatePackageUSModel from '../models/client/ClientRatePackageUSModel'

@Component({
  name: 'PolicySchedule',
  components: {
    EmergencyQuestionGenerator,
    EmergencyQuestionList,
    PolicyQuestionSelection,
    AudioUpload,
    PolicySubDetail,
    PolicyRouteToMarket,
    SelectedPolicyQuestionList,
    PolicyDocumentTemplate,
  },
})
export default class PolicySchedule extends Vue {
  @Prop() private selectedInsurerId: number
  @Prop() private polices: PolicyModel[]
  @Prop() private policySearchRunning: boolean
  @Prop() private allClientRatePackageList: ClientRatePackageModel[] | ClientRatePackageUSModel[]
  @Prop() private jobType: JobType
  private step1formRef = 'ps_step1form'
  private step2formRef = 'ps_step2form'
  private step3formRef = 'ps_step3form'
  private step4formRef = 'ps_step4form'
  private step5formRef = 'ps_step5form'
  private step6formRef = 'ps_step6form'
  private step7formRef = 'ps_step7form'
  private step8formRef = 'ps_step8form'
  private step1validated = false
  private step2validated = false
  private step3validated = false
  private step4validated = false
  private step5validated = false
  private step6validated = false
  private step7validated = false
  private step8validated = false
  private step1visited = false
  private step2visited = false
  private step3visited = false
  private step4visited = false
  private step5visited = false
  private step6visited = false
  private step7visited = false
  private step8visited = false
  private currentStep = 1
  private selectedPolicy: PolicyModel | string | null = null
  private selectedPolicyIsNew = false
  private policyModel: PolicyModel = new PolicyModel()
  private selectedEntities: any = []
  private selectedEmergencies: EmergencyModel[] = []
  private selectedEmergencyDetails: EmergencyDetailModel[] = []
  private emergencyCoverageList: EmergencyCoverageModel[] = []
  private policyQuestionList: PolicyQuestionModel[] = []
  private saveSnackbar = false
  private saveSnackbarTimeout = 5000
  private saveSnackbarText = ''
  private isLoading = false
  private imageName = ''
  private imageUrl = ''
  private policyRoutetoMarket: PolicyRouteToMarketModel[] = []
  private announcementMessage: string | null = ''
  private reassuranceMessageOne: string | null = ''
  private reassuranceMessageTwo: string | null = ''
  private surgeMessage: string | null = ''
  private complexity = Complexity
  private priority = Priority
  private trades: TradeModel[] = []
  private thirdPartyDetail: EmergencyThirdPartyTrade[] = []
  private policyList: PolicyModel[] = []
  private selectedRatePackage: ClientRatePackageModel | ClientRatePackageUSModel | null = null
  private isHelplinePolicy = false
  private tempSelectedEmergencies: EmergencyModel[] = []
  private customerPortals: CustomerPortalListItemModel[] = []
  private isResetCurrentIndex = false
  private policyBasicDetailModel: PolicyBasicDetailModel = new PolicyBasicDetailModel()
  private tempPolicyBasicDetailModel: PolicyBasicDetailModel = new PolicyBasicDetailModel()
  private tempPolicyRoutetoMarket: PolicyRouteToMarketModel[] = []
  private tempSelectedEmergencyDetails: EmergencyDetailModel[] = []
  private tempSelectedEmergencyTypes: EmergencyModel[] = []
  private tempPolicyQuestionList: PolicyQuestionModel[] = []
  private policyCommunicationDetail: PolicyCommunicationDetailModel = new PolicyCommunicationDetailModel()
  private tempPolicyCommunicationDetail: PolicyCommunicationDetailModel = new PolicyCommunicationDetailModel()
  private policyAccommodationDetail: PolicyAccommodationDetailModel = new PolicyAccommodationDetailModel()
  private tempPolicyAccommodationDetail: PolicyAccommodationDetailModel = new PolicyAccommodationDetailModel()

  private get getThirdPartyDetail() {
    return this.thirdPartyDetail
  }

  private async mounted() {
    // reset policy schedule from menu item click
    eventBus.$on('resetPolicySchedule', () => {
      this.resetPolicySchedule()
    })
    this.onSelectedInsurerIdChange()
  }

  @Watch('selectedInsurerId')
  private async onSelectedInsurerIdChange(): Promise<void> {
    // HACK: The templates should come from a store, or at the very least, the selected client ID should.
    this.customerPortals = this.selectedInsurerId
      ? await CustomerPortalsController.GetPortalsByClient(this.selectedInsurerId)
      : []
  }

  private removeAllProducts(): void {
    this.policyRoutetoMarket.splice(0, this.policyRoutetoMarket.length)
  }

  private OnAnnouncementMessageChange(message, file, isTextMessage) {
    this.policyCommunicationDetail.announcementMessage = message
    this.policyCommunicationDetail.announcementMessageFile = file
    this.policyCommunicationDetail.announcementMessageIsText = isTextMessage
  }

  private OnReassuranceMessageOneChange(message, file, isTextMessage) {
    this.policyCommunicationDetail.reassuranceMessageOne = message
    this.policyCommunicationDetail.reassuranceMessageOneFile = file
    this.policyCommunicationDetail.reassuranceMessageOneIsText = isTextMessage
  }

  private OnReassuranceMessageTwoChange(message, file, isTextMessage) {
    this.policyCommunicationDetail.reassuranceMessageTwo = message
    this.policyCommunicationDetail.reassuranceMessageTwoFile = file
    this.policyCommunicationDetail.reassuranceMessageTwoIsText = isTextMessage
  }

  private OnSurgeMessageChange(message, file, isTextMessage) {
    this.policyCommunicationDetail.surgeMessage = message
    this.policyCommunicationDetail.surgeMessageFile = file
    this.policyCommunicationDetail.surgeMessageIsText = isTextMessage
  }

  @Watch('selectedEmergencies')
  private onSelectedEmergenciesChange() {
    // update temp variable when selectedEmergencies changed
    if (!this.policyBasicDetailModel.isHelplinePolicy) {
      this.tempSelectedEmergencies = this.selectedEmergencies
    }
  }

  private onIsHelplinePolicyChange() {
    if (this.policyBasicDetailModel.isHelplinePolicy) {
      this.policyCommunicationDetail.isPortalLinkEnabled = false
    } else {
      this.selectedEmergencies = this.tempSelectedEmergencies
    }
  }

  private getPolicyRouteToMarketList(insurerId) {
    PolicyHolderController.GetPolicyRouteToMarketList(insurerId)
      .then((response: PolicyRouteToMarketModel[]) => {
        storeMutations.setPolicyRouteToMarketList(response)
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading policy route to market list, please try again', true)
      })
  }

  private switchToStep(step: number): void {
    const self = this
    self.isResetCurrentIndex = true
    this.validateStep(self.currentStep)
      .then((result: [number, string, boolean]) => {
        const passed: boolean = result[2]
        if (passed) {
          switch (self.currentStep) {
            case 1:
              if (!self.step1visited) {
                return
              }
              self.step1visited = false
              break
            case 2:
              if (!self.step2visited) {
                return
              }
              self.step2visited = false
              break
            case 3:
              if (!self.step3visited) {
                return
              }
              self.step3visited = false
              break
            case 4:
              if (!self.step4visited) {
                return
              }
              self.step4visited = false
              break
            case 5:
              if (!self.step5visited) {
                return
              }
              self.step5visited = false
              break
            case 6:
              if (!self.step6visited) {
                return
              }
              self.step6visited = false
              break
            case 7:
              if (!self.step7visited) {
                return
              }
              self.step7visited = false
              break
            case 8:
              if (!self.step8visited) {
                return
              }
              self.step8visited = false
              break
            default:
              break
          }
        }
        switch (result[1]) {
          case self.step1formRef:
            self.step1validated = passed
            break
          case self.step2formRef:
            self.step2validated = passed
            break
          case self.step3formRef:
            self.step3validated = passed
            break
          case self.step4formRef:
            self.step4validated = passed
            break
          case self.step5formRef:
            self.step5validated = passed
            break
          case self.step6formRef:
            self.step6validated = passed
            break
          case self.step7formRef:
            self.step7validated = passed
            break
          case self.step8formRef:
            self.step8validated = passed
            break
        }
        if (passed) {
          self.currentStep = step
        } else {
          self.currentStep = result[0]
        }
        self.isResetCurrentIndex = false
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private processCurrentPage(): void {
    const self = this
    self.isResetCurrentIndex = true
    this.validateStep(self.currentStep)
      .then((result: [number, string, boolean]) => {
        const passed: boolean = result[2]
        switch (result[1]) {
          case self.step1formRef:
            self.step1validated = passed
            if (passed) {
              this.isLoading = true
              self.setPolicyDetails()
              if (this.jobType === 'HE') {
                self.getPolicyRouteToMarketList(Number(self.selectedInsurerId))
              }
              self.policyList = self.polices
            }
            break
          case self.step2formRef:
            self.step2validated = passed
            if (passed) {
              const policyDetailSub = this.$refs.policySubDetail as PolicySubDetail
              if (policyDetailSub) {
                if (policyDetailSub.selectedRatePackage) {
                  policyDetailSub.policyModel.ratePackageId = policyDetailSub.selectedRatePackage.id
                }
                if (
                  (policyDetailSub.policyModel.policyId !== 0 &&
                    JSON.stringify(policyDetailSub.policyModel) === JSON.stringify(self.tempPolicyBasicDetailModel)) ||
                  this.isUserRoleClientManager
                ) {
                  // No change in policy detail, move to next step
                  self.redirectToStep3()
                } else {
                  // policy detail changed, API call to save policy detail
                  self.savePolicyBasicDetail(policyDetailSub.policyModel)
                }
              }
            }
            break
          case self.step3formRef:
            self.step3validated = passed
            if (passed) {
              if (!this.policyCommunicationDetail.effortScore) {
                // revert back the changes if effortscore set to false
                this.policyCommunicationDetail.totalSurveysPerJobPerDay =
                  self.tempPolicyCommunicationDetail.totalSurveysPerJobPerDay
                this.policyCommunicationDetail.totalSurveysPerJob =
                  self.tempPolicyCommunicationDetail.totalSurveysPerJob
              }
              if (
                JSON.stringify(self.tempPolicyCommunicationDetail) === JSON.stringify(self.policyCommunicationDetail) ||
                this.isUserRoleClientManager
              ) {
                // no changes in policy communication details
                self.redirectToStep4()
              } else {
                // change in policy communication details, API Call to save detail
                self.savePolicyCommunicationDetail(self.policyCommunicationDetail)
              }
              if (!self.selectedInsurerId) {
                return
              }
              const policyRouteToMarketSub = this.$refs.policyRouteToMarket as PolicyRouteToMarket
              if (policyRouteToMarketSub) {
                policyRouteToMarketSub.bindPolicyRouteToMarketListByInsurerId(
                  self.selectedInsurerId,
                  self.policyModel.policyBasicDetail.policyId
                )
                self.tempPolicyRoutetoMarket = JSON.parse(
                  JSON.stringify(policyRouteToMarketSub.localPolicyRoutetoMarket)
                )
              }
            }
            break
          case self.step4formRef:
            self.step4validated = passed
            if (passed) {
              const policyRouteToMarketSub = this.$refs.policyRouteToMarket as PolicyRouteToMarket
              if (policyRouteToMarketSub) {
                if (
                  JSON.stringify(policyRouteToMarketSub.localPolicyRoutetoMarket) ===
                    JSON.stringify(self.tempPolicyRoutetoMarket) ||
                  this.isUserRoleClientManager
                ) {
                  // No change in policy route to market, move to next step
                  this.redirectToStep5()
                } else {
                  // policy route to market changed, API call to save detail
                  self.savePolicyRouteToMarket(policyRouteToMarketSub.localPolicyRoutetoMarket)
                }
              }
            }
            break
          case self.step5formRef:
            self.step5validated = passed
            if (passed) {
              if (
                JSON.stringify(this.policyAccommodationDetail) === JSON.stringify(this.tempPolicyAccommodationDetail) ||
                this.isUserRoleClientManager
              ) {
                // No change in policy accommodation detail, move to next step
                self.redirectToSte6()
              } else {
                // policy accommodation detail changed, API call to save detail
                self.saveAccommodationDetail(self.policyAccommodationDetail)
              }
            }
            break
          case self.step6formRef:
            self.step6validated = passed
            if (passed) {
              if (
                (JSON.stringify(self.tempSelectedEmergencyTypes) === JSON.stringify(self.selectedEmergencies) &&
                  JSON.stringify(self.selectedEmergencyDetails) ===
                    JSON.stringify(self.tempSelectedEmergencyDetails)) ||
                this.isUserRoleClientManager
              ) {
                // No change in selected emergencies, move to next step
                this.redirectToStep7()
              } else {
                // selected emergencies changed, API call to save detail
                self.savePolicyEmergencyCoverage(self.selectedEmergencies, self.selectedEmergencyDetails)
              }
            }
            break
          case self.step7formRef:
            self.step7validated = passed
            if (passed) {
              const selectedPolicyQuestionListSub = this.$refs.selectedPolicyQuestionList as SelectedPolicyQuestionList
              if (selectedPolicyQuestionListSub) {
                if (
                  JSON.stringify(self.tempPolicyQuestionList) ===
                    JSON.stringify(selectedPolicyQuestionListSub.localPolicyQuestionList) ||
                  this.isUserRoleClientManager
                ) {
                  // No change in policy question selection, move to next step
                  self.redirectToStep8()
                } else {
                  // policy question selection changed, API call to save selected policy question
                  self.savePolicyQuestionList(selectedPolicyQuestionListSub.localPolicyQuestionList)
                }
              }
            }
            break
          case self.step8formRef:
            self.step8validated = passed
            if (passed) {
              const policyDocumentTemplateSub = this.$refs.policyDocumentTemplate as PolicyDocumentTemplate
              if (policyDocumentTemplateSub) {
                if (
                  JSON.stringify(self.policyModel.policyDocumentTemplates) ===
                    JSON.stringify(policyDocumentTemplateSub.localPolicyDocumentTemplates) ||
                  this.isUserRoleClientManager
                ) {
                  // No change policy document template selection
                  this.redirectToStep1()
                } else {
                  // policy document template selection, API call to save document template detail
                  self.savePolicyDocumentTemplate(policyDocumentTemplateSub.localPolicyDocumentTemplates)
                }
              }
            }
            break
        }
        self.isResetCurrentIndex = false
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validateStep(step: number): Promise<[number, string, boolean]> {
    const self = this
    let scope = ''
    switch (step) {
      case 1:
        scope = self.step1formRef
        break
      case 2:
        scope = self.step2formRef
        break
      case 3:
        scope = self.step3formRef
        break
      case 4:
        scope = self.step4formRef
        break
      case 5:
        scope = self.step5formRef
        break
      case 6:
        scope = self.step6formRef
        break
      case 7:
        scope = self.step7formRef
        break
      case 8:
        scope = self.step8formRef
        break
      default:
        return [step, scope, false]
    }
    let result: boolean = await self.$validator.validateAll(scope)
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    } else {
      let result2 = true
      if (step === 2) {
        const policyDetailSub = this.$refs.policySubDetail as PolicySubDetail
        result2 = await policyDetailSub.$validator.validateAll()
        if (!result2) {
          Shared.setValidationFocus(this.$el as HTMLElement)
          result = result2
        }
      }
    }
    return [step, scope, result]
  }

  private setPolicyDetails(): void {
    const self = this
    if (self.selectedPolicy === null) {
      return
    }
    if (typeof self.selectedPolicy === 'string' && self.polices) {
      const policy: PolicyModel | undefined = self.polices.find(
        (e: PolicyModel) => e.policyBasicDetail.name === String(self.selectedPolicy).trim()
      )
      if (policy) {
        this.selectedPolicy = policy
      }
    }
    if (typeof self.selectedPolicy === 'string') {
      self.selectedPolicyIsNew = true
      self.policyModel = new PolicyModel()
      self.policyModel.policyBasicDetail.name = String(self.selectedPolicy)
      self.policyModel.policyBasicDetail.insurerId = Number(self.selectedInsurerId)
      self.policyModel.policyBasicDetail.policyId = 0
      self.imageUrl = ''
      self.imageName = ''
      self.announcementMessage = ''
      self.reassuranceMessageOne = ''
      self.reassuranceMessageTwo = ''
      self.surgeMessage = ''
      self.removeAllProducts()
      const policyDocumentTemplateSub = this.$refs.policyDocumentTemplate as PolicyDocumentTemplate
      if (policyDocumentTemplateSub) {
        policyDocumentTemplateSub.getDocumentTemplateList(Number(self.selectedInsurerId))
      }
      self.currentStep = 2
      self.step1visited = true
      self.isLoading = false
      // set policy sub detail - step2
      self.setPolicyBasicDetailOnLoad()
    } else {
      self.selectedPolicyIsNew = false
      const policyId: number = self.selectedPolicy.policyBasicDetail.policyId
      PolicyScheduleController.GetPolicyById(policyId).then((res: PolicyModel | null) => {
        if (res === null) {
          self.isLoading = false
          return
        }
        self.policyModel = res

        self.imageUrl =
          self.policyModel.policyBasicDetail.fileURL !== null ? self.policyModel.policyBasicDetail.fileURL : ''
        self.imageName = self.imageUrl !== '' ? self.imageUrl.replace(/^.*[\\/]/, '') : ''
        if (this.policyModel.policyBasicDetail.ratePackageId !== null) {
          const clientRatePackageList = (this.allClientRatePackageList as any).filter(
            (a: ClientRatePackageModel | ClientRatePackageUSModel) =>
              a.forCustomPackagePolicyId === this.policyModel.policyBasicDetail.policyId ||
              a.forCustomPackagePolicyId === 0
          )
          const selectedClientRatePackage: ClientRatePackageModel | ClientRatePackageUSModel | undefined =
            clientRatePackageList.find(
              (e: ClientRatePackageModel | ClientRatePackageUSModel) =>
                e.id === this.policyModel.policyBasicDetail.ratePackageId
            )
          if (selectedClientRatePackage) {
            this.selectedRatePackage = selectedClientRatePackage
          }
        }
        // set policy sub detail - step2
        self.setPolicyBasicDetailOnLoad()
        // set policy communication detail - step3
        self.policyCommunicationDetail = Object.assign(
          new PolicyCommunicationDetailModel(),
          self.policyModel.policyCommunicationDetail
        )
        self.tempPolicyCommunicationDetail = Object.assign(
          new PolicyCommunicationDetailModel(),
          self.policyCommunicationDetail
        )

        self.announcementMessage =
          self.policyCommunicationDetail.announcementMessage !== ''
            ? self.policyCommunicationDetail.announcementMessageIsText === true
              ? self.policyCommunicationDetail.announcementMessage
              : self.policyCommunicationDetail.announcementMessage
              ? self.policyCommunicationDetail.announcementMessage.replace(/^.*[\\/]/, '')
              : ''
            : ''
        self.reassuranceMessageOne =
          self.policyCommunicationDetail.reassuranceMessageOne !== ''
            ? self.policyCommunicationDetail.reassuranceMessageOneIsText === true
              ? self.policyCommunicationDetail.reassuranceMessageOne
              : self.policyCommunicationDetail.reassuranceMessageOne
              ? self.policyCommunicationDetail.reassuranceMessageOne.replace(/^.*[\\/]/, '')
              : ''
            : ''
        self.reassuranceMessageTwo =
          self.policyCommunicationDetail.reassuranceMessageTwo !== ''
            ? self.policyCommunicationDetail.reassuranceMessageTwoIsText === true
              ? self.policyCommunicationDetail.reassuranceMessageTwo
              : self.policyCommunicationDetail.reassuranceMessageTwo
              ? self.policyCommunicationDetail.reassuranceMessageTwo.replace(/^.*[\\/]/, '')
              : ''
            : ''
        self.surgeMessage =
          self.policyCommunicationDetail.surgeMessage !== ''
            ? self.policyCommunicationDetail.surgeMessageIsText === true
              ? self.policyCommunicationDetail.surgeMessage
              : self.policyCommunicationDetail.surgeMessage
              ? self.policyCommunicationDetail.surgeMessage.replace(/^.*[\\/]/, '')
              : ''
            : ''

        // set policy accommodation detail - step5
        self.policyAccommodationDetail = self.policyModel.policyAccommodationDetail
        self.tempPolicyAccommodationDetail = Object.assign(
          new PolicyAccommodationDetailModel(),
          self.policyAccommodationDetail
        )

        if (res.policyQuestion) {
          self.bindPolicyQuestions(res.policyQuestion)
        }
        self.bindEmergencyCoverage(res.emergencyCoverage)
        if (res.policyRouteToMarket) {
          self.policyRoutetoMarket = res.policyRouteToMarket
        }
        const policyDocumentTemplateSub = this.$refs.policyDocumentTemplate as PolicyDocumentTemplate
        if (policyDocumentTemplateSub) {
          policyDocumentTemplateSub.getDocumentTemplateList(Number(self.selectedInsurerId))
        }
        this.thirdPartyDetail = self.policyModel.thirdPartyDetail ? self.policyModel.thirdPartyDetail : []
        this.isHelplinePolicy = self.policyBasicDetailModel.isHelplinePolicy
        self.currentStep = 2
        self.step1visited = true
        self.isLoading = false
      })
    }
  }

  private get selectedEntityListForQuestion(): any {
    return this.selectedEntities
  }

  private onCompleteStep1(): void {
    setTimeout(() => {
      this.processCurrentPage()
    }, 0)
  }

  private onCompleteStep6(): void {
    const self = this
    this.selectedEntities = []
    this.emergencyCoverageList = []
    // add policy
    const policyEntity: any = {}
    policyEntity.id = this.selectedInsurerId
    policyEntity.displayText = 'Policy-wide'
    policyEntity.entityType = QuestionEntityType[QuestionEntityType.Policy]
    policyEntity.insurerId = this.selectedInsurerId
    policyEntity.emergencyDetails = []

    this.selectedEntities.push(policyEntity)

    // reset question list
    let oldPolicyQuestionList: PolicyQuestionModel[] = []
    const selectedPolicyQuestionListRef = this.$refs.selectedPolicyQuestionList as SelectedPolicyQuestionList
    if (selectedPolicyQuestionListRef) {
      oldPolicyQuestionList = selectedPolicyQuestionListRef.localPolicyQuestionList
    }
    this.policyQuestionList = []

    // add policy wide question into question list
    const policyQuestions: PolicyQuestionModel[] = oldPolicyQuestionList.filter(
      (e: PolicyQuestionModel) => e.entityType === QuestionEntityType[QuestionEntityType.Policy]
    )
    policyQuestions.forEach((element: PolicyQuestionModel) => {
      const index = self.policyQuestionList.findIndex((e) => e.questionId === element.questionId)
      if (index === -1) {
        this.policyQuestionList.push(element)
      }
    })

    this.selectedEmergencies.forEach((emergency: EmergencyModel) => {
      const thirdPartyDetail = self.getThirdPartyDetail.find((e) => e.emergencyTypeId === emergency.emergencyId)
      if (thirdPartyDetail !== undefined) {
        return
      } else {
        // add emergency
        const emergencyEntity: any = {}
        emergencyEntity.id = emergency.id
        emergencyEntity.displayText = emergency.description
        emergencyEntity.hideOnCustomerPortals = emergency.hideOnCustomerPortals
        emergencyEntity.entityType = QuestionEntityType[QuestionEntityType.Emergency]
        emergencyEntity.insurerId = self.selectedInsurerId
        emergencyEntity.emergencyId = emergency.emergencyId
        emergencyEntity.isLocal = emergency.isLocal
        emergencyEntity.emergencyDetails = []

        // add emergency level question into question list
        const emergencyQuestions: PolicyQuestionModel[] = oldPolicyQuestionList.filter(
          (e: PolicyQuestionModel) =>
            e.entityType === QuestionEntityType[QuestionEntityType.Emergency] &&
            e.question.emergencyId === emergency.emergencyId
        )
        emergencyQuestions.forEach((element: PolicyQuestionModel) => {
          const index = self.policyQuestionList.findIndex((e) => e.questionId === element.questionId)
          if (index === -1) {
            this.policyQuestionList.push(element)
          }
        })

        // add emergency detail
        const emergencyDetailList: EmergencyDetailModel[] = self.selectedEmergencyDetails.filter(
          (e: EmergencyDetailModel) => e.typeId === emergency.emergencyId
        )
        if (emergencyDetailList) {
          emergencyDetailList.forEach((emergencyDetail: EmergencyDetailModel) => {
            const emergencyDetailEntity: any = {}
            emergencyDetailEntity.id = emergencyDetail.id
            emergencyDetailEntity.displayText = emergencyDetail.description
            emergencyDetailEntity.entityType = QuestionEntityType[QuestionEntityType.EmergencyDetail]
            emergencyDetailEntity.insurerId = self.selectedInsurerId
            emergencyDetailEntity.emergencyDetailId = emergencyDetail.emergencyDetailId
            emergencyDetailEntity.isLocal = emergencyDetail.isLocal
            emergencyDetailEntity.emergencyCoverage = null

            if (emergencyDetail.emergencyCoverage) {
              this.emergencyCoverageList.push(emergencyDetail.emergencyCoverage)
            }
            emergencyEntity.emergencyDetails.push(emergencyDetailEntity)

            // add emergency detail level question into question list
            const emergencyDetailQuestions: PolicyQuestionModel[] = oldPolicyQuestionList.filter(
              (e: PolicyQuestionModel) =>
                e.entityType === QuestionEntityType[QuestionEntityType.EmergencyDetail] &&
                e.question.emergencyDetailId === emergencyDetail.emergencyDetailId
            )
            emergencyDetailQuestions.forEach((element: PolicyQuestionModel) => {
              const index = self.policyQuestionList.findIndex((e) => e.questionId === element.questionId)
              if (index === -1) {
                this.policyQuestionList.push(element)
              }
            })
          })
        }
        self.selectedEntities.push(emergencyEntity)
      }
    })
    const selectedPolicyQuestionListSub = this.$refs.selectedPolicyQuestionList as SelectedPolicyQuestionList
    if (selectedPolicyQuestionListSub) {
      const questionList: EmergencyQuestionList = selectedPolicyQuestionListSub.$refs
        .questionList as EmergencyQuestionList
      if (questionList) {
        questionList.setList(self.policyQuestionList)
      }
    }
    self.tempPolicyQuestionList = JSON.parse(JSON.stringify(self.policyQuestionList))
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades && trades.length === 0) {
      PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          if (res) {
            this.trades = res.filter((e) => e.jobType === this.jobType)
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      this.trades = trades.filter((e) => e.jobType === this.jobType)
    }
  }

  // reset all field of policy schedule and take back at top on step 1
  private resetPolicySchedule() {
    this.selectedPolicy = null
    this.policyModel = new PolicyModel()
    this.currentStep = 1
    this.step1validated = false
    this.step2validated = false
    this.step3validated = false
    this.step4validated = false
    this.step5validated = false
    this.step6validated = false
    this.step7validated = false
    this.step8validated = false
    this.step1visited = false
    this.step2visited = false
    this.step3visited = false
    this.step4visited = false
    this.step5visited = false
    this.step6visited = false
    this.step7visited = false
    this.step8visited = false
    this.selectedEmergencies = []
    this.tempSelectedEmergencies = []
    this.selectedEmergencyDetails = []
    this.selectedEntities = []

    // reset all the variable used for import policy questions
    this.policyQuestionList = []
    const selectedPolicyQuestionListSub = this.$refs.selectedPolicyQuestionList as SelectedPolicyQuestionList
    if (selectedPolicyQuestionListSub) {
      selectedPolicyQuestionListSub.currentSelectedPolicyIdToCopy = null
      selectedPolicyQuestionListSub.closePolicyQuestionCopyPopups()
      selectedPolicyQuestionListSub.resetTempPolicyQuestionCopyObjects()
      const questionList: EmergencyQuestionList = selectedPolicyQuestionListSub.$refs
        .questionList as EmergencyQuestionList
      if (questionList) {
        questionList.setList([])
      }
    }
    this.policyList = []
    // null emergencies and emergencyDetails for policyQuestionSelection page
    const policyQuestionSelection: PolicyQuestionSelection = this.$refs
      .policyQuestionSelection as PolicyQuestionSelection
    this.selectedRatePackage = null
    if (policyQuestionSelection) {
      policyQuestionSelection.setList([], [])
    }
    setTimeout(() => {
      this.$validator.errors.items = []
      // set scroll to default position
      const containerForRouteToMarket: any = this.$el.querySelector('.route-to-market .vb-content')
      if (containerForRouteToMarket) {
        containerForRouteToMarket.scrollTop = 0
      }
    }, 0)
    // remove all policy route to market products
    this.removeAllProducts()
    this.isHelplinePolicy = false
    // reset policy communication detail
    this.announcementMessage = ''
    this.reassuranceMessageOne = ''
    this.reassuranceMessageTwo = ''
    this.surgeMessage = ''
    const announcementMessageRef = this.$refs.announcementMessageRef as AudioUpload
    if (announcementMessageRef) {
      announcementMessageRef.audioMessage = ''
    }
    const reassuranceMessageOneRef = this.$refs.reassuranceMessageOneRef as AudioUpload
    if (reassuranceMessageOneRef) {
      reassuranceMessageOneRef.audioMessage = ''
    }
    const reassuranceMessageTwoRef = this.$refs.reassuranceMessageTwoRef as AudioUpload
    if (reassuranceMessageTwoRef) {
      reassuranceMessageTwoRef.audioMessage = ''
    }
    const surgeMessageRef = this.$refs.surgeMessageRef as AudioUpload
    if (surgeMessageRef) {
      surgeMessageRef.audioMessage = ''
    }
    // reset models used for binding
    this.policyBasicDetailModel = new PolicyBasicDetailModel()
    this.tempPolicyBasicDetailModel = new PolicyBasicDetailModel()
    this.policyCommunicationDetail = new PolicyCommunicationDetailModel()
    this.tempPolicyCommunicationDetail = new PolicyCommunicationDetailModel()
    this.policyAccommodationDetail = new PolicyAccommodationDetailModel()
    this.tempPolicyAccommodationDetail = new PolicyAccommodationDetailModel()
    this.tempPolicyRoutetoMarket = []
    this.tempSelectedEmergencyDetails = []
    this.tempSelectedEmergencyTypes = []
    this.tempPolicyQuestionList = []
  }

  private bindPolicyQuestions(questions: PolicyQuestionModel[]): void {
    const self = this
    const policyQuestionSelection: PolicyQuestionSelection = this.$refs
      .policyQuestionSelection as PolicyQuestionSelection
    if (questions) {
      questions.forEach((e: PolicyQuestionModel) => {
        if (e.question) {
          if (e.question.emergencyId) {
            e.entityType = QuestionEntityType[QuestionEntityType.Emergency]
            // set questionType column data
            const emergency: EmergencyModel | undefined = policyQuestionSelection.getEmergency(e.question.emergencyId)
            if (emergency) {
              e.entityName = emergency.description
            }
          } else if (e.question.emergencyDetailId) {
            e.entityType = QuestionEntityType[QuestionEntityType.EmergencyDetail]
            // set questionType column data
            const emergencyDetail: EmergencyDetailModel | undefined = policyQuestionSelection.getEmergencyDetail(
              e.question.emergencyDetailId
            )
            if (emergencyDetail) {
              e.entityName = emergencyDetail.description
            }
          } else {
            e.entityType = QuestionEntityType[QuestionEntityType.Policy]
            e.entityName = 'Policy-wide'
          }
        } else {
          e.entityType = QuestionEntityType[QuestionEntityType.Policy]
          e.entityName = 'Policy-wide'
        }
      })
      questions.sort((a: PolicyQuestionModel, b: PolicyQuestionModel) => {
        if (a.orderBy < b.orderBy) {
          return -1
        }
        if (a.orderBy > b.orderBy) {
          return 1
        }
        return -1
      })
      self.policyQuestionList = questions
      const selectedPolicyQuestionListSub = this.$refs.selectedPolicyQuestionList as SelectedPolicyQuestionList
      if (selectedPolicyQuestionListSub) {
        const questionList: EmergencyQuestionList = selectedPolicyQuestionListSub.$refs
          .questionList as EmergencyQuestionList
        if (questionList) {
          questionList.setList(self.policyQuestionList)
        }
      }
    }
  }

  private bindEmergencyCoverage(emergencyCoverageList: EmergencyCoverageModel[] | null): void {
    const self = this
    this.selectedEmergencyDetails = []
    this.selectedEmergencies = []
    const policyQuestionSelection: PolicyQuestionSelection = this.$refs
      .policyQuestionSelection as PolicyQuestionSelection
    if (emergencyCoverageList) {
      emergencyCoverageList.forEach((e: EmergencyCoverageModel) => {
        const emergencyDetail: EmergencyDetailModel | undefined = policyQuestionSelection.getEmergencyDetail(
          e.emergencyDetailId
        )
        if (emergencyDetail) {
          emergencyDetail.emergencyCoverage = e
          emergencyDetail.isEmergencyCoverageExist = true
          self.selectedEmergencyDetails.push(emergencyDetail)

          if (!self.selectedEmergencies.find((a: EmergencyModel) => a.emergencyId === emergencyDetail.typeId)) {
            const emergency: EmergencyModel | undefined = policyQuestionSelection.getEmergency(emergencyDetail.typeId)
            if (emergency) {
              self.selectedEmergencies.push(emergency)
            }
          }
        }
      })
      self.tempSelectedEmergencies = JSON.parse(JSON.stringify(self.selectedEmergencies))
      self.tempSelectedEmergencyDetails = JSON.parse(JSON.stringify(self.selectedEmergencyDetails))
      self.tempSelectedEmergencyTypes = self.tempSelectedEmergencies
    }
    policyQuestionSelection.setList(self.selectedEmergencies, self.selectedEmergencyDetails)
  }

  private onShowSnackbarMessage(message: string) {
    const self = this
    self.saveSnackbarText = message
    self.saveSnackbar = true
  }

  private jobTypeLabel(name: string) {
    return Shared.JobTypeLabel(name, this.jobType)
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }

  private redirectToStep3() {
    const self = this
    if (this.jobType === 'HE') {
      self.currentStep = 3
    } else {
      self.currentStep = 6
      self.step3visited = true
      self.step4visited = true
      self.step5visited = true
    }
    self.step2visited = true
  }

  private redirectToStep4() {
    const self = this
    self.currentStep = 4
    self.step3visited = true
  }

  private redirectToStep5() {
    const self = this
    self.currentStep = 5
    self.step4visited = true
  }

  private redirectToSte6() {
    const self = this
    if (self.isHelplinePolicy) {
      self.step5visited = true
      this.selectedEmergencies = []
      self.onCompleteStep6()
      self.currentStep = 7
      // self.step6visited = true;
    } else {
      self.currentStep = 6
      self.step5visited = true
    }
  }

  private redirectToStep7() {
    const self = this
    self.onCompleteStep6()
    self.currentStep = 7
    self.step6visited = true
  }

  private redirectToStep8() {
    const self = this
    self.currentStep = 8
    self.step7visited = true
  }

  private redirectToStep1() {
    this.step8visited = true
    this.resetPolicySchedule()
    if (this.jobType === 'HE') {
      this.getPolicyRouteToMarketList(Number(this.selectedInsurerId))
    }
  }

  private setPolicyBasicDetailOnLoad() {
    // bind PolicyBasicDetailModel Data
    const self = this
    self.policyBasicDetailModel = Object.assign(new PolicyBasicDetailModel(), self.policyModel.policyBasicDetail)
    const policyDetailSub = this.$refs.policySubDetail as PolicySubDetail
    if (policyDetailSub) {
      policyDetailSub.setValues(self.selectedRatePackage, self.imageName, self.imageUrl)
    }
    self.tempPolicyBasicDetailModel = Object.assign(new PolicyBasicDetailModel(), self.policyBasicDetailModel)
  }

  private setPolicyDetailModelAfterChange(policyId: number) {
    const self = this
    const policyDetailSub = this.$refs.policySubDetail as PolicySubDetail
    if (policyDetailSub) {
      self.policyBasicDetailModel = policyDetailSub.policyModel
      self.policyBasicDetailModel.policyId = policyId
      self.policyModel.policyBasicDetail = self.policyBasicDetailModel
      self.selectedRatePackage = policyDetailSub.selectedRatePackage
      self.isHelplinePolicy = self.policyBasicDetailModel.isHelplinePolicy
      self.tempPolicyBasicDetailModel = Object.assign(new PolicyBasicDetailModel(), policyDetailSub.policyModel)
      self.onIsHelplinePolicyChange()
    }
  }

  private savePolicyBasicDetail(policyBasicDetail: PolicyBasicDetailModel) {
    this.isLoading = true
    policyBasicDetail.cutOffHours = policyBasicDetail.cutOffHours ? policyBasicDetail.cutOffHours : null
    policyBasicDetail.jobType = this.jobType
    PolicyScheduleController.SavePolicyDetail(policyBasicDetail)
      .then((res: number) => {
        this.showSnackbarMessage(res && res !== 0 ? 'Policy Detail Saved Successfully.' : 'Something went wrong.')
        if (res && res !== 0) {
          this.setPolicyDetailModelAfterChange(res)
          if (this.selectedPolicyIsNew) {
            this.polices.push(this.policyModel)
            this.selectedPolicy = this.policyModel
            this.selectedPolicyIsNew = false
          }
          this.redirectToStep3()
        } else {
          this.tempPolicyBasicDetailModel = new PolicyBasicDetailModel()
        }
      })
      .catch((err: any) => {
        this.showSnackbarMessage('Something went wrong.')
        eventBus.$emit('errorHandler', 'Error saving policy detail, please try again', false)
        this.policyBasicDetailModel = new PolicyBasicDetailModel()
      })
  }

  private savePolicyCommunicationDetail(policyCommunicationDetail: PolicyCommunicationDetailModel) {
    this.isLoading = true
    policyCommunicationDetail.policyId = this.policyBasicDetailModel.policyId
    policyCommunicationDetail.totalSurveysPerJobPerDay = policyCommunicationDetail.totalSurveysPerJobPerDay
      ? Number(policyCommunicationDetail.totalSurveysPerJobPerDay)
      : null
    policyCommunicationDetail.totalSurveysPerJob = policyCommunicationDetail.totalSurveysPerJob
      ? Number(policyCommunicationDetail.totalSurveysPerJob)
      : null
    PolicyScheduleController.SavePolicyCommunicationDetail(policyCommunicationDetail)
      .then((res: boolean) => {
        this.showSnackbarMessage(res ? 'Policy Communication Detail Saved Successfully.' : 'Something went wrong.')
        if (res) {
          this.redirectToStep4()
        }
        this.tempPolicyCommunicationDetail = res
          ? Object.assign(new PolicyCommunicationDetailModel(), policyCommunicationDetail)
          : new PolicyCommunicationDetailModel()
      })
      .catch((err: any) => {
        this.showSnackbarMessage('Something went wrong.')
        this.tempPolicyCommunicationDetail = new PolicyCommunicationDetailModel()
        eventBus.$emit('errorHandler', 'Error saving communication detail, please try again', false)
      })
  }

  private savePolicyRouteToMarket(policyRouteToMarket: PolicyRouteToMarketModel[]) {
    this.isLoading = true
    const savePolicyRouteToMarket: SavePolicyRouteToMarketModel = new SavePolicyRouteToMarketModel()
    savePolicyRouteToMarket.policyId = this.policyBasicDetailModel.policyId
    savePolicyRouteToMarket.policyRouteToMarketList = policyRouteToMarket
    PolicyScheduleController.SavePolicyRouteToMarket(savePolicyRouteToMarket)
      .then((res: boolean) => {
        this.showSnackbarMessage(res ? 'Policy Route to Market Detail Saved Successfully.' : 'Something went wrong.')
        if (res) {
          this.policyRoutetoMarket = policyRouteToMarket
          this.redirectToStep5()
        }
        this.tempPolicyRoutetoMarket = res ? JSON.parse(JSON.stringify(policyRouteToMarket)) : []
      })
      .catch((err: any) => {
        this.showSnackbarMessage('Something went wrong.')
        this.tempPolicyRoutetoMarket = []
        eventBus.$emit('errorHandler', 'Error saving policy route to market detail, please try again', false)
      })
  }

  private saveAccommodationDetail(accomodationDetail: PolicyAccommodationDetailModel) {
    this.isLoading = true
    accomodationDetail.policyId = this.policyBasicDetailModel.policyId
    PolicyScheduleController.SavePolicyAccommodationDetail(accomodationDetail)
      .then((res: boolean) => {
        this.showSnackbarMessage(res ? 'Policy Accomodation Detail Saved Successfully.' : 'Something went wrong.')
        if (res) {
          this.redirectToSte6()
        }
        this.tempPolicyAccommodationDetail = res
          ? Object.assign(new PolicyAccommodationDetailModel(), accomodationDetail)
          : new PolicyAccommodationDetailModel()
      })
      .catch((err: any) => {
        this.showSnackbarMessage('Something went wrong.')
        this.tempPolicyAccommodationDetail = new PolicyAccommodationDetailModel()
        eventBus.$emit('errorHandler', 'Error saving accommodation detail, please try again', false)
      })
  }

  private savePolicyEmergencyCoverage(emergencies: EmergencyModel[], emergencyDetails: EmergencyDetailModel[]) {
    // save policy emergency coverage
    const self = this
    const emergencyCoverage: EmergencyCoverageModel[] = []
    emergencyDetails.forEach((pd: EmergencyDetailModel) => {
      if (pd.emergencyCoverage && emergencyCoverage) {
        emergencyCoverage.push(pd.emergencyCoverage)
      } else {
        pd.emergencyCoverage = new EmergencyCoverageModel()
        pd.emergencyCoverage.emergencyDetailId = pd.emergencyDetailId
        if (emergencyCoverage) {
          emergencyCoverage.push(pd.emergencyCoverage)
        }
      }
    })
    this.isLoading = true
    const savePolicyEmergencyCoverage: SavePolicyEmergencyCoverageModel = new SavePolicyEmergencyCoverageModel()
    savePolicyEmergencyCoverage.policyId = this.policyBasicDetailModel.policyId
    savePolicyEmergencyCoverage.emergencyCoverageList = emergencyCoverage
    PolicyScheduleController.SavePolicyEmergencyCoverage(savePolicyEmergencyCoverage)
      .then((res: boolean) => {
        this.showSnackbarMessage(res ? 'Emergency Detail Saved Successfully.' : 'Something went wrong.')
        if (res) {
          this.redirectToStep7()
        }
        this.tempSelectedEmergencyTypes = res ? JSON.parse(JSON.stringify(emergencies)) : []
        this.tempSelectedEmergencyDetails = res ? JSON.parse(JSON.stringify(emergencyDetails)) : []
      })
      .catch((err: any) => {
        this.showSnackbarMessage('Something went wrong.')
        this.tempSelectedEmergencyTypes = []
        this.tempSelectedEmergencyDetails = []
        eventBus.$emit('errorHandler', 'Error saving emergency coverage, please try again', false)
      })
  }

  private savePolicyQuestionList(policyQuestionList: PolicyQuestionModel[]) {
    this.isLoading = true
    const savePolicyEmergencyQuestionsModel: SavePolicyEmergencyQuestionsModel = new SavePolicyEmergencyQuestionsModel()
    savePolicyEmergencyQuestionsModel.policyId = this.policyBasicDetailModel.policyId
    savePolicyEmergencyQuestionsModel.policyQuestions = policyQuestionList
    PolicyScheduleController.SavePolicyQuestion(savePolicyEmergencyQuestionsModel)
      .then((res: boolean) => {
        this.showSnackbarMessage(res ? 'Policy Questions Saved Successfully.' : 'Something went wrong.')
        if (res) {
          this.redirectToStep8()
        }
        this.tempPolicyQuestionList = res ? JSON.parse(JSON.stringify(policyQuestionList)) : []
      })
      .catch((err: any) => {
        this.showSnackbarMessage('Something went wrong.')
        this.tempPolicyQuestionList = []
        eventBus.$emit('errorHandler', 'Error saving policy question list, please try again', false)
      })
  }

  private savePolicyDocumentTemplate(documentTemplateList: PolicyDocumentTemplateModel[]) {
    this.isLoading = true
    const policyDocumentTemplateModel: SavePolicyDocumentTemplatesModel = new SavePolicyDocumentTemplatesModel()
    policyDocumentTemplateModel.policyId = this.policyBasicDetailModel.policyId
    policyDocumentTemplateModel.policyDocumentTemplates = documentTemplateList
    PolicyScheduleController.SavePolicyDocumentTemplate(policyDocumentTemplateModel)
      .then((res: boolean) => {
        this.showSnackbarMessage(res ? 'Policy Document Template Detail Saved Successfully.' : 'Something went wrong.')
        if (res) {
          this.redirectToStep1()
          this.policyModel.policyDocumentTemplates = documentTemplateList
        }
      })
      .catch((err: any) => {
        this.showSnackbarMessage('Something went wrong.')
        eventBus.$emit('errorHandler', 'Error saving document template detail, please try again', false)
      })
  }

  private showSnackbarMessage(message: string) {
    this.isLoading = false
    this.saveSnackbarText = message
    this.saveSnackbar = true
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumeric(event)
  }
}
</script>

<style scoped>
.v-stepper--vertical .v-stepper__content {
  margin-left: 40px;
}
/*----Step 4----*/
.expansion-panel {
  box-shadow: none;
  padding: 1px;
}
.policyQuestionList {
  padding: 0px;
  padding-top: 0px;
}
.policyQuestionList .sub-title {
  font-weight: 400;
  padding: 5px 0px;
}
/*----Step 4----*/
/*----Step 5----*/
.select-que-control {
  max-width: 300px;
  display: inline-block;
  vertical-align: middle;
}
.select-que-caption {
  margin-right: 10px;
  font-size: 16px;
}
.select-que-check {
  display: inline-block;
  max-width: 400px;
  vertical-align: middle;
}
.select-que-check >>> .v-list__tile__action {
  min-width: 36px;
}
/*----Step 5----*/
.link-portal-text {
  position: relative;
}
.link-portal-text .link-switch {
  position: absolute;
  right: 0px;
  top: -25px;
  bottom: 0px;
}
.link-portal-text .v-select {
  z-index: 2;
}
.average-hold-text {
  display: flex;
  flex-direction: row;
}
.average-hold-switch {
  justify-content: flex-end;
  margin-top: 0px;
  padding-top: 0px;
}
.link-portal-text .link-switch >>> .v-input--selection-controls {
  margin-top: 2px;
}
.link-portal-text span {
  display: inline-block;
  vertical-align: top;
  margin: 0px 5px;
  line-height: 1.8;
}
.link-portal-text span:last-child {
  line-height: 0;
  position: relative;
  top: -4px;
}
.bold-select {
  font-weight: 600;
}
.col-center-checkbox >>> .v-input__control {
  margin: 0 auto !important;
}
.communication-content > .flex {
  align-self: flex-end;
}
.effort-score-switch {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
