<template>
  <v-layout v-if="getAdditionalRequestList.length > 0" wrap class="step-colum mt-3">
    <v-layout class="mb-3">
      <v-flex><h3>Additional Request</h3></v-flex>
      <v-spacer></v-spacer>
      <v-flex text-xs-right class="total-request-cost">
        <b>
          <span>
            Total:
            <span class="secondary--text">{{ totalRequestCost }}</span>
          </span>
        </b>
      </v-flex>
    </v-layout>
    <v-flex xs12>
      <v-layout>
        <v-data-table
          :headers="additionalRequestHeader"
          :items="getAdditionalRequestList"
          hide-actions
          item-key="id"
          class="gridView gridView-full-width"
        >
          <template slot="items" slot-scope="props">
            <tr class="expand-row" @click="props.expanded = !props.expanded">
              <td>{{ getFormattedDate(props.item.createdAt) }}</td>
              <td>
                <span v-for="(material, index) in props.item.engineerRequests" :key="index">
                  <span v-if="material.requestType === engineerRequestType[engineerRequestType.Material]">
                    {{ material.materialName.split(',').length }} x parts
                  </span>
                  <span v-if="material.requestType === engineerRequestType[engineerRequestType.Time]">
                    <span v-if="checkIfMaterialTypeExist(props.item.engineerRequests)">,</span>
                    {{ calculateMinutes(material.extraMinutes) }} hours extra time
                  </span>
                  <span v-if="material.requestType === engineerRequestType[engineerRequestType.ReturnVisit]">
                    <span v-if="checkIfMaterialOrTimeTypeExist(props.item.engineerRequests)">,</span>
                    additional visit
                  </span>
                </span>
              </td>
              <td class="text-xs-right">
                <div
                  v-if="
                    parentComponent === 'EngineerVisitPreview' &&
                    props.item.requestStatus === 'Pending' &&
                    engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.Cancelled] &&
                    engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.Rejected] &&
                    engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.Abandoned] &&
                    job &&
                    job.status !== completedJobStatus
                  "
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        color="error"
                        icon
                        small
                        class="mr-2 ma-0 request-action-btn"
                        :class="abilityToEditMaterialRequest(props.item.engineerRequests) ? 'disabled-btn' : ''"
                        v-on="on"
                        @click.native.stop="
                          openAdditionalRequestActionDialog(
                            props.item.id,
                            props.item.jobId,
                            false,
                            abilityToEditMaterialRequest(props.item.engineerRequests)
                          )
                        "
                      >
                        <v-icon small>close</v-icon>
                      </v-btn>
                    </template>
                    <span>Reject</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        color="success"
                        icon
                        small
                        class="ma-0 request-action-btn"
                        :class="abilityToEditMaterialRequest(props.item.engineerRequests) ? 'disabled-btn' : ''"
                        v-on="on"
                        @click.native.stop="
                          openAdditionalRequestActionDialog(
                            props.item.id,
                            props.item.jobId,
                            true,
                            abilityToEditMaterialRequest(props.item.engineerRequests)
                          )
                        "
                      >
                        <v-icon small>check</v-icon>
                      </v-btn>
                    </template>
                    <span>Accept</span>
                  </v-tooltip>
                  <v-tooltip v-if="abilityToEditMaterialRequest(props.item.engineerRequests)" bottom>
                    <template #activator="{ on }">
                      <v-btn
                        color="primary"
                        icon
                        small
                        class="ml-2 ma-0 request-action-btn edit-material-cost"
                        v-on="on"
                        @click.native.stop="openEditMaterialRequestDialog(props.item.id)"
                      >
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Material Cost</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip
                    v-if="
                      isUserRoleContractor &&
                      props.item.requestStatus === 'Pending' &&
                      abilityToEditMaterialRequest(props.item.engineerRequests)
                    "
                    bottom
                  >
                    <template #activator="{ on }">
                      <v-btn
                        color="primary"
                        icon
                        small
                        class="ml-2 ma-0 request-action-btn edit-material-cost"
                        v-on="on"
                        @click.native.stop="openEditMaterialRequestDialog(props.item.id)"
                      >
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Material Cost</span>
                  </v-tooltip>
                  <span
                    v-else
                    :class="
                      props.item.requestStatus === 'Pending'
                        ? 'orange--text'
                        : props.item.requestStatus === 'Approved'
                        ? 'green--text'
                        : props.item.requestStatus === 'Rejected'
                        ? 'red--text'
                        : ''
                    "
                    class="engineer-requests-statu"
                  >
                    <b>{{ props.item.requestStatus }}</b>
                  </span>
                </div>
              </td>
            </tr>
          </template>
          <template slot="expand" slot-scope="props">
            <v-card flat elevation-0>
              <v-card-text class="lighten-4 pa-2">
                <div v-for="(material, index) in props.item.engineerRequests" :key="index" class="mb-2">
                  <v-layout
                    v-if="material.requestType === engineerRequestType[engineerRequestType.Material]"
                    wrap
                    white
                    elevation-1
                    border-table
                  >
                    <v-flex grey lighten-3 pa-2 description class="xs3">
                      <b>Material</b>
                    </v-flex>
                    <v-flex :class="material.additionalInfo ? 'xs3' : 'xs6'" grey lighten-3 pa-2>
                      <b>Description</b>
                    </v-flex>
                    <v-flex v-if="material.additionalInfo" class="xs3" grey lighten-3 pa-2>
                      <b>Additional Description</b>
                    </v-flex>
                    <v-flex xs3 grey lighten-3 pa-2 text-xs-right>
                      <b>Price</b>
                    </v-flex>
                    <v-flex pa-2 description class="xs3">
                      {{ material.materialName }}
                    </v-flex>
                    <v-flex :class="material.additionalInfo ? 'xs3' : 'xs6'" pa-2 description>
                      {{ material.furtherInfo }}
                    </v-flex>
                    <v-flex v-if="material.additionalInfo" class="xs3" pa-2 description>
                      {{ material.additionalInfo }}
                    </v-flex>
                    <v-flex xs3 pa-2 text-xs-right class="material-price">
                      <b class="secondary--text">
                        {{ getFormatedCurrency(material.materialCost) }}
                      </b>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    v-if="material.requestType === engineerRequestType[engineerRequestType.Time]"
                    elevation-1
                    white
                    wrap
                    mb-3
                    border-table
                  >
                    <v-flex class="xs3" grey lighten-3 pa-2>
                      <b>Extra Hours</b>
                    </v-flex>
                    <v-flex xs6 grey lighten-3 pa-2>
                      <b>Request Reason</b>
                    </v-flex>
                    <v-flex xs3 grey lighten-3 pa-2 text-xs-right>
                      <b>Price</b>
                    </v-flex>
                    <v-flex pa-2 class="xs3">
                      {{ calculateMinutes(material.extraMinutes) }}
                    </v-flex>
                    <v-flex xs6 pa-2 description>
                      {{ material.timeRequestReason }}
                    </v-flex>
                    <v-flex xs3 pa-2 text-xs-right class="time-request-price">
                      <b class="secondary--text">
                        {{ getFormatedCurrency(props.item.contractorTimeValue) }}
                      </b>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    v-if="material.requestType === engineerRequestType[engineerRequestType.ReturnVisit]"
                    wrap
                    white
                    elevation-1
                    border-table
                  >
                    <v-flex xs3 grey lighten-3 pa-2>
                      <b>Visit At</b>
                    </v-flex>
                    <v-flex xs2 grey lighten-3 pa-2>
                      <b>Return Visit Type</b>
                    </v-flex>
                    <v-flex xs3 grey lighten-3 pa-2>
                      <b>Reason</b>
                    </v-flex>
                    <v-flex class="xs2" pa-0 grey signature-text lighten-3 py-2 text-xs-center>
                      <b>Signature</b>
                    </v-flex>
                    <v-flex xs2 grey lighten-3 pa-2 text-xs-right>
                      <b>Price</b>
                    </v-flex>
                    <v-flex xs3 pa-2>
                      {{ getFormattedDate(material.returnVisitAt) }}
                      <br />
                      <v-tooltip v-if="material.returnVisitDuration" right class="returnVisitDuration">
                        <template #activator="{ on }">
                          <b v-on="on">({{ getFormattedVisitDuration(material.returnVisitDuration) }})</b>
                        </template>
                        <span>Estimated visit duration time</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex xs2 pa-2 description>
                      {{ material.returnVisitType }}
                    </v-flex>
                    <v-flex xs3 pa-2 description>
                      {{ material.reasonForReturnVisit }}
                    </v-flex>
                    <v-flex class="xs2" pa-2 description text-xs-center>
                      <img
                        v-if="material.customerSignatureUrl"
                        :src="getBlobUrl(material.customerSignatureUrl)"
                        alt="signature"
                        class="signature-return-visit"
                      />
                      <span v-else><v-icon>remove</v-icon></span>
                    </v-flex>
                    <v-flex xs2 pa-2 description text-xs-right class="return-visit-price">
                      <b class="secondary--text">
                        {{ getFormatedCurrency(props.item.contractorExtraVisitValue) }}
                      </b>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </v-layout>
    </v-flex>
    <!-- dialog - reject visit -->
    <v-dialog v-if="showRejectDialog" v-model="showRejectDialog" max-width="400">
      <v-card>
        <v-card-title class="title">Are you sure to Reject?</v-card-title>
        <v-card-text class="py-0">
          <v-text-field
            v-model="materialRejectReason"
            v-validate="'required'"
            label="Why you want to Reject?"
            required
            data-vv-scope="materialRejectReason"
            data-vv-name="Reason"
            :error-messages="errors.collect('Reason')"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="showRejectDialog = false">No</v-btn>
          <v-btn
            color="primary"
            class="white--text mr-0"
            :disabled="onSubmitLoading"
            :loading="onSubmitLoading"
            @click.native="updateEmergencyAdditionalRequest('Rejected')"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog - approve visit -->
    <v-dialog v-model="showapproveDialog" max-width="400">
      <v-card>
        <v-card-title class="title">Are you sure to Approve?</v-card-title>
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat="flat" @click.native="showapproveDialog = false">No</v-btn>
          <v-btn
            color="primary"
            class="white--text mr-0"
            :disabled="onSubmitLoading"
            :loading="onSubmitLoading"
            @click.native="updateEmergencyAdditionalRequest('Approved')"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" bottom left>
      {{ snackbarText }}
      <v-btn flat color="secondary" @click="snackbar = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <!-- confirmation dialog for customer want to use Extra Balance -->
    <v-dialog
      v-if="useExtraBalanceConfirmationDialog"
      v-model="useExtraBalanceConfirmationDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="pa-3">
          <h3>
            {{
              'You have ' +
              getFormatedCurrency(updateEngineerRequestResponseModel.extraBalanceLeft) +
              ' extra balance. Do you want to use this balance to proceed further?'
            }}
          </h3>
        </v-card-title>
        <v-card-actions class="pt-2 px-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn small flat @click="onCancelExtraBalanceConfirmationDialog">No</v-btn>
          <v-btn
            color="primary"
            small
            :disabled="useExtraBalanceLoading"
            :loading="useExtraBalanceLoading"
            @click="onConfirmUseExtraBalance"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit material request dialog -->
    <v-dialog
      v-if="editMaterialRequestDialog"
      v-model="editMaterialRequestDialog"
      max-width="600"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Update Material Cost</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="closeEditMaterialRequestDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <table width="100%">
            <thead class="text-left">
              <tr>
                <th>Material</th>
                <th class="pl-2">Price</th>
                <th style="width: 0" />
              </tr>
            </thead>
            <tbody>
              <tr v-if="loadingMaterialRequests">
                <td colspan="3" class="text-center pa-4">
                  <v-progress-circular indeterminate color="primary" size="64" />
                </td>
              </tr>

              <tr v-for="(item, i) in materialRequests" :key="i">
                <td>
                  <v-autocomplete
                    v-model="item.name"
                    :items="materialsStore.materials"
                    hide-selected
                    item-text="name"
                    hide-details
                    placeholder="Select material"
                  />
                </td>

                <td class="pl-2">
                  <v-text-field v-model="item.cost" type="number" min="0" hide-details filled name="price">
                    <template #prepend-inner>
                      <span class="pa-1">£</span>
                    </template>
                  </v-text-field>
                </td>

                <td>
                  <v-btn color="error" class="action-button" flat @click="() => onRemoveMaterial(i)">
                    <v-icon>close</v-icon>
                  </v-btn>
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td>
                  <v-btn color="primary" class="action-button" flat @click="onAddMaterial">
                    <v-icon>add</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-divider />

        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="closeEditMaterialRequestDialog">Cancel</v-btn>
          <v-btn
            color="primary submit-material-cost"
            class="mr-0"
            :disabled="
              isLoading ||
              !materialRequests.length ||
              materialRequests.some((mr) => !mr.name) ||
              materialRequests.some((mr) => !mr.cost || mr.cost <= 0)
            "
            :loading="isLoading"
            @click="updateMaterialRequest"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import EngineerRequestBase from '@/models/claim/engineerRequest/EngineerRequestBase'
import { EngineerRequestType, EngineerJobVisitStatus } from '@/common/enums'
import EngineerReturnVisitRequest from '@/models/claim/engineerRequest/EngineerReturnVisitRequest'
import JobController from '@/api/jobController'
import { RequestStatus, JobStatus } from '@/models/types'
import UpdateEngineerRequestStatus from '@/models/requests/UpdateEngineerRequestStatus'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import eventBus from '@/common/bus'
import AddRequestToEscalateCTPProcessModel from '@/models/requests/AddRequestToEscalateCTPProcessModel'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import CheckPolicyLimitResponse from '@/models/claim/CheckPolicyLimitResponse'
import EscalateCTPResponseModel from '@/models/claim/EscalateCTPResponseModel'
import Emergency from '@/models/Emergency'
import PaymentController from '@/api/paymentController'
import DocumentController from '@/api/documentController'
import UpdateMaterialCostRequestModel from '@/models/requests/UpdateMaterialCostRequestModel'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { AxiosResponse } from 'axios'
import { MaterialRequest } from '@/models/EngineerRequests'
import EngineerRequestsController from '@/api/engineerRequestsController'
import { useMaterialsStore } from '@/pinia/materials'

@Component
export default class EngineerResourceRequestComponent extends Vue {
  @Prop() private additionalRequestList: EngineerRequestModel[]
  @Prop() private jobId: string
  @Prop() private engineerVisitDetail: EngineerVisitDetail
  @Prop() private parentComponent: string
  private signatureList: any[] = []
  private additionalRequestHeader: any = [
    { text: 'Received At', value: 'createdAt', sortable: false },
    { text: 'Summary Description', value: 'description', sortable: false },
    {
      text: 'Status',
      value: 'status',
      align: 'right',
      sortable: false,
      class: 'action-btn',
    },
  ]
  private engineerRequestType = EngineerRequestType
  private engineerJobVisitStatus = EngineerJobVisitStatus
  private showRejectDialog = false
  private showapproveDialog = false
  private emergencyIdTemp = ''
  private jobIdTemp = ''
  private materialRejectReason = ''
  private onSubmitLoading = false
  private snackbar = false
  private useExtraBalanceConfirmationDialog = false
  private updateEngineerRequestResponseModel: CheckPolicyLimitResponse = new CheckPolicyLimitResponse()
  private updateEngineerRequest: UpdateEngineerRequestStatus = new UpdateEngineerRequestStatus()
  private useExtraBalanceLoading = false
  private totalRequestCost = ''
  private snackbarTimeout = 3000
  private completedJobStatus: JobStatus = 'Completed'
  private editMaterialRequestDialog = false
  private isLoading = false
  private materialCost = 0
  private materialRequestId = ''
  private snackbarText = ''
  public materialRequests: MaterialRequest[] = []
  public loadingMaterialRequests = false
  private materialsStore = useMaterialsStore()

  public onAddMaterial() {
    this.materialRequests.push({
      name: '',
      cost: 0,
    })
  }

  public onRemoveMaterial(index: number) {
    this.materialRequests.splice(index, 1)
  }

  private get getAdditionalRequestList() {
    let total = 0
    // get blob url for customer signature
    for (const request of this.additionalRequestList) {
      if (request.engineerRequests) {
        const requestBase: EngineerRequestBase | undefined = request.engineerRequests.find(
          (e) => e.requestType.toString() === EngineerRequestType[EngineerRequestType.ReturnVisit]
        )
        if (requestBase) {
          const returnVisitRequest: EngineerReturnVisitRequest | undefined = Object.assign(
            new EngineerReturnVisitRequest(),
            requestBase
          )
          if (returnVisitRequest && returnVisitRequest.customerSignatureUrl) {
            const url: string = returnVisitRequest.customerSignatureUrl.substring(
              returnVisitRequest.customerSignatureUrl.indexOf(this.jobId)
            )
            this.GetSASTokenForMaterialBlob(url).then((res) => {
              const customerSignature: any = {}
              customerSignature.customerSignatureUrl = returnVisitRequest.customerSignatureUrl
              customerSignature.customerSignatureUrlBlob = res
              this.signatureList.push(customerSignature)
            })
          }
        }

        const materialRequestBase: EngineerRequestBase | undefined = request.engineerRequests.find(
          (e) => e.requestType.toString() === EngineerRequestType[EngineerRequestType.Material]
        )
        if (materialRequestBase && request.requestStatus === 'Approved') {
          total += Number(request.contractorMaterialsValue)
        }
        const timeRequestBase: EngineerRequestBase | undefined = request.engineerRequests.find(
          (e) => e.requestType.toString() === EngineerRequestType[EngineerRequestType.Time]
        )
        if (timeRequestBase && request.requestStatus === 'Approved') {
          total += Number(request.contractorTimeValue)
        }
        const returnVisitRequestBase: EngineerRequestBase | undefined = request.engineerRequests.find(
          (e) => e.requestType.toString() === EngineerRequestType[EngineerRequestType.ReturnVisit]
        )
        if (returnVisitRequestBase && request.requestStatus === 'Approved') {
          total += Number(request.contractorExtraVisitValue)
        }
      }
    }

    this.totalRequestCost = this.getFormatedCurrency(total)
    return this.additionalRequestList
  }

  private get getAbilityToAccessCustomerToPay(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.hasCustomerToPayAccess
    )
  }

  public get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private checkIfMaterialOrTimeTypeExist(requestList: EngineerRequestBase[]): boolean {
    if (requestList) {
      const isMaterialRequestTypeExists: number = requestList.filter(
        (x) => x.requestType.toString() === EngineerRequestType[EngineerRequestType.Material]
      ).length
      const isTimeRequestTypeExists: number = requestList.filter(
        (x) => x.requestType.toString() === EngineerRequestType[EngineerRequestType.Time]
      ).length
      if (isMaterialRequestTypeExists > 0) {
        return true
      } else if (isTimeRequestTypeExists > 0) {
        return true
      }
    }
    return false
  }

  // Calculate and fomate minutes and second
  private calculateMinutes(second: number): string {
    return Shared.formatDuration(second)
  }

  private checkIfMaterialTypeExist(requestList: EngineerRequestBase[]): boolean {
    if (requestList) {
      const isRequesTypeExists: number = requestList.filter(
        (x) => x.requestType.toString() === EngineerRequestType[EngineerRequestType.Material]
      ).length
      if (isRequesTypeExists > 0) {
        return true
      }
    }
    return false
  }

  private updateEmergencyAdditionalRequest(status: RequestStatus): void {
    if (status === 'Rejected') {
      this.validate('materialRejectReason')
        .then((result: boolean) => {
          if (result) {
            this.updateEmergencyAdditionalRequestStatus(status)
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    } else {
      this.updateEmergencyAdditionalRequestStatus(status)
    }
  }

  private async validate(scope: string): Promise<boolean> {
    let result = true
    const validation: boolean = await this.$validator.validateAll(scope)
    // set focus to non validate field
    if (!validation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = validation
    }
    return result
  }

  private updateEmergencyAdditionalRequestStatus(status: RequestStatus): void {
    if (this.emergencyIdTemp && this.jobIdTemp) {
      this.updateEngineerRequest = new UpdateEngineerRequestStatus()
      this.updateEngineerRequest.id = this.emergencyIdTemp
      this.updateEngineerRequest.jobId = this.jobIdTemp
      this.updateEngineerRequest.engineerId = this.engineerVisitDetail.engineerId
      this.updateEngineerRequest.requestStatus = status
      if (status === 'Rejected') {
        this.updateEngineerRequest.rejectReason = this.materialRejectReason
      }
      this.onSubmitLoading = true
      JobController.UpdateEngineerRequest(this.updateEngineerRequest)
        .then((res: CheckPolicyLimitResponse | null) => {
          if (this.updateEngineerRequest.requestStatus !== 'Rejected') {
            if (res) {
              if (res.requiredToTriggerCTP) {
                this.onSubmitLoading = false
                this.showapproveDialog = false
                if (this.getAbilityToAccessCustomerToPay) {
                  if (res.extraBalanceLeft > 0) {
                    // open confirmation popup, whether customer want to use extra balance or not
                    this.updateEngineerRequestResponseModel = res
                    this.useExtraBalanceConfirmationDialog = true
                  } else {
                    // open confirmation popup for trigger CTP process
                    Shared.confirmationPopup.open(
                      'Policy Limit Exceeded. Do you want to pay ' +
                        this.getFormatedCurrency(res.extraAmountNeedToPay) +
                        ' to proceed further?',
                      '',
                      '',
                      'No',
                      'Yes',
                      this,
                      'makeCustomerToPay',
                      res
                    )
                    return
                  }
                } else {
                  // open confirmation popup to escalate CTP process to team leader
                  const escalateCTPProcess: AddRequestToEscalateCTPProcessModel =
                    new AddRequestToEscalateCTPProcessModel()
                  escalateCTPProcess.jobId = this.jobId
                  escalateCTPProcess.escalateBy = Store.Instance.state.SessionDetail.userName
                  escalateCTPProcess.description =
                    'Customer To Pay Process is required as a consequence of total cost of additional request goes beyond the policy limit.'
                  Shared.confirmationPopup.open(
                    'You do not have permission to perform customer to pay. Do you want to escalate this to your team leader?',
                    '',
                    '',
                    'No',
                    'Yes',
                    this,
                    'escalateCTPProcess',
                    escalateCTPProcess
                  )
                }
              }
            }
          } else {
            if (this.getAdditionalRequestList) {
              const selectedRequest = this.getAdditionalRequestList.find(
                (x) => x.id === this.emergencyIdTemp && x.jobId === this.jobIdTemp
              )
              if (selectedRequest) {
                selectedRequest.requestStatus = this.updateEngineerRequest.requestStatus
              }
              this.updateEngineerRequest = new UpdateEngineerRequestStatus()
            }
          }
          this.onSubmitLoading = false
          this.showRejectDialog = false
          this.showapproveDialog = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error updating engineer request, please try again', true)
          this.onSubmitLoading = false
          this.showRejectDialog = false
          this.showapproveDialog = false
        })
    }
  }

  private escalateCTPProcess(escalateCTPProcess: AddRequestToEscalateCTPProcessModel) {
    // user agreed to escalate CTP process, add request to add audit log
    JobController.AddRequestToEscalateCTPProcess(escalateCTPProcess)
      .then((response: boolean) => {
        if (response) {
          this.onSubmitLoading = false
          this.showapproveDialog = false
          this.updateEngineerRequest = new UpdateEngineerRequestStatus()
          this.showMessage('CTP process escalated to team leader.')
        }
        return
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting request to escalate CTP process, please try again', true)
      })
  }

  private makeCustomerToPay(updateRequestResponseModel: CheckPolicyLimitResponse) {
    // trigger CTP process
    eventBus.$emit('makeCustomerToPay')
    let amountNeedToPay = 0
    const escalateCTPResponseModel: EscalateCTPResponseModel = new EscalateCTPResponseModel()
    escalateCTPResponseModel.extraAmountNeedToPay = updateRequestResponseModel.extraAmountNeedToPay
    escalateCTPResponseModel.useExtraBalance = false
    escalateCTPResponseModel.recordType = 'EngineerRequest'
    escalateCTPResponseModel.updateEngineerRequest = this.updateEngineerRequest
    amountNeedToPay = updateRequestResponseModel.extraAmountNeedToPay
    if (this.job && this.engineerVisitDetail) {
      const emergency: Emergency | undefined = this.job.emergencies.find(
        (e) =>
          e.typeId === this.engineerVisitDetail.forEmergencyTypeId &&
          e.detailId === this.engineerVisitDetail.forEmergencyDetailId
      )
      if (emergency) {
        // wait till the customerToPay Card Added to timeline
        setTimeout(() => {
          eventBus.$emit('escalateCTPResponse', escalateCTPResponseModel, amountNeedToPay, emergency.id)
        }, 0)
      }
    }
  }

  private onConfirmUseExtraBalance() {
    let amountNeedToPay = 0
    const escalateCTPResponseModel: EscalateCTPResponseModel = new EscalateCTPResponseModel()
    escalateCTPResponseModel.extraAmountNeedToPay = this.updateEngineerRequestResponseModel.extraAmountNeedToPay
    escalateCTPResponseModel.useExtraBalance = true
    escalateCTPResponseModel.recordType = 'EngineerRequest'
    escalateCTPResponseModel.updateEngineerRequest = this.updateEngineerRequest
    // customer agree to use extra balance, check CTP process required or not
    if (
      this.updateEngineerRequestResponseModel.extraAmountNeedToPay <=
      this.updateEngineerRequestResponseModel.extraBalanceLeft
    ) {
      this.useExtraBalanceLoading = true
      PaymentController.ApproveCTPTriggerRequest(escalateCTPResponseModel, this.jobId)
        .then((res: boolean) => {
          if (res) {
            this.updateEngineerRequestResponseModel = new CheckPolicyLimitResponse()
            this.useExtraBalanceLoading = false
            this.useExtraBalanceConfirmationDialog = false
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error submitting approve CTP trigger request, please try again', true)
          this.useExtraBalanceLoading = false
        })
    } else {
      // trigger CTP process for amount (extraAmountNeedToPay - extraBalanceLeft)
      eventBus.$emit('makeCustomerToPay')
      amountNeedToPay =
        this.updateEngineerRequestResponseModel.extraAmountNeedToPay -
        this.updateEngineerRequestResponseModel.extraBalanceLeft
      if (this.job && this.engineerVisitDetail) {
        const emergency: Emergency | undefined = this.job.emergencies.find(
          (e) =>
            e.typeId === this.engineerVisitDetail.forEmergencyTypeId &&
            e.detailId === this.engineerVisitDetail.forEmergencyDetailId
        )
        if (emergency) {
          // wait till the customerToPay Card Added to timeline
          setTimeout(() => {
            eventBus.$emit('escalateCTPResponse', escalateCTPResponseModel, amountNeedToPay, emergency.id)
          }, 0)
        }
      }
    }
  }

  private onCancelExtraBalanceConfirmationDialog() {
    // trigger CTP process
    this.makeCustomerToPay(this.updateEngineerRequestResponseModel)
  }

  private openAdditionalRequestActionDialog(
    id: string,
    jobId: string,
    isToApprove: boolean,
    isEditMaterialRequest: boolean
  ) {
    if (isEditMaterialRequest) {
      return
    }
    this.emergencyIdTemp = ''
    this.jobIdTemp = ''
    this.emergencyIdTemp = id
    this.jobIdTemp = jobId
    if (isToApprove) {
      this.showapproveDialog = true
    } else {
      this.materialRejectReason = ''
      this.showRejectDialog = true
    }
  }

  private getBlobUrl(signature: string): string {
    const url: any = this.signatureList.find((x) => x.customerSignatureUrl === signature)
    if (url) {
      return url.customerSignatureUrlBlob
    }
    return ''
  }

  private async GetSASTokenForMaterialBlob(url: string) {
    let fileURL = ''
    fileURL = await DocumentController.GetSASTokenForRecordingUrl(url).then((res: string | null) => {
      if (res && typeof res === 'string') {
        return res
      } else {
        return ''
      }
    })
    return fileURL
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private abilityToEditMaterialRequest(engineerRequests: EngineerRequestBase[]) {
    const materialRequest: any =
      engineerRequests.length > 0
        ? engineerRequests.find(
            (material) => material.requestType.toString() === EngineerRequestType[EngineerRequestType.Material]
          )
        : undefined
    return materialRequest ? (materialRequest.materialCost && materialRequest.materialCost > 0 ? false : true) : false
  }

  private openEditMaterialRequestDialog(id: string) {
    this.materialRequestId = id
    this.materialCost = 0
    this.editMaterialRequestDialog = true

    this.loadMaterialRequests()
  }

  public closeEditMaterialRequestDialog() {
    this.editMaterialRequestDialog = false
    this.materialRequests = []
  }

  private async loadMaterialRequests() {
    this.loadingMaterialRequests = true
    const requests = await EngineerRequestsController.GetMaterialRequests(this.jobId, this.materialRequestId)
    this.loadingMaterialRequests = false

    if (!requests) {
      this.showMessage('Something went wrong')
      return
    }

    for (const request of requests) {
      // Handles the case where the material name is a comma separated list
      const names = request.name.split(',')

      for (const name of names) {
        this.materialRequests.push({
          name: name,
          cost: request.cost,
          furtherInfo: request.furtherInfo,
          additionalInfo: request.additionalInfo,
        })
      }
    }
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private updateMaterialCost() {
    const materialRequest: UpdateMaterialCostRequestModel = new UpdateMaterialCostRequestModel()
    materialRequest.id = this.materialRequestId
    materialRequest.jobId = this.jobId
    materialRequest.materialCost = this.materialCost
    this.isLoading = true
    JobController.UpdateMaterialCostRequest(materialRequest)
      .then((res: boolean) => {
        if (res) {
          this.editMaterialRequestDialog = false
          if (this.parentComponent === 'EngineerVisitPreview') {
            this.showMessage('Material Cost Updated Successfully.')
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error updating material cost, please try again', true)
        if (this.parentComponent === 'EngineerVisitPreview') {
          this.showMessage('Something went wrong')
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public async updateMaterialRequest() {
    const res: AxiosResponse = await CallCentreApi.put(
      `engineer-requests/${this.jobId}/${this.materialRequestId}/materials`,
      {
        materials: this.materialRequests,
      }
    )

    switch (res.status) {
      case 202:
        this.showMessage('Material request(s) updated')
        this.closeEditMaterialRequestDialog()
        break
      case 400:
        this.showMessage('Invalid request')
        break
      case 401:
        this.showMessage('Unauthorised')
        break
      case 403:
        this.showMessage('Forbidden')
        break
      case 404:
        this.showMessage('Job or request not found')
        break
      default:
        this.showMessage('Something went wrong')
        break
    }
  }

  private showMessage(message: string) {
    this.snackbarText = message
    this.snackbar = true
  }

  private get isUserRoleContractor(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  private getFormattedVisitDuration(travelTime: number) {
    return Shared.formatDuration(travelTime)
  }
}
</script>

<style scoped>
.border-table {
  border: 1px solid #eae6e6;
}
.description {
  word-break: break-word;
}
.bold-select {
  font-weight: 600;
}
.signature-return-visit {
  max-width: 100%;
  width: 120px;
  max-height: 60px;
}
.step-colum {
  position: relative;
}
.gridView >>> .action-btn {
  min-width: 80px;
}
.gridView-full-width {
  width: 100%;
}
.signature-text {
  word-break: break-all;
}
.disabled-btn {
  cursor: default;
  opacity: 0.5;
}

.action-button {
  min-width: 0;
  width: 3.5rem;
  font-size: 1.5em;
}

@media (max-width: 1320px) {
  .request-action-btn {
    margin-top: 5px !important;
  }
}
</style>
