<template>
  <div>
    <div class="emergencies-coverd mb-3">
      <v-card class="bg-gray">
        <v-layout>
          <v-flex md5 lg3 class="emergencies-type-que pr-0 pt-2">
            <div class="text-xs-right adp-type-text">
              <v-tooltip v-if="!isUserRoleClientManager" top>
                <template #activator="{ on }">
                  <v-btn
                    flat
                    icon
                    class="md-icon ma-0 btn-addemergency"
                    color="secondary"
                    v-on="on"
                    @click.stop="
                      showEmergencyDialog = true
                      currentIndex = -1
                    "
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Add {{ jobTypeLabel('emergency') }}</span>
              </v-tooltip>
              <v-dialog
                v-if="showEmergencyDialog"
                v-model="showEmergencyDialog"
                content-class="v-dialog--scrollable"
                max-width="400"
                persistent
              >
                <EmergencyComponent
                  ref="refEmergency"
                  :emergency="emergency"
                  :job-type="jobType"
                  @closeEmergency="onCloseEmergencyDialog"
                  @saveEmergency="onSaveEmergency"
                ></EmergencyComponent>
              </v-dialog>
            </div>
            <div v-bar="{ useScrollbarPseudo: true }">
              <div class="scroll-content pr-3">
                <div v-for="emergency in getEmergencyList" :key="emergency.emergencyId" class="emergecny-list-sla">
                  <v-checkbox
                    v-model="emergencyList"
                    :hide-details="true"
                    :label="emergency.description"
                    :value="emergency"
                    color="primary"
                    class="que-checkbox"
                    name="emergency"
                    :readonly="isUserRoleClientManager"
                    :class="emergency.isDeleted ? 'deleted-item' : ''"
                    @change="emergencyListChange(emergencyList)"
                  ></v-checkbox>
                  <span
                    class="primary--text sla-time"
                    :class="currentIndex === emergency.emergencyId ? 'sla-time-edit' : ''"
                  >
                    <span v-if="currentIndex !== emergency.emergencyId" class="sla-value">
                      {{ emergency.engineerSiteSLA }}
                    </span>
                    <v-combobox
                      v-else
                      id="engineerOnSiteSLA"
                      v-model="engineerSiteSLA"
                      :items="engineerSiteSLAItems"
                      append-icon="save"
                      single-line
                      :disabled="currentIndex !== emergency.emergencyId"
                      hide-details
                      name="engineerOnSiteSLA"
                      class="mt-0 pt-0 engineerOnSiteSLA"
                      @click:append="saveEngineerSiteSLA(emergency)"
                      @keypress="numberKeyValidation($event)"
                    ></v-combobox>
                    <span v-if="currentIndex === emergency.emergencyId" class="sla-close">
                      <v-btn
                        icon
                        class="ma-0 btn-closeEngineerOnSiteSLA"
                        small
                        @click="cancelEditEngineerSiteSLA(emergency)"
                      >
                        <v-icon small>close</v-icon>
                      </v-btn>
                    </span>
                    <span v-if="emergency.engineerSiteSLA && currentIndex !== emergency.emergencyId" class="sla-text">
                      hrs
                    </span>
                    <span
                      v-if="!isUserRoleClientManager && emergency.engineerSiteSLA"
                      class="sla-action"
                      :class="currentIndex === emergency.emergencyId ? 'hide' : ''"
                    >
                      <v-btn
                        icon
                        color="white"
                        class="btn-editEngineerSiteSLA"
                        small
                        @click="editEngineerSiteSLA(emergency)"
                      >
                        <v-icon small color="secondary">edit</v-icon>
                      </v-btn>
                    </span>
                    <span
                      v-else-if="!isUserRoleClientManager && emergencyList.indexOf(emergency) !== -1"
                      class="primary--text sla-time"
                      :class="currentIndex !== emergency.emergencyId ? 'sla-time-edit' : 'hide'"
                    >
                      <v-btn icon color="white" small @click="editEngineerSiteSLA(emergency)">
                        <v-icon small color="secondary">edit</v-icon>
                      </v-btn>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </v-flex>
          <v-flex xs9>
            <div class="emergencies-sel-que pa-4">
              <div v-if="!isUserRoleClientManager" class="control-right-side">
                <span>
                  <v-checkbox
                    v-model="selectAll"
                    label="Select All"
                    color="primary"
                    class="mb-3 que-checkbox"
                    :hide-details="true"
                    name="selectAllEmergencyDetail"
                    @change="selectAllEmergencyDetail"
                  ></v-checkbox>
                </span>
              </div>
              <v-dialog
                v-if="showEmergencyDetailDialog"
                v-model="showEmergencyDetailDialog"
                content-class="v-dialog--scrollable"
                max-width="400"
                persistent
              >
                <EmergencyDetail
                  ref="refEmergencyDetail"
                  :job-type="jobType"
                  :emergencies="emergencies"
                  :trades="trades"
                  :emergency-id="emergencyId"
                  :emergency-detail="emergencyDetail"
                  @closeEmergencyDetail="showEmergencyDetailDialog = !showEmergencyDetailDialog"
                  @saveEmergencyDetail="onSaveEmergencyDetail"
                ></EmergencyDetail>
              </v-dialog>
              <v-dialog
                v-if="showThirdPartyDetailDialog"
                v-model="showThirdPartyDetailDialog"
                content-class="v-dialog--scrollable"
                max-width="650"
                persistent
              >
                <ThirdPartyDetail
                  ref="refThirdPartyDetail"
                  :contractors="contractors"
                  :emergency-id="emergencyId"
                  :third-party-contractor-id="thirdPartyContractorId"
                  @saveThirdPartyDetail="onSaveThirdPartyDetail"
                  @closeThirdPartyDetail="showThirdPartyDetailDialog = !showThirdPartyDetailDialog"
                ></ThirdPartyDetail>
              </v-dialog>
              <div v-bar="{ useScrollbarPseudo: true }" class="policy-scroll-content">
                <div class="scroll-content">
                  <v-layout wrap>
                    <v-flex v-for="emergency in selectedEmergencies" :key="emergency.emergencyId" xs12 mb-3>
                      <v-card>
                        <v-card-title class="pb-0 selectedEmergencies">
                          <h3 class="mb-0">
                            {{ emergency.description }}
                            <v-tooltip v-if="!isUserRoleClientManager" top>
                              <template #activator="{ on }">
                                <v-btn
                                  flat
                                  color="primary"
                                  icon
                                  :style="{ cursor: 'pointer' }"
                                  class="btn-addEmergencyDetail"
                                  v-on="on"
                                  @click.stop="addEmergencyDetail(emergency.emergencyId)"
                                >
                                  <v-icon>add</v-icon>
                                </v-btn>
                              </template>
                              <span>Add {{ jobTypeLabel('emergency detail') }}</span>
                            </v-tooltip>
                          </h3>
                          <v-spacer></v-spacer>
                          <v-tooltip
                            v-if="
                              showAddThirdPartyDetail(emergency.emergencyId) &&
                              policyId !== 0 &&
                              !isUserRoleClientManager
                            "
                            top
                          >
                            <template #activator="{ on }">
                              <v-btn
                                icon
                                class="ma-0 mr-1 btn-addThirdPartyDetail"
                                v-on="on"
                                @click="addEditThirdPartyDetail(emergency.emergencyId)"
                              >
                                <v-icon color="primary" dark>group_add</v-icon>
                              </v-btn>
                            </template>
                            <span>Add Third Party Detail</span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="
                              !showAddThirdPartyDetail(emergency.emergencyId) &&
                              policyId !== 0 &&
                              !isUserRoleClientManager
                            "
                            top
                          >
                            <template #activator="{ on }">
                              <v-btn
                                icon
                                class="secondary--text ma-0 btn-updateThirdPartyDetail"
                                v-on="on"
                                @click.native="addEditThirdPartyDetail(emergency.emergencyId)"
                              >
                                <v-icon dark>group</v-icon>
                              </v-btn>
                            </template>
                            <span>Update Third Party Detail</span>
                          </v-tooltip>
                          <v-tooltip v-if="!isUserRoleClientManager" top>
                            <template #activator="{ on }">
                              <v-btn
                                icon
                                class="secondary--text ma-0 btn-editEmergency"
                                v-on="on"
                                @click.native="editEmergency(emergency.emergencyId)"
                              >
                                <v-icon dark class="md-icon emergency-edit">edit</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit {{ jobTypeLabel('emergency') }}</span>
                          </v-tooltip>
                          <v-tooltip v-if="!isUserRoleClientManager" top>
                            <template #activator="{ on }">
                              <v-btn
                                icon
                                class="secondary--text ma-0 btn-deleteEmergency"
                                v-on="on"
                                @click.native="onDeleteEmergency(emergency.emergencyId)"
                              >
                                <v-icon dark class="md-icon">delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete {{ jobTypeLabel('emergency') }}</span>
                          </v-tooltip>
                        </v-card-title>
                        <v-container fluid grid-list-md pa-2>
                          <v-layout class="pt-0 policy-cov-list" wrap pa-3>
                            <v-flex
                              v-for="emergencyDetail in filteredEmergencyDetails(emergency.emergencyId)"
                              :key="emergencyDetail.emergencyDetailId"
                              xs12
                              md6
                              lg4
                            >
                              <v-card class="pa-3 grey lighten-4 fill-height emergencyDetail-list">
                                <v-layout wrap>
                                  <v-flex xs12 class="policy-addemergency">
                                    <v-checkbox
                                      v-model="emergencyDetailList"
                                      :hide-details="true"
                                      :label="emergencyDetail.description"
                                      :value="emergencyDetail"
                                      class="que-checkbox mt-1 mb-1"
                                      color="primary"
                                      name="emergencyDetail"
                                      :readonly="isUserRoleClientManager"
                                      :class="emergencyDetail.isDeleted ? 'deleted-item' : ''"
                                      @change="
                                        onEmergencyDetailCheckChange(emergencyDetail, $event)
                                        $emit('update:selectedEmergencyDetails', emergencyDetailList)
                                      "
                                    ></v-checkbox>
                                    <v-icon
                                      v-show="
                                        !isUserRoleClientManager &&
                                        emergencyDetail.isEmergencyCoverageExist &&
                                        (!emergencyDetail.emergencyCoverage ||
                                          (!emergencyDetail.emergencyCoverage.startMonth &&
                                            !emergencyDetail.emergencyCoverage.endMonth &&
                                            !emergencyDetail.emergencyCoverage.coverageDetails &&
                                            !emergencyDetail.emergencyCoverage.exclusions &&
                                            !emergencyDetail.emergencyCoverage.isMarkedAsCTP))
                                      "
                                      :disabled="showEmergencyCoverageMenu"
                                      color="primary"
                                      dark
                                      class="menu-activator btn-addEmergencyCoverage"
                                      @click.stop="addEmergencyCoverage(emergencyDetail, $event)"
                                    >
                                      add
                                    </v-icon>
                                    <v-icon
                                      v-show="
                                        !isUserRoleClientManager &&
                                        emergencyDetail.isEmergencyCoverageExist &&
                                        emergencyDetail.emergencyCoverage &&
                                        (emergencyDetail.emergencyCoverage.startMonth ||
                                          emergencyDetail.emergencyCoverage.endMonth ||
                                          emergencyDetail.emergencyCoverage.coverageDetails ||
                                          emergencyDetail.emergencyCoverage.exclusions ||
                                          emergencyDetail.emergencyCoverage.isMarkedAsCTP)
                                      "
                                      :disabled="showEmergencyCoverageMenu"
                                      color="secondary"
                                      dark
                                      class="icon-edit menu-activator btn-editEmergencyCoverage"
                                      @click.stop="editEmergencyCoverage(emergencyDetail, $event)"
                                    >
                                      edit
                                    </v-icon>
                                    <v-icon
                                      v-show="!isUserRoleClientManager && !emergencyDetail.isEmergencyCoverageExist"
                                      color="primary"
                                      dark
                                      class="menu-activator md-icon emergency-edit btn-editEmergencyDetail"
                                      @click.stop="editEmergencyDetail(emergency.emergencyId, emergencyDetail)"
                                    >
                                      edit
                                    </v-icon>
                                    <v-icon
                                      v-show="!isUserRoleClientManager && !emergencyDetail.isEmergencyCoverageExist"
                                      color="primary"
                                      dark
                                      class="menu-activator md-icon btn-deleteEmergencyDetail"
                                      @click.stop="onDeleteEmergencyDetail(emergencyDetail.emergencyDetailId)"
                                    >
                                      delete
                                    </v-icon>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </div>
    <v-menu
      v-model="showEmergencyCoverageMenu"
      offset-x
      absolute
      left
      :position-x="xPosition"
      :position-y="yPosition"
      :close-on-content-click="false"
      :nudge-width="200"
      :max-width="300"
    >
      <v-card>
        <v-layout wrap pa-3>
          <EditEmergencyCoverage
            v-if="showEmergencyCoverageMenu"
            :item.sync="currentEmergencyCoverage"
          ></EditEmergencyCoverage>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="
              currentEmergencyCoverage.startMonth ||
              currentEmergencyCoverage.endMonth ||
              currentEmergencyCoverage.coverageDetails ||
              currentEmergencyCoverage.exclusions ||
              currentEmergencyCoverage.isMarkedAsCTP
            "
            color="primary"
            class="btn-deleteEmergencyCoverage"
            flat
            dark
            @click="deleteEmergencyCoverage"
          >
            Delete
          </v-btn>
          <v-btn color="primary" class="btn-saveEmergencyCoverage" dark @click="saveEmergencyCoverage">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
      {{ saveSnackbarText }}
      <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PolicyHolderController from '@/api/policyHolderController'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import EmergencyCoverageModel from '@/models/policyHolder/EmergencyCoverageModel'
import EmergencyQuestionModel from '@/models/policy/EmergencyQuestionModel'
import eventBus from '@/common/bus'
import { QuestionEntityType, ModelOperation } from '@/common/enums'
import EditEmergencyCoverage from '@/components/EditEmergencyCoverage.vue'
import TradeModel from '@/models/policyHolder/TradeModel'
import EmergencyComponent from '@/components/EmergencyComponent.vue'
import EmergencyDetail from '@/components/EmergencyDetail.vue'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import Shared from '@/common/shared'
import Store from '@/store'
import ContractorController from '@/api/contractorController'
import ContractorModel from '@/models/contractor/ContractorModel'
import ThirdPartyDetail from '@/components/ThirdPartyDetail.vue'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'
import { JobType } from '@/models/types'
import EmergencyController from '@/api/emergencyController'

@Component({
  components: {
    EditEmergencyCoverage,
    EmergencyComponent,
    EmergencyDetail,
    ThirdPartyDetail,
  },
})
export default class PolicyQuestionSelection extends Vue {
  public emergencyDetails: EmergencyDetailModel[] = []

  @Prop() private selectedEmergencies: EmergencyModel[]
  @Prop() private selectedEmergencyDetails: EmergencyDetailModel[]
  @Prop() private policyId: number
  @Prop() private insurerId: number
  @Prop() private thirdPartyDetail: EmergencyThirdPartyTrade[]
  @Prop() private isResetCurrentIndex: boolean
  @Prop() private jobType: JobType

  private emergencyList: EmergencyModel[] = []
  private emergencyDetailList: EmergencyDetailModel[] = []
  private trades: TradeModel[] = []
  private currentEmergencyCoverage: EmergencyCoverageModel | null = new EmergencyCoverageModel()
  private currentEmergencyDetail: EmergencyDetailModel = new EmergencyDetailModel()
  private showEmergencyCoverageMenu = false
  private xPosition = 0
  private yPosition = 0
  private selectAll = false
  private showEmergencyDialog = false
  private showEmergencyDetailDialog = false
  private emergencyId = 0
  private emergency: EmergencyModel | undefined | any = null
  private emergencyDetail: EmergencyModel | undefined | any = null
  private showThirdPartyDetailDialog = false
  private contractors: ContractorModel[] = []
  private thirdPartyContractorId = ''
  private currentIndex = -1
  private engineerSiteSLAItems: string[] = ['4', '8', '16', '24']
  private localEngineerSiteSLA = 0
  private engineerSiteSLA = 0
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = ''
  private emergencies: EmergencyModel[] = []
  private emergencyListHE: EmergencyModel[] = [] // get emergencies for HE jobType
  private emergencyDetailListHE: EmergencyDetailModel[] = [] // get emergency details for HE jobType
  private emergencyListUS: EmergencyModel[] = [] // get emergencies for US jobType
  private emergencyDetailListUS: EmergencyDetailModel[] = [] // get emergency details for US jobType
  private emergencyListSI: EmergencyModel[] = [] // get emergencies for SI jobType
  private emergencyDetailListSI: EmergencyDetailModel[] = [] // get emergency details for SI jobType
  private tempSelectedEmergencies: EmergencyModel[] = []
  private tempSelectedEmergencyDetailList: EmergencyDetailModel[] = []

  public setList(emergencies: EmergencyModel[], emergencyDetails: EmergencyDetailModel[]): void {
    this.emergencyList = emergencies
    this.emergencyDetailList = emergencyDetails
    this.tempSelectedEmergencies = JSON.parse(JSON.stringify(this.emergencyList))
    this.tempSelectedEmergencyDetailList = JSON.parse(JSON.stringify(this.emergencyDetailList))
  }

  public getEmergency(id: number): EmergencyModel | undefined {
    return this.emergencies.find((e: EmergencyModel) => e.emergencyId === id)
  }

  public getEmergencyDetail(id: number): EmergencyDetailModel | undefined {
    return this.emergencyDetails.find((e: EmergencyDetailModel) => e.emergencyDetailId === id)
  }

  private created(): void {
    this.emergencyList = this.selectedEmergencies
    this.emergencyDetailList = this.selectedEmergencyDetails
    if (this.emergencies.length === 0) {
      this.setEmergencies()
    }
    PolicyHolderController.GetEmergencyDetails().then((res: EmergencyDetailModel[]) => {
      this.emergencyDetails = res
      this.emergencyDetailListHE = res
    })
    ContractorController.GetAllContractors().then((res: ContractorModel[]) => {
      this.contractors = res
    })
  }

  private mounted() {
    this.onJobTypeChange()
  }

  @Watch('isResetCurrentIndex')
  private isResetCurrentIndexChange() {
    this.currentIndex = -1
  }

  private get getTrades() {
    return storeGetters.getTrades()
  }

  @Watch('getTrades')
  private getFilteredTradeList() {
    this.trades = this.getTrades.filter((e) => e.jobType === this.jobType)
  }

  @Watch('jobType')
  private onJobTypeChange() {
    this.getFilteredTradeList()
    switch (this.jobType) {
      case 'HE':
        this.emergencyDetails = this.emergencyDetailListHE
        this.emergencies = this.emergencyListHE
        break
      case 'US':
        if (this.emergencyListUS.length === 0) {
          PolicyHolderController.GetEmergencies(this.jobType).then((res: EmergencyModel[]) => {
            this.emergencyListUS = res
            this.emergencies = res
          })
        } else {
          this.emergencies = this.emergencyListUS
        }
        if (this.emergencyDetailListUS.length === 0) {
          PolicyHolderController.GetEmergencyDetails(this.jobType).then((res: EmergencyDetailModel[]) => {
            this.emergencyDetailListUS = res
            this.emergencyDetails = res
          })
        } else {
          this.emergencyDetails = this.emergencyDetailListUS
        }
        break
      case 'SI':
        if (this.emergencyListSI.length === 0) {
          PolicyHolderController.GetEmergencies(this.jobType).then((res: EmergencyModel[]) => {
            this.emergencyListSI = res
            this.emergencies = res
          })
        } else {
          this.emergencies = this.emergencyListSI
        }
        if (this.emergencyDetailListSI.length === 0) {
          PolicyHolderController.GetEmergencyDetails(this.jobType).then((res: EmergencyDetailModel[]) => {
            this.emergencyDetailListSI = res
            this.emergencyDetails = res
          })
        } else {
          this.emergencyDetails = this.emergencyDetailListSI
        }
        break
      default:
        break
    }
  }

  private setEmergencies() {
    PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        this.emergencies = res
        this.emergencyListHE = res
        storeMutations.setEmergencies(res)
      }
    })
  }

  private isEmergencyDetailsExist(emergencyId: number): boolean {
    const emergencyDetail: EmergencyDetailModel | undefined = this.emergencyDetails.find(
      (e: EmergencyDetailModel) => e.typeId === emergencyId
    )
    return emergencyDetail ? true : false
  }

  private filteredEmergencyDetails(emergencyId: number): EmergencyDetailModel[] {
    const emergencyDetailList: EmergencyDetailModel[] = this.emergencyDetails.filter(
      (e: EmergencyDetailModel) =>
        (!e.isDeleted ||
          (e.isDeleted &&
            this.tempSelectedEmergencyDetailList &&
            this.tempSelectedEmergencyDetailList.findIndex((x) => x.emergencyDetailId === e.emergencyDetailId) !==
              -1)) &&
        e.typeId === emergencyId
    )
    return emergencyDetailList
  }

  // select all the emergency details when emergency is checked
  // unselect the emergency if all the emergency details are unchecked
  private emergencyListChange(emergencyList: EmergencyModel[]) {
    let difference: EmergencyModel[] = []
    if (emergencyList.length > this.selectedEmergencies.length) {
      // for selected emergencies
      difference = emergencyList.filter((x) => !(this.selectedEmergencies as any).includes(x))

      // add emergencyDetail of selected emergencies in emergencyDetailList
      difference.forEach((emergency: EmergencyModel) => {
        const emergencyDetailItems: EmergencyDetailModel[] = this.filteredEmergencyDetails(emergency.emergencyId)
        if (emergencyDetailItems) {
          emergencyDetailItems.forEach((emergencyDetail: EmergencyDetailModel) => {
            emergencyDetail.isEmergencyCoverageExist = true
            if (!emergencyDetail.emergencyCoverage) {
              emergencyDetail.emergencyCoverage = new EmergencyCoverageModel()
              emergencyDetail.emergencyCoverage.emergencyDetailId = emergencyDetail.emergencyDetailId
            }
            this.emergencyDetailList.push(emergencyDetail)
          })
        }
      })
    } else if (this.selectedEmergencies.length > emergencyList.length) {
      // for unselected emergencies
      difference = this.selectedEmergencies.filter((x) => !(emergencyList as any).includes(x))

      // remove emergencyDetail of unselected emergencies from emergencyDetailList
      difference.forEach((emergency: EmergencyModel) => {
        const emergencyDetailItems: EmergencyDetailModel[] = this.filteredEmergencyDetails(emergency.emergencyId)
        if (emergencyDetailItems) {
          emergencyDetailItems.forEach((emergencyDetail: EmergencyDetailModel) => {
            const index: number = this.emergencyDetailList.indexOf(emergencyDetail)
            this.emergencyDetailList.splice(index, 1)
          })
        }
      })
    }
    this.$emit('update:selectedEmergencyDetails', this.emergencyDetailList)
    this.$emit('update:selectedEmergencies', emergencyList)
  }

  private addEmergencyCoverage(emergencyDetail: EmergencyDetailModel, event: any): void {
    this.openEmergencyCoverage(ModelOperation[ModelOperation.Add], emergencyDetail, event)
  }

  private editEmergencyCoverage(emergencyDetail: EmergencyDetailModel, event: any): void {
    this.openEmergencyCoverage(ModelOperation[ModelOperation.Edit], emergencyDetail, event)
  }

  private selectAllEmergencyDetail(value: boolean): void {
    const self = this
    this.emergencyDetailList = []

    self.emergencyList.forEach((emergency: EmergencyModel) => {
      const emergencyDetailItems: EmergencyDetailModel[] = self.filteredEmergencyDetails(emergency.emergencyId)
      if (emergencyDetailItems) {
        emergencyDetailItems.forEach((emergencyDetail: EmergencyDetailModel) => {
          if (value) {
            emergencyDetail.isEmergencyCoverageExist = true
            if (!emergencyDetail.emergencyCoverage) {
              emergencyDetail.emergencyCoverage = new EmergencyCoverageModel()
              emergencyDetail.emergencyCoverage.emergencyDetailId = emergencyDetail.emergencyDetailId
            }
            self.emergencyDetailList.push(emergencyDetail)
          } else {
            emergencyDetail.isEmergencyCoverageExist = false
            emergencyDetail.emergencyCoverage = null
          }
        })
      }
    })

    this.$emit('update:selectedEmergencyDetails', this.emergencyDetailList)
  }

  private openEmergencyCoverage(mode: string, emergencyDetail: EmergencyDetailModel, event: any): void {
    this.currentEmergencyDetail = emergencyDetail
    if (mode === ModelOperation[ModelOperation.Add]) {
      this.currentEmergencyCoverage = new EmergencyCoverageModel()
      this.currentEmergencyCoverage.id = 0
      this.currentEmergencyCoverage.policyId = this.policyId
      this.currentEmergencyCoverage.emergencyDetailId = emergencyDetail.emergencyDetailId
      this.currentEmergencyCoverage.isMarkedAsCTP = false
    } else {
      this.currentEmergencyCoverage = emergencyDetail.emergencyCoverage
    }

    this.xPosition = event.clientX
    this.yPosition = event.clientY
    this.showEmergencyCoverageMenu = true
    this.currentIndex = -1
  }

  private saveEmergencyCoverage(): void {
    this.currentEmergencyDetail.emergencyCoverage = this.currentEmergencyCoverage
    this.showEmergencyCoverageMenu = false
  }

  private deleteEmergencyCoverage(): void {
    this.currentEmergencyDetail.emergencyCoverage = new EmergencyCoverageModel()
    this.currentEmergencyDetail.emergencyCoverage.emergencyDetailId = this.currentEmergencyDetail.emergencyDetailId
    this.showEmergencyCoverageMenu = false
  }

  private addEmergencyDetail(emergencyId: number): void {
    this.emergencyId = emergencyId
    this.emergencyDetail = new EmergencyDetailModel()
    this.showEmergencyDetailDialog = true
    this.currentIndex = -1
  }

  private editEmergencyDetail(emergencyId: number, emergencyDetail: EmergencyDetailModel): void {
    this.emergencyId = emergencyId
    this.emergencyDetail = emergencyDetail
    this.showEmergencyDetailDialog = true
    this.currentIndex = -1
  }

  private editEmergency(emergencyId: number): void {
    this.emergency = this.getEmergency(emergencyId)
    this.showEmergencyDialog = true
    this.currentIndex = -1
  }

  private onDeleteEmergency(emergencyId: number): void {
    Shared.confirmationPopup.open(
      `Are you sure you want to delete this ${this.jobTypeLabel('emergency')} from ALL policy schedules?`,
      '',
      '',
      '',
      '',
      this,
      'DeleteEmergency',
      emergencyId
    )
  }

  private DeleteEmergency(emergencyId: number): void {
    EmergencyController.DeleteEmergency(String(emergencyId))
      .then((res: boolean) => {
        if (res) {
          const emergencies: EmergencyModel[] = this.emergencies.filter((e: any) => e.emergencyId !== emergencyId)
          if (this.jobType === 'HE') {
            storeMutations.setEmergencies(emergencies)
          } else if (this.jobType === 'US') {
            this.emergencyListUS = emergencies
          } else {
            this.emergencyListSI = emergencies
          }
          this.emergencyList = this.emergencyList.filter((e: any) => e.emergencyId !== emergencyId)
          this.$emit('update:selectedEmergencies', this.emergencyList)
          this.emergency = new EmergencyModel()
          this.saveSnackbarText = this.jobTypeLabel('emergency') + ' Deleted successfully.'
          this.saveSnackbar = true
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error deleting an emergency, please try again', true)
      })
  }

  private onSaveEmergency(emergency: EmergencyModel): void {
    emergency.jobType = this.jobType
    EmergencyController.SaveEmergency(emergency)
      .then((res: EmergencyModel) => {
        if (res) {
          emergency.isLocal = false
          if (emergency.emergencyId) {
            const index = this.emergencies.findIndex((e: any) => e.emergencyId === emergency.emergencyId)
            if (index !== -1) {
              this.emergencies[index] = res
              if (this.jobType === 'HE') {
                storeMutations.setEmergencies(this.emergencies)
              } else if (this.jobType === 'US') {
                this.emergencyListUS = this.emergencies
              } else {
                this.emergencyListSI = this.emergencies
              }
            }
            const emergencyListIndex = this.emergencyList.findIndex((e: any) => e.emergencyId === emergency.emergencyId)
            if (emergencyListIndex !== -1) {
              this.emergencyList[emergencyListIndex] = res
            }
            this.$emit('update:selectedEmergencies', this.emergencyList)
            this.emergency = new EmergencyModel()
          } else {
            this.emergencies.push(res)
            if (this.jobType === 'HE') {
              storeMutations.setEmergencies(this.emergencies)
            } else if (this.jobType === 'US') {
              this.emergencyListUS = this.emergencies
            } else {
              this.emergencyListSI = this.emergencies
            }
          }
        } else {
          const index = this.emergencies.findIndex((e: any) => e.emergencyId === emergency.emergencyId)
          if (index !== -1) {
            this.emergencies[index] = this.emergency
          }
        }
        this.showEmergencyDialog = false
        this.saveSnackbarText = this.jobTypeLabel('emergency') + ' Saved successfully.'
        this.saveSnackbar = true
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error saving an emergency, please try again', true)
      })
  }

  private onDeleteEmergencyDetail(emergencyDetailId: number): void {
    Shared.confirmationPopup.open(
      'Do you really want to delete emergency detail? It will delete from all policy schedule.',
      '',
      '',
      '',
      '',
      this,
      'DeleteEmergencyDetail',
      emergencyDetailId
    )
  }

  private DeleteEmergencyDetail(emergencyDetailId: number): void {
    EmergencyController.DeleteEmergencyDetail(String(emergencyDetailId))
      .then((res: boolean) => {
        if (res) {
          this.emergencyDetails = this.emergencyDetails.filter((e: any) => e.emergencyDetailId !== emergencyDetailId)
          this.saveSnackbarText = 'Emergency Detail Deleted successfully.'
          this.saveSnackbar = true
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error deleting an emergency detail, please try again', true)
      })
  }

  private onSaveEmergencyDetail(emergencyDetail: EmergencyDetailModel): void {
    EmergencyController.SaveEmergencyDetail(emergencyDetail)
      .then((res: EmergencyDetailModel) => {
        if (emergencyDetail.emergencyDetailId === 0) {
          emergencyDetail.isLocal = false
          this.emergencyDetails.push(res)
        } else {
          const index = this.emergencyDetails.findIndex(
            (e: any) => e.emergencyDetailId === emergencyDetail.emergencyDetailId
          )
          if (index !== -1) {
            this.emergencyDetails[index] = Object.assign(this.emergencyDetails[index], res)
            this.emergencyDetails = [...this.emergencyDetails]
          }
        }
        this.showEmergencyDetailDialog = false
        this.saveSnackbarText = 'Emergency Detail Saved successfully.'
        this.saveSnackbar = true
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error saving an emergency detail, please try again', true)
      })
  }

  private onEmergencyDetailCheckChange(emergencyDetail: EmergencyDetailModel, value: any) {
    emergencyDetail.isEmergencyCoverageExist = !emergencyDetail.isEmergencyCoverageExist
    if (value && value.length > 0) {
      emergencyDetail.emergencyCoverage = new EmergencyCoverageModel()
      emergencyDetail.emergencyCoverage.id = 0
      emergencyDetail.emergencyCoverage.emergencyDetailId = emergencyDetail.emergencyDetailId
    } else {
      emergencyDetail.emergencyCoverage = null
    }

    let emergencyDetailItem: EmergencyDetailModel[] = []
    emergencyDetailItem = value.filter((e: EmergencyDetailModel) => e.typeId === emergencyDetail.typeId)
    // unselect emergency, if none of the emergency detail selected
    if (emergencyDetailItem && emergencyDetailItem.length === 0) {
      const emergencyItem: EmergencyModel | undefined = this.emergencyList.find(
        (e: EmergencyModel) => e.emergencyId === emergencyDetail.typeId
      )
      if (emergencyItem) {
        const index: number = this.emergencyList.indexOf(emergencyItem)
        this.emergencyList.splice(index, 1)
      }
      this.$emit('update:selectedEmergencies', this.emergencyList)
    }
  }

  private getThirdPartyDetail(id: number): EmergencyThirdPartyTrade | undefined {
    return this.thirdPartyDetail.find((e) => e.emergencyTypeId === id)
  }

  private showAddThirdPartyDetail(emergencyId: number): boolean {
    const thirdPartyDetail = this.getThirdPartyDetail(emergencyId)
    if (thirdPartyDetail !== undefined) {
      return false
    } else {
      return true
    }
  }

  private addEditThirdPartyDetail(emergencyId: number) {
    const thirdPartyDetail = this.getThirdPartyDetail(emergencyId)
    this.thirdPartyContractorId = thirdPartyDetail !== undefined ? thirdPartyDetail.contractorId : ''
    this.emergencyId = emergencyId
    this.showThirdPartyDetailDialog = true
    this.currentIndex = -1
  }

  private onSaveThirdPartyDetail(emergencyThirdPartyTrade: EmergencyThirdPartyTrade) {
    let thirdPartyDetail: any = null
    emergencyThirdPartyTrade.policyId = this.policyId
    if (this.thirdPartyDetail && this.thirdPartyDetail.length > 0) {
      thirdPartyDetail = this.getThirdPartyDetail(emergencyThirdPartyTrade.emergencyTypeId)
      emergencyThirdPartyTrade.id = thirdPartyDetail !== undefined ? thirdPartyDetail.id : 0
    }
    if (emergencyThirdPartyTrade.contractorId === '' && emergencyThirdPartyTrade.id === 0) {
      this.showThirdPartyDetailDialog = !this.showThirdPartyDetailDialog
    } else if (emergencyThirdPartyTrade.contractorId === '' && emergencyThirdPartyTrade.id !== 0) {
      {
        PolicyHolderController.DeleteThirdPartyDetail(emergencyThirdPartyTrade.id)
          .then((res: boolean) => {
            if (res) {
              const index = this.thirdPartyDetail.findIndex(
                (e: any) => e.emergencyTypeId === thirdPartyDetail.emergencyTypeId
              )
              if (index !== -1) {
                this.thirdPartyDetail.splice(this.thirdPartyDetail.indexOf(thirdPartyDetail), 1)
              }
            }
            this.showThirdPartyDetailDialog = !this.showThirdPartyDetailDialog
            this.saveSnackbarText = 'Third Party Detail Deleted successfully.'
            this.saveSnackbar = true
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error deleting third party contractor detail, please try again', true)
          })
      }
    } else {
      PolicyHolderController.SaveThirdPartyDetail(emergencyThirdPartyTrade)
        .then((res: EmergencyThirdPartyTrade) => {
          if (thirdPartyDetail) {
            const index = this.thirdPartyDetail.findIndex(
              (e: any) => e.emergencyTypeId === thirdPartyDetail.emergencyTypeId
            )
            if (index !== -1) {
              this.thirdPartyDetail[index] = res
            }
          } else {
            this.thirdPartyDetail.push(res)
          }
          this.showThirdPartyDetailDialog = !this.showThirdPartyDetailDialog
          this.saveSnackbarText = 'Third Party Detail Saved successfully.'
          this.saveSnackbar = true
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error saving third party contractor detail, please try again', true)
        })
    }
    this.thirdPartyContractorId = ''
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private editEngineerSiteSLA(emergency: EmergencyModel) {
    this.currentIndex = emergency.emergencyId
    this.engineerSiteSLA = emergency.engineerSiteSLA
    this.localEngineerSiteSLA = emergency.engineerSiteSLA
  }

  private cancelEditEngineerSiteSLA(emergency: EmergencyModel) {
    emergency.engineerSiteSLA = this.localEngineerSiteSLA
    this.currentIndex = -1
  }

  private jobTypeLabel(name: string) {
    return Shared.JobTypeLabel(name, this.jobType)
  }

  private saveEngineerSiteSLA(emergency: EmergencyModel) {
    const engineerOnSiteSLASelector: any = this.$el.querySelector('#engineerOnSiteSLA')
    if (engineerOnSiteSLASelector) {
      this.engineerSiteSLA = engineerOnSiteSLASelector.value !== undefined ? engineerOnSiteSLASelector.value : ''
    }
    emergency.engineerSiteSLA = this.engineerSiteSLA
    this.currentIndex = -1
    this.onSaveEmergency(emergency)
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }

  private onCloseEmergencyDialog() {
    this.showEmergencyDialog = false
    this.emergency = new EmergencyModel()
  }

  private get getEmergencyList() {
    return this.emergencies.filter(
      (e) =>
        !e.isDeleted ||
        (e.isDeleted &&
          this.tempSelectedEmergencies &&
          this.tempSelectedEmergencies.findIndex((x) => x.emergencyId === e.emergencyId) !== -1)
    )
  }
}
</script>

<style scoped>
.que-checkbox {
  margin: 4px 0 10px;
}
.emergencies-type-que {
  background-color: #f3f3f3;
  padding: 16px;
}
.policy-scroll-content h3 {
  font-size: 18px;
}
.policy-scroll-content .scroll-content {
  max-height: 450px;
}
.policy-cov-list >>> .v-input__control label {
  font-size: 14px;
}
.edit-valid.input-group--selection-controls label {
  max-width: 84%;
  min-width: 84%;
  width: 84%;
}
.control-right-side {
  text-align: right;
}
.control-right-side span {
  display: inline-block;
  max-width: 100px;
}
.scroll-content {
  max-height: 500px;
}
.policy-scroll-content .scroll-content {
  max-height: 450px;
}
.policy-scroll-content .que-checkbox {
  padding-right: 50px;
}
.policy-scroll-content .que-checkbox >>> .v-input__control {
  word-break: break-word;
}
.policy-scroll-content .menu-activator {
  position: absolute;
  right: 11px;
  top: 23px;
}
.icon-edit {
  font-size: 19px;
}
.edit-valid {
  max-width: 27%;
}
.adp-type-text {
  font-size: 18px;
  cursor: pointer;
}
.policy-addemergency .emergency-edit {
  right: 45px;
  cursor: pointer;
  z-index: 7;
}
.md-icon {
  font-size: 20px;
  cursor: pointer;
}
.policy-addemergency >>> .v-icon {
  cursor: pointer;
}
.emergecny-list-sla {
  position: relative;
  padding-right: 80px;
  border-bottom: 1px solid #dcd6d6;
}
.sla-time {
  position: absolute;
  right: 0px;
  top: 0px;
  font-weight: 500;
  z-index: 1;
  max-width: 80px;
  padding-right: 25px;
}
.sla-time.sla-time-edit {
  padding-right: 0px;
}
.sla-text,
.sla-close {
  position: absolute;
  right: 0px;
  top: 6px;
}
.sla-close {
  right: 22px;
  top: 3px;
}
.sla-time >>> .v-input--is-disabled .v-input__slot:before {
  border: none;
}
.sla-time >>> .v-input--is-disabled .v-input__slot:after {
  display: none;
}
.sla-time >>> .v-input--is-disabled .v-input__slot input {
  color: #617e8d;
  text-align: right;
}
.sla-time >>> .v-input--is-disabled .v-input__append-inner {
  display: none;
}
.emergecny-list-sla >>> .v-input__control label {
  word-break: break-all;
}
.sla-action {
  position: absolute;
  top: -4px;
  right: -12px;
  opacity: 0;
  visibility: hidden;
}
.sla-time:hover .sla-action {
  opacity: 1;
  visibility: visible;
}
.sla-time >>> .v-input__append-inner .v-icon {
  transform: rotate(0deg);
}
.sla-time.sla-time-edit >>> .v-input__slot input {
  padding-right: 18px;
}
.sla-value {
  font-size: 16px;
  padding-top: 5px;
  display: inline-block;
}
</style>
