<template>
  <div>
    <v-dialog
      v-if="documentTemplate && showDocumentTemplateDialog"
      v-model="showDocumentTemplateDialog"
      max-width="800"
      content-class="document-temp v-dialog--scrollable"
    >
      <DocumentTemplate
        ref="refDocumentTemplate"
        :document-item="documentTemplate"
        :is-edit="true"
        @OnDocumentSave="saveDocumentTemplate"
      ></DocumentTemplate>
    </v-dialog>
    <v-layout wrap>
      <v-flex class="pl-2 pr-2" xs12>
        <v-data-table
          :headers="headers"
          :items="getDocumentList"
          :loading="isTemplateLoading"
          :pagination.sync="pagination"
          class="gridView document-template-list"
        >
          <template slot="items" slot-scope="props">
            <tr :class="props.item.isDeleted ? 'deleted-template' : ''">
              <td>
                <v-checkbox
                  color="primary"
                  hide-details
                  :input-value="props.item.selectedTemplate"
                  :disabled="isUserRoleClientManager"
                  class="documentRecord"
                  @click.native="onDocumentSelect(props.item)"
                ></v-checkbox>
              </td>

              <td>{{ props.item.name }}</td>
              <td>{{ props.item.templateTypeDescription }}</td>
              <td>{{ props.item.notificationPreferenceName }}</td>
              <td class="justify-center layout px-0">
                <v-tooltip v-if="!isUserRoleClientManager" top>
                  <template #activator="{ on }">
                    <v-btn icon class="mx-0 btn-editDocumenTemplate" v-on="on" @click="onEditClick(props.item)">
                      <v-icon color="grey">edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import DocumentTemplateModel from '@/models/policy/DocumentTemplateModel'
import DocumentTemplate from '@/views/DocumentTemplate.vue'
import PolicyDocumentTemplateModel from '@/models/policy/PolicyDocumentTemplateModel'
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import PolicyHolderController from '@/api/policyHolderController'
import Store from '@/store'
import eventBus from '@/common/bus'

@Component({
  components: { DocumentTemplate },
})
export default class PolicyDocumentTemplate extends Vue {
  public localPolicyDocumentTemplates: PolicyDocumentTemplateModel[] = []
  @Prop() private policyDocumentTemplates: PolicyDocumentTemplateModel[]
  private documentList: DocumentTemplateModel[] = []

  private headers: any = []
  private pagination: any = {}
  private isTemplateLoading = false
  private showDocumentTemplateDialog = false
  private documentTemplate: DocumentTemplateModel = new DocumentTemplateModel()
  private isLoading = false

  public getDocumentTemplateList(insurerId: number) {
    const self = this
    PolicyHolderController.GetDocumentTemplateByInsurerId(insurerId)
      .then((res: DocumentTemplateModel[]) => {
        if (res) {
          self.documentList = res
          self.documentList.forEach((element: DocumentTemplateModel) => {
            element.notificationPreferenceName = this.getNotificationPreferenceNameByID(
              element.notificationPreferenceId
            )
          })

          if (self.localPolicyDocumentTemplates) {
            self.localPolicyDocumentTemplates.forEach((element: PolicyDocumentTemplateModel) => {
              const item: DocumentTemplateModel | undefined = self.documentList.find(
                (e: DocumentTemplateModel) => e.templateId === element.documentTemplateId
              )
              if (item) {
                Vue.set(item, 'selectedTemplate', true)
              }
            })
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading document template list, please try again', true)
      })
  }

  private created(): void {
    this.headers = [
      { text: 'Select', value: 'selectedTemplate', sortable: false },
      { text: 'Name', value: 'name' },
      { text: 'Template Type', value: 'templateTypeDescription' },
      { text: 'Notification Preference', value: 'notificationPreferenceName' },
      { text: '', sortable: false },
    ]
    this.pagination.rowsPerPage = 10
  }

  @Watch('policyDocumentTemplates')
  private onPolicyDocumentTemplatesChange() {
    this.localPolicyDocumentTemplates = JSON.parse(JSON.stringify(this.policyDocumentTemplates))
  }

  private saveDocumentTemplate(document: DocumentTemplateModel) {
    this.showDocumentTemplateDialog = false
    if (document) {
      document.notificationPreferenceName = this.getNotificationPreferenceNameByID(document.notificationPreferenceId)
      this.documentList.forEach((element: DocumentTemplateModel) => {
        if (element.templateId === document.templateId) {
          element = document
        }
      })
    }
  }

  private getNotificationPreferenceNameByID(notificationPreferenceId: number): string {
    let notificationPreference = ''
    switch (notificationPreferenceId) {
      case 1:
        notificationPreference = 'SMS'
        break
      case 2:
        notificationPreference = 'Email'
        break
      case 3:
        notificationPreference = 'Both'
        break
      default:
        break
    }
    return notificationPreference
  }

  private onEditClick(template: DocumentTemplateModel) {
    const self = this
    self.isTemplateLoading = true
    self.documentTemplate = template
    self.isTemplateLoading = false
    this.showDocumentTemplateDialog = true
  }

  private onDocumentSelect(documentTemplate: DocumentTemplateModel): void {
    const selectedItem: DocumentTemplateModel | undefined = this.documentList.find(
      (e: DocumentTemplateModel) => e.templateId === documentTemplate.templateId
    )
    if (selectedItem) {
      Vue.set(selectedItem, 'selectedTemplate', !selectedItem.selectedTemplate)

      const selectedPolicyDocumentTemplates = new PolicyDocumentTemplateModel()
      selectedPolicyDocumentTemplates.documentTemplateId = selectedItem.templateId
      if (this.localPolicyDocumentTemplates === null) {
        this.localPolicyDocumentTemplates = []
      }
      if (selectedItem.selectedTemplate) {
        this.localPolicyDocumentTemplates.push(selectedPolicyDocumentTemplates)
      } else {
        const unselectedTemplate: PolicyDocumentTemplateModel | undefined = this.localPolicyDocumentTemplates.find(
          (e: PolicyDocumentTemplateModel) => e.documentTemplateId === selectedItem.templateId
        )
        if (unselectedTemplate) {
          const index: number = this.localPolicyDocumentTemplates.indexOf(unselectedTemplate)
          this.localPolicyDocumentTemplates.splice(index, 1)
        }
      }
    }
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }

  private get getDocumentList() {
    return this.documentList.filter(
      (d) =>
        !d.isDeleted ||
        (d.isDeleted &&
          this.policyDocumentTemplates &&
          this.policyDocumentTemplates.findIndex((p) => p.documentTemplateId === d.templateId) !== -1)
    )
  }
}
</script>

<style scoped>
.gridView >>> table > thead .checkbox .v-icon {
  color: #607d8c !important;
}
.deleted-template td {
  text-decoration: line-through;
}
</style>
