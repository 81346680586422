<template>
  <div>
    <v-dialog
      v-if="clientRateTemplateDialog"
      v-model="clientRateTemplateDialog"
      max-width="1200"
      persistent
      content-class="v-dialog--scrollable full-card-width"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Client Rate</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="close-rate-template-dialog" @click.native="closeClientRateTemplateDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-card class="py-0">
            <ClientRateUS
              v-if="jobType === 'US'"
              ref="clientRateUSRef"
              :selected-rate-package-in-policy="selectedRatePackage"
              :all-client-rate-package-list.sync="allClientRatePackageList"
              :policy-id="policyModel.policyId"
              :policy-name="policyModel.name"
              :rate-package-id.sync="policyModel.ratePackageId"
              :show-rate-template-in-dialog="true"
              @saveRatePackage="onSaveRatePackage"
            ></ClientRateUS>
            <ClientRate
              v-else
              ref="clientRateRef"
              :selected-rate-package-in-policy="selectedRatePackage"
              :all-client-rate-package-list.sync="allClientRatePackageList"
              :policy-id="policyModel.policyId"
              :policy-name="policyModel.name"
              :rate-package-id.sync="policyModel.ratePackageId"
              :show-rate-template-in-dialog="true"
              @saveRatePackage="onSaveRatePackage"
            ></ClientRate>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex class="policy-name-add" xs4>
            <v-text-field
              v-model.trim="policyModel.name"
              v-validate="'required|max:50'"
              label="Policy Name"
              class="required"
              required
              maxlength="50"
              data-vv-name="Policy Name"
              :error-messages="errors.collect('Policy Name')"
              :readonly="isUserRoleClientManager"
              name="PolicyName"
            ></v-text-field>
            <v-chip color="secondary" class="new-policy-chip" disabled :value="selectedPolicyIsNew">New</v-chip>
          </v-flex>
          <v-flex xs2 pl-3 assumed-cover-switch>
            <v-switch
              v-model="policyModel.isStarred"
              color="primary"
              small
              name="AssumedCover"
              :readonly="isUserRoleClientManager"
              label="Assumed Cover"
            ></v-switch>
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pr-2 pl-3" xs6>
            <v-text-field
              v-model.trim="policyModel.insurerReference"
              v-validate="'max:50'"
              label="Insurer Reference"
              maxlength="50"
              data-vv-name="Insurer Reference"
              :error-messages="errors.collect('Insurer Reference')"
              :readonly="isUserRoleClientManager"
              name="InsurerReference"
            />
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pl-2 pr-3" xs6>
            <v-select
              v-model="policyModel.complexity"
              :items="getComplexity"
              label="Complexity"
              item-text="complexity"
              item-value="id"
              :readonly="isUserRoleClientManager"
              name="Complexity"
            ></v-select>
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pr-2 pl-3" xs6>
            <v-select
              v-model="policyModel.priority"
              :items="getPriority"
              label="Priority"
              item-text="priority"
              item-value="id"
              :readonly="isUserRoleClientManager"
              name="Priority"
            ></v-select>
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pl-2 pr-3" xs6>
            <v-textarea
              v-model.trim="policyModel.intention"
              v-validate="'max:500'"
              label="Intention"
              data-vv-name="Intention"
              :error-messages="errors.collect('Intention')"
              :readonly="isUserRoleClientManager"
              class="intention"
            ></v-textarea>
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pr-2 pl-3 showEditor" xs6>
            <div>
              <label class="pre-block-label">Emergency Definition</label>
              <v-btn v-if="!isUserRoleClientManager" icon class="ma-0 emergency-definition" small @click="openEditor()">
                <v-icon color="primary">edit</v-icon>
              </v-btn>
              <div v-bar="{ useScrollbarPseudo: true }" class="pre-block">
                <v-layout>
                  <pre class="mb-0 mt-2" v-html="sanitizeHTML(policyModel.emergencyDefinition)">{{
                    policyModel.emergencyDefinition
                  }}</pre>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pl-2 pr-3" xs6>
            <v-text-field
              v-model.trim="policyModel.deliveryPhone"
              v-validate="'max:20'"
              label="Delivery Phone"
              placeholder="Phone Number"
              maxlength="20"
              data-vv-name="Delivery Phone"
              :error-messages="errors.collect('Delivery Phone')"
              :readonly="isUserRoleClientManager"
              name="DeliveryPhone"
            />
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pr-2 pl-3" xs6>
            <v-text-field
              v-model.trim="policyModel.answerPhone"
              v-validate="'max:500'"
              label="Phone Answer"
              placeholder="Welcome to the claims department, how may I help you?"
              maxlength="500"
              data-vv-name="Phone Answer"
              :error-messages="errors.collect('Phone Answer')"
              :readonly="isUserRoleClientManager"
              name="PhoneAnswer"
            />
          </v-flex>
          <v-flex :class="jobType === 'HE' ? 'pl-2 pr-3' : 'pr-2 pl-3'" xs6>
            <v-text-field
              v-show="jobType === 'HE' || jobType === 'SI'"
              v-model.number="policyModel.policyLimit"
              v-validate="'required|numeric|max:8'"
              :label="'Policy Limit'"
              type="number"
              maxlength="8"
              placeholder="0"
              class="required"
              required
              :data-vv-name="'Policy Limit'"
              :error-messages="errors.collect('Policy Limit')"
              :readonly="isUserRoleClientManager"
              name="PolicyLimit"
            />
            <v-text-field
              v-show="jobType === 'US'"
              v-model.number="policyModel.policyLimit"
              v-validate="'required|numeric|max:8'"
              label="Delegated Authority"
              type="number"
              maxlength="8"
              placeholder="0"
              class="required"
              required
              :data-vv-name="'Delegated Authority'"
              :error-messages="errors.collect('Delegated Authority')"
              :readonly="isUserRoleClientManager"
              name="DelegatedAuthority"
            />
          </v-flex>
          <v-flex v-if="jobType === 'HE'" class="pr-2 pl-3 mt-1" xs6>
            <v-switch
              v-model="policyModel.hasPolicyLimitPerEmergency"
              label="Policy Limit Per Emergency"
              color="primary"
              hide-details
              :readonly="isUserRoleClientManager"
              name="PolicyLimitPerEmergency"
            ></v-switch>
          </v-flex>
          <v-flex :class="jobType === 'HE' ? 'pl-2 pr-3' : ''" xs6>
            <v-text-field
              v-model="policyModel.cutOffHours"
              v-validate="'numeric|max:3'"
              label="Cut off Hours"
              placeholder="Cut off hours"
              maxlength="3"
              data-vv-name="Cut off Hours"
              :error-messages="errors.collect('Cut off Hours')"
              :readonly="isUserRoleClientManager"
              name="CutoffHours"
            />
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pr-2 pl-3" xs6>
            <v-select
              v-model="policyModel.enquiryType"
              :disabled="policyModel.isHelplinePolicy"
              :items="getEnquiryType"
              label="Enquiry Type"
              item-text="enquiryType"
              item-value="id"
              :readonly="isUserRoleClientManager"
              name="EnquiryType"
            ></v-select>
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pl-2 pr-3 mt-1" xs6>
            <v-switch
              v-model="policyModel.isHelplinePolicy"
              label="Is Helpline Policy?"
              color="primary"
              hide-details
              :readonly="isUserRoleClientManager"
              name="helplinePolicy"
              @change="onHelplinePolicyChange"
            ></v-switch>
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pr-2 pl-3" xs6>
            <v-text-field
              v-model="policyModel.helplineEmail"
              v-validate="{
                rules: {
                  required: policyModel.isHelplinePolicy ? true : false,
                  emails: true,
                },
              }"
              label="Helpline Emails"
              :class="policyModel.isHelplinePolicy ? 'required' : ''"
              required
              data-vv-name="Helpline Email"
              :error-messages="errors.collect('Helpline Email')"
              :readonly="isUserRoleClientManager"
              name="HelplineEmail"
            />
          </v-flex>
          <v-flex v-show="jobType !== 'SI'" xs6 class="pl-2 pr-3 package-selction">
            <v-autocomplete
              v-model="selectedRatePackage"
              label="Search package"
              return-object
              :items="getClientRatePackageList"
              item-text="packageName"
              item-value="id"
              :readonly="isUserRoleClientManager"
              class="select-package"
              @change="onClientRatePackageChange($event)"
            ></v-autocomplete>
            <span
              v-if="policyModel.policyId !== 0 && !isUserRoleClientManager"
              class="packge-btn pr-2"
              :class="editClientRatePackage || selectedRatePackage !== null ? 'show' : 'hide'"
            >
              <v-btn
                icon
                color="primary"
                small
                class="ma-0 edit-rate-template"
                @click="clientRateTemplateDialog = true"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </span>
          </v-flex>
          <v-flex xs6 class="text-xs-center icon-validation img-with-input" :class="{ 'pl-3 pr-2': jobType !== 'US' }">
            <v-text-field
              v-model="imageName"
              label="Logo"
              placeholder="Select logo"
              prepend-icon="attach_file"
              :hide-details="true"
              :readonly="isUserRoleClientManager"
              name="imageName"
              @click="pickFile"
            ></v-text-field>
            <input
              ref="image"
              v-validate="{
                rules: {
                  required: false,
                  image: true,
                  size: LogoSize,
                  mimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
                  ext: ['jpg', 'jpeg', 'png', 'gif'],
                },
              }"
              type="file"
              style="display: none"
              accept="image/*"
              name="logo"
              :disabled="isUserRoleClientManager"
              @change="onFilePicked"
            />
            <div class="input-group__details">
              <div class="input-group__messages input-group__error input-group--error error--text">
                <span v-if="imageValidationMessage(errors)">
                  {{ logoErrorMsg }}
                </span>
              </div>
            </div>
            <div v-if="imageUrl" class="show-up-logo">
              <img :src="imageUrl" />
              <v-btn
                v-if="!isUserRoleClientManager"
                flat
                icon
                color="primary"
                small
                class="remove-img"
                @click.native.stop="removeImage"
              >
                <v-icon dark color="primary">delete</v-icon>
              </v-btn>
            </div>
          </v-flex>
          <v-flex v-show="jobType === 'HE'" class="pl-2 pr-3 mt-1" xs6>
            <v-switch
              v-model="policyModel.isCommercialPropertyPolicy"
              label="Is Commercial Property Policy?"
              color="primary"
              hide-details
              :readonly="isUserRoleClientManager"
              name="isCommercialPropertyPolicy"
              @change="onHelplinePolicyChange"
            ></v-switch>
          </v-flex>
        </v-layout>
        <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
          {{ saveSnackbarText }}
          <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
        </v-snackbar>
      </v-flex>
    </v-layout>
    <v-dialog v-model="editEmergencyDefinition" content-class="v-dialog--scrollable" max-width="650" persistent>
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Emergency Definition</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="editEmergencyDefinition = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-3">
          <v-layout>
            <v-flex xs12>
              <froala
                id="froala"
                v-model="emergencyDefinitionTemp"
                data-vv-scope="templateformRef"
                data-vv-name="Email"
                :config="config"
                :tag="'textarea'"
              ></froala>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="editEmergencyDefinition = false">Close</v-btn>
          <v-btn color="primary" class="mr-0 save-emergency-definition" @click="onSaveEmergencyDefinition()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import PolicyBasicDetailModel from '@/models/policy/PolicyBasicDetailModel'
import Shared from '@/common/shared'
import ClientRatePackageModel from '@/models/client/ClientRatePackageModel'
import Store from '@/store'
import { Complexity, Priority, EnquiryType } from '@/common/enums'
import ClientRate from '@/components/ClientRate.vue'
import { JobType } from '@/models/types'
import ClientRateUS from '@/components/ClientRateUS.vue'
import ClientRatePackageUSModel from '../models/client/ClientRatePackageUSModel'
import ClientFixedFeeRateModel from '@/models/client/ClientFixedFeeRateModel'

interface IComplexity {
  id: number
  complexity: string
}
interface IPriority {
  id: number
  priority: string
}
interface IEnquiryType {
  id: number
  enquiryType: string
}

@Component({
  components: { ClientRate, ClientRateUS },
})
export default class PolicySubDetail extends Vue {
  @Prop() public policyModel: PolicyBasicDetailModel
  public imageName = ''
  public imageUrl = ''
  public selectedRatePackage: ClientRatePackageModel | ClientRatePackageUSModel | null = null

  @Prop() private allClientRatePackageList: ClientRatePackageModel[] | ClientRatePackageUSModel[]
  @Prop() private jobType: JobType
  @Prop({ default: false }) private selectedPolicyIsNew: boolean

  private sanitizeHTML = Shared.sanitizeHTML
  private savedCustomRatePackage: ClientRatePackageModel | ClientRatePackageUSModel | null = null
  private editClientRatePackage = false
  private logoErrorMsg = ''
  private complexity = Complexity
  private priority = Priority
  private enquiryType = EnquiryType
  private clientRateTemplateDialog = false
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = ''
  private clientRateList: ClientRatePackageModel[] | ClientRatePackageUSModel[] = []
  private editEmergencyDefinition = false
  private emergencyDefinitionTemp = ''
  private config: any = Shared.froalaConfig()

  public setValues(
    selectedRatePackage: ClientRatePackageModel | ClientRatePackageUSModel | null,
    imageName: string,
    imageUrl: string
  ) {
    const selectedRatePackageTemp: any = selectedRatePackage

    // initialize with ClientFixedFeeRateModel model if clientFixedFeeRates value is set to null
    if (this.jobType === 'HE' && selectedRatePackageTemp && selectedRatePackageTemp.clientFixedFeeRates === null) {
      selectedRatePackageTemp.clientFixedFeeRates = new ClientFixedFeeRateModel()
    }
    this.selectedRatePackage = selectedRatePackageTemp
    this.imageName = imageName
    this.imageUrl = imageUrl
  }

  private mounted() {
    this.getClientRatePackages()
  }

  @Watch('policyModel')
  private onPolicyDetailChange() {
    this.getClientRatePackages()
    this.editClientRatePackage = false
    this.$validator.errors.items = []
  }

  private onHelplinePolicyChange() {
    this.policyModel.enquiryType = this.policyModel.isHelplinePolicy ? EnquiryType.Off : EnquiryType.On
  }

  private imageValidationMessage(err: any) {
    if (err && err.collect('logo').length > 0) {
      const message: string = err.collect('logo')[0]
      this.logoErrorMsg =
        message === 'The logo field must be an image.' ? 'Only JPEG, JPG, PNG, GIF files are allowed.' : message
      this.removeImage()
      return true
    }
  }

  private removeImage() {
    this.imageName = ''
    this.imageUrl = ''
    const image = this.$refs.image as any
    image.value = null
    this.policyModel.fileURL = ''
    this.policyModel.file = ''
  }

  private get getClientRatePackageList(): ClientRatePackageModel[] | ClientRatePackageUSModel[] {
    return this.clientRateList
  }

  private set getClientRatePackageList(newValue: ClientRatePackageModel[] | ClientRatePackageUSModel[]) {
    this.clientRateList = newValue
  }

  private get getComplexity(): IComplexity[] {
    const keys: any[] = Object.keys(this.complexity).filter(Number)
    const complexityItems: IComplexity[] = []
    keys.forEach((element) => {
      const complexityEntity: any = {}
      complexityEntity.id = Number(element)
      complexityEntity.complexity = Complexity[element]
      complexityItems.push(complexityEntity)
    })
    return complexityItems
  }

  private get getPriority(): IPriority[] {
    const keys: any[] = Object.keys(this.priority).filter(Number)
    const priorityItems: IPriority[] = []
    keys.forEach((element) => {
      const priorityEntity: any = {}
      priorityEntity.id = Number(element)
      priorityEntity.priority = Priority[element]
      priorityItems.push(priorityEntity)
    })
    return priorityItems
  }

  private get getEnquiryType(): IEnquiryType[] {
    const keys: any[] = Object.keys(this.enquiryType).filter(Number)
    const enquiryTypeItems: IEnquiryType[] = []
    keys.forEach((element) => {
      const enquiryTypeEntity: any = {}
      enquiryTypeEntity.id = Number(element)
      enquiryTypeEntity.enquiryType = EnquiryType[element]
      enquiryTypeItems.push(enquiryTypeEntity)
    })
    return enquiryTypeItems
  }

  private pickFile() {
    const logoError = this.$validator.errors.items.find((a) => a.field === 'logo')
    if (logoError) {
      logoError.msg = ''
    }
    const image = this.$refs.image as any
    image.click()
  }

  private get LogoSize(): number {
    return Store.Instance.state.Environment.LogoSize
  }

  private onFilePicked(e: any) {
    const files = e.target.files
    const self = this
    if (files[0] !== undefined) {
      self.imageName = files[0].name
      if (self.imageName.lastIndexOf('.') <= 0) {
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        self.imageUrl = fr.result as string
        if (self.logoErrorMsg === '' && fr.result) {
          self.policyModel.file = fr.result as string
        }
      })
    }
  }

  private onClientRatePackageChange(selectedRatePackage: ClientRatePackageModel) {
    this.savedCustomRatePackage = null
    this.editClientRatePackage = true
    this.selectedRatePackage = selectedRatePackage
    // initialize with ClientFixedFeeRateModel model if clientFixedFeeRates value is set to null
    if (this.selectedRatePackage && this.selectedRatePackage.clientFixedFeeRates === null) {
      this.selectedRatePackage.clientFixedFeeRates = new ClientFixedFeeRateModel()
    }
  }

  private onSaveRatePackage(clientRatePackage: ClientRatePackageModel) {
    // on save rate package, get all rate package list
    this.getClientRatePackages()
    this.saveSnackbarText = 'Rate saved successfully.'
    this.saveSnackbar = true
    if (clientRatePackage.forCustomPackagePolicyId !== 0) {
      this.selectedRatePackage = null
      this.savedCustomRatePackage = clientRatePackage
    } else {
      this.savedCustomRatePackage = null
    }
    if (this.savedCustomRatePackage !== null) {
      // added timeout to set newly created custom rate package as selected package
      setTimeout(() => {
        this.selectedRatePackage = this.savedCustomRatePackage
      }, 0)
    }
    this.clientRateTemplateDialog = false
  }

  private get getAllClientRatePackageList(): ClientRatePackageModel[] | ClientRatePackageUSModel[] {
    return this.allClientRatePackageList
  }

  // filter rate packages by client from all client rate packages
  private getClientRatePackages() {
    if (this.getAllClientRatePackageList) {
      const clientRatePackageList: ClientRatePackageModel[] | ClientRatePackageUSModel[] = (
        this.getAllClientRatePackageList as any
      ).filter(
        (a: ClientRatePackageModel) =>
          a.forCustomPackagePolicyId === this.policyModel.policyId || a.forCustomPackagePolicyId === 0
      )
      this.getClientRatePackageList = clientRatePackageList
    }
  }

  private closeClientRateTemplateDialog() {
    this.clientRateTemplateDialog = false
    this.getClientRatePackages()
  }

  private markPolicyAsStarred() {
    if (this.isUserRoleClientManager) {
      return
    }
    this.policyModel.isStarred = !this.policyModel.isStarred
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }

  private openEditor() {
    this.emergencyDefinitionTemp = this.policyModel.emergencyDefinition
    this.editEmergencyDefinition = true
  }

  private onSaveEmergencyDefinition() {
    this.policyModel.emergencyDefinition = this.emergencyDefinitionTemp
    this.emergencyDefinitionTemp = ''
    this.editEmergencyDefinition = false
  }
}
</script>

<style scoped>
.policy-name-add .v-input__control input {
  padding-right: 75px;
}
.policy-name-add {
  position: relative;
}
.policy-name-add .v-chip.secondary {
  position: absolute;
  top: 10px;
  right: 0px;
  color: #fff;
}
.starred-policy {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.package-selction {
  position: relative;
}
.package-selction .packge-btn {
  position: absolute;
  top: 15px;
  right: 30px;
}
.icon-validation {
  position: relative;
}
.img-with-input >>> .v-input .v-input__slot {
  padding-right: 185px;
}
.show-up-logo {
  position: absolute;
  width: 180px;
  top: -12px;
  right: 15px;
  height: 56px;
  display: inline-flex;
  padding: 1px 5px;
  border: 1px solid #d6d6d6;
}
.show-up-logo img {
  width: 100%;
  height: 50px;
  margin: auto;
  object-fit: contain;
}
.show-up-logo button {
  position: absolute;
  top: -10px;
  right: -15px;
}
.show-up-logo button >>> .v-btn__content {
  background-color: #ececec;
  height: inherit;
}
.show-up-logo button >>> .v-btn__content i {
  font-size: 17px;
}
.full-card-width.v-dialog .v-card {
  width: 100%;
}
.showEditor {
  position: relative;
}
.showEditor .v-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.showEditor .pre-block {
  height: 100px;
  border-bottom: 1px solid rgb(169, 169, 169);
}
.showEditor .pre-block-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.assumed-cover-switch {
  margin-top: 8px;
}
</style>
