<template>
  <div v-bar="{ useScrollbarPseudo: true }">
    <v-dialog
      v-if="showClientUserDialog"
      v-model="showClientUserDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ClientUser
        ref="clientUser"
        :policies="policies"
        :policy-search-running="policySearchRunning"
        :client-user-item="clientUser"
        @saveClientUser="onSaveClientUser"
        @OnDialogClose="closeDialog"
      ></ClientUser>
    </v-dialog>
    <v-card class="pa-3">
      <v-card-title primary-title class="pa-0">
        <v-flex xs2>
          <v-select v-model="selectedUserType" :items="['Client Users', 'Affinity Partners']" />
        </v-flex>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          class="mt-0 pt-0"
          single-line
          hide-details
        ></v-text-field>
        <v-tooltip v-if="!isUserRoleClientManager" top>
          <template #activator="{ on }">
            <v-btn icon class="mx-0 add-action-btn add-client-user" v-on="on" @click="onCreateClientUser">
              <v-icon color="grey">add</v-icon>
            </v-btn>
          </template>
          <span>Add Client User</span>
        </v-tooltip>
      </v-card-title>
      <div>
        <v-data-table
          :headers="headers"
          :items="getClientUsers"
          :loading="isLoading"
          :search="search"
          :pagination.sync="pagination"
          class="gridView clientuserlist"
        >
          <template slot="items" slot-scope="props">
            <td class="profile-picture">
              <img :src="props.item.profileImageThumbnailUrl || '/img/unknownuser.png'" />
            </td>
            <td>{{ props.item.firstName }}</td>
            <td>{{ props.item.lastName }}</td>
            <td>{{ props.item.email }}</td>
            <td class="text-xs-center">
              {{ props.item.hasPortalAccess ? 'Yes' : 'No' }}
            </td>
            <td class="justify-center layout px-0">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    class="mx-0 edit-btn"
                    :disabled="isUserRoleClientManager"
                    v-on="on"
                    @click="onEditClick(props.item)"
                  >
                    <v-icon color="primary">edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    class="mx-0 mt-2 ml-2 delete-btn"
                    :disabled="isUserRoleClientManager"
                    v-on="on"
                    @click="onDeleteClick(props.item)"
                  >
                    <v-icon color="primary">delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
                {{ snackbarText }}
                <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
              </v-snackbar>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ClientUserDetailModel from '@/models/client/ClientUserDetailModel'
import ClientUserController from '@/api/clientUserController'
import Shared from '@/common/shared'
import ClientUser from '@/components/client/ClientUser.vue'
import Store from '@/store'
import PolicyModel from '@/models/policy/PolicyModel'
import eventBus from '@/common/bus'
@Component({
  components: { ClientUser },
})
export default class ClientUserList extends Vue {
  @Prop() private insurerId: number
  @Prop() private policies: PolicyModel[]
  @Prop() private policySearchRunning: boolean
  @Prop() private clientUsers: ClientUserDetailModel[]
  private showClientUserDialog = false
  private clientUser: ClientUserDetailModel | null = new ClientUserDetailModel()
  private clientUserList: ClientUserDetailModel[] = []
  private isLoading = false
  private search = ''
  private pagination: any = {}
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private selectedUserType: 'Client Users' | 'Affinity Partners' = 'Client Users'

  private created() {
    this.pagination.rowsPerPage = 10
    this.clientUserList = this.clientUsers
  }

  private get headers() {
    return [
      { text: ' ', value: 'profileImageThumbnailUrl', class: 'profile-picture-header', sortable: false },
      { text: 'First Name', value: 'firstName' },
      { text: 'Last Name', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'Portal Access', value: 'hasPortalAccess', align: 'center' },
      { text: '', sortable: false },
    ]
  }

  @Watch('clientUsers')
  private onClientUserListChange(newValue: ClientUserDetailModel[]) {
    this.isLoading = false
    this.getClientUsers = newValue
  }
  private get getClientUsers(): ClientUserDetailModel[] {
    if (!this.clientUserList) {
      return []
    }
    return this.clientUserList.filter((u) =>
      this.selectedUserType === 'Affinity Partners' ? u.isAffinityPartner : !u.isAffinityPartner
    )
  }

  private set getClientUsers(newValue: ClientUserDetailModel[]) {
    this.clientUserList = newValue
  }

  private onCreateClientUser() {
    if (this.clientUser) {
      this.clientUser.insurerId = this.insurerId
    }
    this.showClientUserDialog = true
  }

  private onEditClick(clientUser: ClientUserDetailModel) {
    this.clientUser = clientUser
    this.showClientUserDialog = true
  }

  private onDeleteClick(clientUser: ClientUserDetailModel) {
    Shared.confirmationPopup.open(
      'Do you really want to delete Client User?',
      '',
      '',
      '',
      'Delete',
      this,
      'DeactivateClientUser',
      clientUser
    )
  }

  private closeDialog(clientUser: ClientUserDetailModel) {
    this.showClientUserDialog = false
    this.clientUser = new ClientUserDetailModel()
  }

  private DeactivateClientUser(clientUser: ClientUserDetailModel) {
    const self = this
    self.isLoading = true
    if (clientUser.id) {
      ClientUserController.DeactivateClientUser(clientUser.id)
        .then((res: boolean) => {
          if (res) {
            self.getClientUsers.splice(self.getClientUsers.indexOf(clientUser), 1)
            self.getClientUsers = [...self.getClientUsers]
            self.snackbarText = 'Client User is deleted.'
            self.snackbar = true
            self.isLoading = false
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error submitting deactivate clientUser request, please try again', true)
          self.isLoading = false
        })
    }
  }

  private onSaveClientUser(clientUser: ClientUserDetailModel) {
    this.showClientUserDialog = false
    this.snackbarText = 'Client User Detail Saved Successfully.'
    this.snackbar = true
    const index: number = this.getClientUsers.findIndex((c) => c.id === clientUser.id)
    if (index !== -1) {
      this.getClientUsers[index].firstName = clientUser.firstName
      this.getClientUsers[index].lastName = clientUser.lastName
      this.getClientUsers[index].email = clientUser.email
      this.getClientUsers[index].hasPortalAccess = clientUser.hasPortalAccess
      this.getClientUsers[index].profileImageUrl = clientUser.profileImageUrl
      this.getClientUsers[index].profileImageThumbnailUrl = clientUser.profileImageThumbnailUrl
    } else {
      this.getClientUsers.push(clientUser)
    }
    this.clientUser = new ClientUserDetailModel()
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }
}
</script>

<style scoped>
.add-action-btn {
  position: relative !important;
  top: 6px;
}

.gridView >>> .profile-picture-header {
  max-width: 50px;
  width: 10px;
}

.profile-picture {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}

.profile-picture img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #d2d2d2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
</style>
