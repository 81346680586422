import { render, staticRenderFns } from "./ClientTemplate.vue?vue&type=template&id=2998c0b5&scoped=true"
import script from "./ClientTemplate.vue?vue&type=script&lang=ts"
export * from "./ClientTemplate.vue?vue&type=script&lang=ts"
import style0 from "./ClientTemplate.vue?vue&type=style&index=0&id=2998c0b5&prod&lang=css"
import style1 from "./ClientTemplate.vue?vue&type=style&index=1&id=2998c0b5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.9_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.10_lodas_njaui5j7ox37wcdpl467s5x2le/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2998c0b5",
  null
  
)

export default component.exports