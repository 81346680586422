<template>
  <v-layout wrap class="holder-list-form">
    <v-flex xs12>
      <v-card
        class="ml-1 grey lighten-4 pt-3 pb-3 pl-3"
        :class="localPolicyRoutetoMarket.length >= 1 ? 'pr-3 pb-5' : ''"
      >
        <div v-bar="{ useScrollbarPseudo: true }" class="route-to-market">
          <div>
            <v-flex pb-1>
              <h3 v-if="localPolicyRoutetoMarket.length === 0" class="text-xs-center grey--text">No Data Available</h3>
            </v-flex>
            <v-flex
              v-for="(item, ItemIndex) in localPolicyRoutetoMarket"
              :key="item.dummyId"
              xs12
              :class="
                localPolicyRoutetoMarket.length === 0
                  ? 'pr-0'
                  : !isUserRoleClientManager
                  ? 'route-to-market-repeat'
                  : 'pr-3'
              "
            >
              <v-layout>
                <v-flex class="pr-2" xs6>
                  <v-select
                    v-model="item.routeToMarket"
                    :items="policyRoutetoMarketItems"
                    label="Route to market"
                    item-value="routeToMarket"
                    item-text="routeToMarket"
                    :readonly="isUserRoleClientManager"
                    class="routeToMarketTemp"
                    :loading="routeToMarketRunning"
                    @change="policyRoutetoMarkertChanged($event, ItemIndex, item)"
                  ></v-select>
                </v-flex>
                <v-flex xs6 class="pl-2">
                  <v-select
                    v-model="item.policyRouteToMarketId"
                    :items="item.productItems"
                    label="Product code"
                    item-value="policyRouteToMarketId"
                    item-text="productCode"
                    :readonly="isUserRoleClientManager"
                    class="productCode"
                    :loading="item.isLoading"
                  ></v-select>
                </v-flex>
                <v-flex v-if="!isUserRoleClientManager">
                  <v-btn
                    flat
                    icon
                    color="secondary"
                    class="mt-3 emergency-delete-icon close-icon"
                    @click.prevent="removeProduct(ItemIndex)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </div>
        </div>
        <div v-if="!isUserRoleClientManager" class="add-btn-form">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn flat icon color="primary" class="mt-2 add-icon" v-on="on" @click="addAdditionalProduct">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>Add route to market</span>
          </v-tooltip>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Store from '@/store'
import PolicyRouteToMarketModel from '@/models/policy/PolicyRouteToMarketModel'
import storeGetters from '@/storeGetters'

@Component
export default class PolicyRouteToMarket extends Vue {
  public localPolicyRoutetoMarket: PolicyRouteToMarketModel[] = []
  @Prop() private policyRoutetoMarket: PolicyRouteToMarketModel[]

  private policyRoutetoMarketItems: PolicyRouteToMarketModel[] = []
  private routeToMarketRunning = false

  public bindPolicyRouteToMarketListByInsurerId(insurerId: number, policyId: number) {
    const self = this
    self.routeToMarketRunning = true
    const policyRouteToMarketList: PolicyRouteToMarketModel[] = storeGetters.getPolicyRouteToMarketList()
    let routeToMarketItems: any = Store.Instance.state.PolicyRouteToMarketList.map((obj) => obj.routeToMarket)
    routeToMarketItems = routeToMarketItems.filter((v, i) => routeToMarketItems.indexOf(v) === i)
    self.policyRoutetoMarketItems = routeToMarketItems
    self.routeToMarketRunning = false
    self.bindPolicyRoutetoMarket()
  }

  @Watch('policyRoutetoMarket')
  private policyRoutetoMarketChange() {
    this.localPolicyRoutetoMarket = JSON.parse(JSON.stringify(this.policyRoutetoMarket))
  }

  private policyRoutetoMarkertChanged(policyRouteToMarket: string, index: number, item: PolicyRouteToMarketModel) {
    const self = this
    item.isLoading = true
    const productItemList: PolicyRouteToMarketModel[] = Store.Instance.state.PolicyRouteToMarketList.filter(
      (e: PolicyRouteToMarketModel) => e.routeToMarket === policyRouteToMarket && e.policyId === null
    )
    Vue.set(self.localPolicyRoutetoMarket[index], 'productItems', productItemList)
    item.isLoading = false
  }

  private addAdditionalProduct(): void {
    const self = this
    let id = 1
    if (self.localPolicyRoutetoMarket.length > 0) {
      id =
        1 +
        Math.max.apply(
          Math,
          self.localPolicyRoutetoMarket.map((o: any) => {
            return o.dummyId
          })
        )
    }

    const ph: PolicyRouteToMarketModel = new PolicyRouteToMarketModel()
    ph.dummyId = id
    self.localPolicyRoutetoMarket.push(ph)
    // scroll to down on addition of product
    setTimeout(() => {
      const containerForRouteToMarket: any = this.$el.querySelector('.route-to-market .vb-content')
      containerForRouteToMarket.scrollTop =
        containerForRouteToMarket.scrollHeight - containerForRouteToMarket.offsetHeight
    }, 0)
  }

  private removeProduct(index: number): void {
    this.localPolicyRoutetoMarket.splice(index, 1)
  }

  private bindPolicyRoutetoMarket() {
    const self = this
    let id = 0

    if (self.localPolicyRoutetoMarket && self.localPolicyRoutetoMarket.length > 0) {
      self.localPolicyRoutetoMarket.forEach((element: PolicyRouteToMarketModel) => {
        element.dummyId = ++id
        element.isLoading = true
        const productItemList: PolicyRouteToMarketModel[] = Store.Instance.state.PolicyRouteToMarketList.filter(
          (e: PolicyRouteToMarketModel) =>
            element.routeToMarket === e.routeToMarket && (e.policyId === null || e.policyId === element.policyId)
        )
        Vue.set(element, 'productItems', productItemList)
        element.isLoading = false
      })
    } else {
      self.localPolicyRoutetoMarket = [new PolicyRouteToMarketModel()]
      self.localPolicyRoutetoMarket[0].dummyId = ++id
    }
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }
}
</script>

<style>
.route-to-market .vb-content {
  max-height: 365px;
}
.holder-list-form {
  position: relative;
}
.holder-list-form .add-btn-form {
  position: absolute;
  right: 15px;
  bottom: 0px;
}
.route-to-market-repeat {
  position: relative;
  padding-right: 55px;
}
.emergency-delete-icon {
  position: absolute !important;
  right: 0;
  top: -4px;
  cursor: pointer;
}
</style>
