<template>
  <div
    v-if="showSearchbar()"
    :class="{
      'header-search': parentComponent === 'App',
    }"
  >
    <div class="grow relative">
      <v-autocomplete
        ref="jobHeaderSearch"
        v-model="selectedSearchId"
        :items="searchItems"
        flat
        :solo-inverted="parentComponent == 'App' ? true : false"
        prepend-inner-icon="search"
        :append-icon="onSearchInputFocus ? 'close' : ''"
        :loading="searchRunning"
        browser-autocomplete="off"
        :search-input.sync="searchValue"
        item-text="displayValue"
        item-value="id"
        maxlength="50"
        :filter="() => true"
        @click:append="onClearClick"
        @focus="onFocus"
        @blur="onBlur"
      >
        <template slot="item" slot-scope="data">
          <v-list-tile-content>
            <v-list-tile-title v-html="sanitize(highlightPhrase(searchValue, data.item.displayValue))" />
          </v-list-tile-content>
        </template>
      </v-autocomplete>

      <v-btn icon flat tabindex="-1" class="search-loader" :loading="searchRunning" color="grey" />
    </div>

    <div v-if="showMultiSearchDropdown && !isUserRoleContractor" class="search-category">
      <v-autocomplete
        v-model="type"
        class="mx-0"
        flat
        :items="getSearchTypes"
        item-text="label"
        item-value="type"
        hide-no-data
        hide-details
        label="Search by"
        solo-inverted
      />
    </div>
  </div>
</template>

<script lang="ts">
import JobController from '@/api/jobController'
import eventBus from '@/common/bus'
import { highlightPhrase } from '@/common/shared'
import JobSearchIndexModel from '@/models/JobSearchIndexModel'
import store from '@/store'
import { sanitize } from 'dompurify'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SearchType } from '@/common/enums'
import JobsController, { SearchFilter } from '@/api/jobs-controller'

@Component
export default class SimplifiSearch extends Vue {
  @Prop() public isClearSearch: boolean
  @Prop() public parentComponent: string
  @Prop() public SISearch: boolean

  private highlightPhrase = highlightPhrase
  private isJobSelected = false
  private onSearchInputFocus = false
  private preSearchValue = ''
  private sanitize = sanitize
  private type = SearchType.Everywhere
  private searchItems: JobSearchIndexModel[] = []
  private searchRunning = false
  private searchValue = ''
  private selectedSearchId = ''
  private waitForMoreInputTimeoutHandle: number | null = null

  public mounted() {
    // set searchItems and displayValue for contractorJobs.
    eventBus.$on('filteredContractorJobItems', (items: any) => {
      this.searchRunning = false
      if (items && items.length > 0) {
        this.setSearchItems(items)
      }
    })

    // set searchItems and displayValue for clientJobs.
    eventBus.$on('filteredClientJobItems', (items: any) => {
      this.searchRunning = false
      if (items && items.length > 0) {
        this.setSearchItems(items)
      }
    })

    // clear search input when new job loaded
    eventBus.$on('clearSearchInput', (jobId: string, isClearSearchInput: boolean) => {
      if (
        (jobId !== '' && this.selectedSearchId !== '' && !this.selectedSearchId.includes(jobId)) ||
        isClearSearchInput
      ) {
        this.onClearSearchInput()
      }
    })

    if (this.isUserRoleContractor) {
      this.type = SearchType.ContractorJobs
    } else {
      this.type = SearchType.Everywhere
    }
  }

  private get showMultiSearchDropdown(): boolean {
    return !!this.$route.meta?.searchType
  }

  private get isUserRoleContractor(): boolean {
    return store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  @Watch('isUserRoleContractor')
  private isUserRoleContractorChange() {
    if (this.isUserRoleContractor === true) {
      this.type = SearchType.ContractorJobs
    }
  }

  @Watch('isClearSearch')
  private isClearSearchChange() {
    if (this.isClearSearch === true) {
      this.onClearSearchInput()
    }
    this.$emit('update:isClearSearch', false)
  }

  private onClearSearchInput() {
    this.onSearchInputFocus = false
    this.searchItems = []
    this.searchValue = ''
    this.selectedSearchId = ''
    this.searchRunning = false
  }

  private showSearchbar() {
    return this.isUserRoleContractor ? this.$route.meta?.showSearchBar : true
  }

  private get getSearchTypes() {
    const types: SearchType[] = [SearchType.Everywhere]
    if (this.$route.meta?.searchType) {
      types.push(this.$route.meta.searchType)
    }

    return types.map((type) => ({
      type,
      label: (type.match(/[A-Z][a-z]+/g) || [type]).join(' '),
    }))
  }

  private setSearchItems(items: JobSearchIndexModel[]) {
    this.searchItems = items
    this.searchItems.forEach((element) => {
      element.displayValue = [
        element.id,
        element.policyNumber || element.customerReference,
        element.customerName,
        element.addressLine,
        element.postcode,
      ]
        .filter(Boolean)
        .join(' - ')
    })
  }

  @Watch('searchValue')
  private searchValueChanged(newValue: string) {
    if (this.waitForMoreInputTimeoutHandle) {
      window.clearTimeout(this.waitForMoreInputTimeoutHandle)
    }

    if (newValue === '' || newValue === undefined || newValue === null) {
      return
    }
    newValue = newValue.trim()
    if (newValue === this.preSearchValue || newValue.replace(/[\W]/g, '').length < 3) {
      return
    }
    const jobHeaderSearch = this.$refs.jobHeaderSearch as any
    if (jobHeaderSearch.selectedItem && jobHeaderSearch.selectedItem.displayValue === newValue) {
      this.searchValue = newValue
    }

    this.waitForMoreInputTimeoutHandle = window.setTimeout(() => {
      if (this.searchValue && !this.isJobSelected) {
        this.searchRunning = true

        const searchOptions: Partial<Record<SearchType, SearchFilter>> = {
          [SearchType.ContractorJobs]: {
            type: 'ContractorId',
            value: this.$store.getters['contractorModule/selectedContractorId'],
          },
          [SearchType.ClientJobs]: {
            type: 'ClientId',
            value: this.$store.getters['clientModule/selectedClientId'],
          },
        }

        const filters: (SearchFilter | undefined)[] = [searchOptions[this.type]]

        if (this.SISearch) {
          filters.push({ type: 'JobType', value: 'SI' })
        }

        JobsController.SearchJobs(this.searchValue, filters)
          .then((res: JobSearchIndexModel[]) => {
            this.preSearchValue = newValue
            this.setSearchItems(res)
          })
          .catch(() => {
            this.searchItems = []
          })
          .finally(() => {
            this.searchRunning = false
          })
      }
      this.isJobSelected = false
    }, 300)
  }

  @Watch('selectedSearchId')
  private searchItemSelected() {
    if (!this.selectedSearchId) {
      return
    }

    const item: JobSearchIndexModel | undefined = this.searchItems.find(
      (e) => e.displayValue.includes(this.selectedSearchId) || e.id === this.selectedSearchId
    )
    if (!item) {
      return
    }

    this.isJobSelected = true

    if (this.parentComponent === 'PhoneCallJobsAssign') {
      this.$emit('searchJobResult', item.id)
      return
    }

    this.searchRunning = true

    if (this.type === SearchType.ContractorJobs) {
      eventBus.$emit('searchContractorJob', item)
    } else if (this.type === SearchType.ClientJobs) {
      eventBus.$emit('searchClientJob', item)
    } else {
      if (!this.SISearch) {
        this.$router.push({
          name: 'job',
          params: {
            jobId: item.id,
          },
        })
      } else {
        JobController.GetJobHeader(item.id).then((res) => {
          if (res) {
            eventBus.$emit('siJobHeader', res)
          }
        })
      }
    }

    this.searchRunning = false
  }

  private resetSearchInput() {
    this.onSearchInputFocus = false
    this.searchItems = []
    this.searchValue = ''
    this.selectedSearchId = ''

    if (this.type === SearchType.ContractorJobs || this.type === SearchType.ClientJobs) {
      eventBus.$emit('resetContractorJobs')
    }

    if (this.parentComponent === 'PhoneCallJobsAssign') {
      this.$emit('closeSearchJobResult')
    }

    if (this.SISearch) {
      eventBus.$emit('siSearchClear')
    }
  }

  private onFocus() {
    this.onSearchInputFocus = true
  }

  private onBlur() {
    if (this.selectedSearchId === '') {
      this.onSearchInputFocus = false
      this.searchItems = []
    }
  }

  private onClearClick() {
    this.resetSearchInput()
  }
}
</script>

<style scoped>
.header-search {
  display: flex;
  justify-content: end;
  gap: 1px;
  flex-grow: 1;
  transition: all 0.2s linear;
  position: relative;
}
.header-search >>> .v-autocomplete {
  border-radius: 2px 0px 0px 2px;
}
.header-search >>> .v-input__control > .v-input__slot {
  margin-bottom: 0px;
}

.search-loader {
  position: absolute;
  pointer-events: none;
  right: 45px;
  top: 0px;
}

.search-category {
  width: 200px;
}
.search-category >>> .v-autocomplete {
  border-radius: 0px 2px 2px 0px;
}
</style>
