<template>
  <div>
    <!-- Grid of templates -->
    <div>
      <v-container class="grid-list-lg fluid">
        <v-layout v-if="clientTemplates" wrap>
          <v-flex v-for="(item, index) in clientTemplates" :key="index" xl2 lg3 md4 sm6>
            <div class="inner-content edit-template-btn" @click="onEditTemplate(item)">
              <img v-if="item.logoURL" :src="item.logoURL" />
              <img v-else class="default-img" src="img/default-logo.png" />
            </div>
            <div class="pa-2 text-center grey--text text--darken-1">
              <span class="portal-name">{{ item.displayName }}</span>
            </div>
          </v-flex>
          <v-flex v-if="!isUserRoleClientManager" xl2 lg3 md4 sm6>
            <div class="inner-content" @click="addNewTemplate()">
              <v-icon class="secondary--text">add</v-icon>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div class="make-template px-4 py-3" :class="editTemplate ? 'add-newtempalte' : ''">
      <v-container class="grid-list-lg fluid pa-0">
        <v-layout>
          <!-- Template preview -->
          <div class="pt-3 pb-3 preview-content">
            <div class="mobile-section-content">
              <span class="back-mobile"></span>
              <div class="url-bar" @click="openStagingInNewTab()">
                <span class="custom-text-filed">{{ previewUrl }}</span>
              </div>
              <div v-bar="{ useScrollbarPseudo: true }" style="height: 100%">
                <v-layout>
                  <v-flex xs12 px-3 py-0 class="scroll-content" style="height: 100%; overflow: hidden">
                    <iframe
                      v-if="previewUrl"
                      id="iframeClientTemplate"
                      class="preview-template"
                      :src="previewUrl"
                      @load="frame = $event.target"
                    />
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </div>

          <div class="portal-form">
            <v-expansion-panel v-model="templateDetailsPanel">
              <v-expansion-panel-content>
                <div slot="header">
                  <h3>Template Detail</h3>
                </div>
                <v-divider></v-divider>
                <v-card>
                  <v-card-text class="template-form px-4">
                    <v-layout class="header-section" wrap>
                      <!-- General details -->
                      <v-flex lg4 md12>
                        <v-text-field v-model="clientTemplateModel.displayName" label="Display Name"></v-text-field>
                      </v-flex>
                      <v-flex lg4 md6>
                        <div class="sub-domain">
                          <v-text-field
                            v-model="clientTemplateModel.subDomain"
                            label="SubDomain"
                            disabled
                          ></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex lg4 md6>
                        <!-- Spacer to push next component on to new line -->
                      </v-flex>

                      <!-- Policy logo details -->
                      <v-flex xl2 lg3 md6>
                        <v-checkbox
                          v-model="allowToSelectLogo"
                          :label="`Add Policy Logo`"
                          class="mt-3"
                          hide-details
                          color="primary"
                          @change="onChangeAllowToSelectLogo"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex
                        xl5
                        lg5
                        md6
                        class="text-xs-center icon-validation"
                        :class="!allowToSelectLogo ? 'show' : 'hide'"
                      >
                        <v-text-field
                          v-model="imageName"
                          label="Logo"
                          required
                          placeholder="Select logo"
                          :append-icon="imageName ? 'close' : ''"
                          @click:append="removeImage"
                          prepend-icon="attach_file"
                          :hide-details="true"
                          @click="pickFile"
                        ></v-text-field>
                        <input
                          ref="image"
                          v-validate="{
                            rules: {
                              required: isLogoRequired,
                              image: true,
                              size: LogoSize,
                              mimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
                              ext: ['jpg', 'jpeg', 'png', 'gif'],
                            },
                          }"
                          type="file"
                          style="display: none"
                          accept="image/*"
                          name="logo"
                          :data-vv-scope="validationScope"
                          @change="onFilePicked"
                        />
                        <div class="input-group__details">
                          <div class="input-group__messages input-group__error input-group--error error--text">
                            <span v-if="imageValidationMessage(errors)">{{ logoErrorMsg }}</span>
                          </div>
                        </div>
                      </v-flex>
                      <v-flex
                        xl5
                        lg5
                        md6
                        class="text-xs-center icon-validation policy-logo-client"
                        :class="allowToSelectLogo ? 'show' : 'hide'"
                      >
                        <v-select
                          v-model="selectedPolicyLogo"
                          v-validate="{ rules: { required: isPolicyLogoUrlRequired } }"
                          :items="existingPolicyLogoUrls"
                          label="Policy Logo"
                          return-object
                          :menu-props="{ maxHeight: 'auto' }"
                          :hide="!allowToSelectLogo"
                          class="policy-logo-clienttemplate"
                          required
                          :data-vv-scope="validationScope"
                          data-vv-name="Policy Logo"
                          :error-messages="errors.collect('Policy Logo')"
                          @change="onSelectLogoFromExistingUrls"
                        >
                          <!-- FIX THIS MAYBE -->
                          <template slot="selection" slot-scope="data">{{ data.item.policyName }}</template>
                          <template slot="item" slot-scope="data">
                            <template>
                              <span class="policy-logo-select">
                                <img v-if="data.item.fileURL" :src="data.item.fileURL" />
                              </span>
                              <v-list-tile-content>
                                <v-list-tile-title>{{ data.item.policyName }}</v-list-tile-title>
                              </v-list-tile-content>
                            </template>
                          </template>
                        </v-select>
                      </v-flex>
                      <v-flex xl5 lg4 md6 class="text-xs-center icon-validation">
                        <v-text-field
                          v-model="favIconImageName"
                          label="Favicon"
                          placeholder="Select favicon"
                          :append-icon="
                            favIconImageName || favIconImageUrl || clientTemplateModel.favIconURL ? 'close' : ''
                          "
                          @click:append="removeFavIconImage"
                          prepend-icon="attach_file"
                          :hide-details="true"
                          @click="pickFavIconFile"
                        ></v-text-field>
                        <input
                          ref="favIconImage"
                          v-validate="{
                            rules: {
                              required: false,
                              image: true,
                              size: 10,
                              mimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
                              ext: ['jpg', 'jpeg', 'png', 'gif'],
                            },
                          }"
                          type="file"
                          style="display: none"
                          accept="image/*"
                          name="favicon"
                          :data-vv-scope="validationScope"
                          @change="onFavIconFilePicked"
                        />
                        <div class="input-group__details">
                          <div class="input-group__messages input-group__error input-group--error error--text">
                            <span v-if="favIconImageValidationMessage(errors)">{{ favIconErrorMsg }}</span>
                          </div>
                        </div>
                      </v-flex>

                      <!-- Color selection -->
                      <v-flex lg4 md6>
                        <div class="color-input">
                          <v-text-field label="Primary" :value="primaryColor.hex"></v-text-field>
                          <v-menu left offset-y content-class="color-picker-menu" :close-on-content-click="false">
                            <template #activator="{ on }">
                              <span class="show-color" :style="'background:' + primaryColor.hex" v-on="on"></span>
                            </template>
                            <v-list>
                              <v-list-tile>
                                <v-list-tile-title>
                                  <chrome-picker v-model="primaryColor" data-testid="primaryColorPicker" />
                                </v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex lg4 md6>
                        <div class="color-input">
                          <v-text-field label="Secondary" :value="secondaryColor.hex"></v-text-field>
                          <v-menu left offset-y content-class="color-picker-menu" :close-on-content-click="false">
                            <template #activator="{ on }">
                              <span class="show-color" :style="'background:' + secondaryColor.hex" v-on="on"></span>
                            </template>
                            <v-list>
                              <v-list-tile>
                                <v-list-tile-title>
                                  <chrome-picker v-model="secondaryColor" data-testid="secondaryColorPicker" />
                                </v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex lg4 md6>
                        <div class="color-input">
                          <v-text-field label="Complementary" :value="complementaryColor.hex"></v-text-field>
                          <v-menu left offset-y content-class="color-picker-menu" :close-on-content-click="false">
                            <template #activator="{ on }">
                              <span class="show-color" :style="'background:' + complementaryColor.hex" v-on="on"></span>
                            </template>
                            <v-list>
                              <v-list-tile>
                                <v-list-tile-title>
                                  <chrome-picker v-model="complementaryColor" data-testid="complementaryColorPicker" />
                                </v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex lg4 md6>
                        <div class="color-input">
                          <v-text-field label="Error Color" :value="errorColor.hex"></v-text-field>
                          <v-menu left offset-y content-class="color-picker-menu" :close-on-content-click="false">
                            <template #activator="{ on }">
                              <span
                                class="show-color error-color"
                                :style="'background:' + errorColor.hex"
                                v-on="on"
                              ></span>
                            </template>
                            <v-list>
                              <v-list-tile>
                                <v-list-tile-title>
                                  <chrome-picker v-model="errorColor" data-testid="errorColorPicker" />
                                </v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex lg4 md6>
                        <div class="color-input">
                          <v-text-field label="Input Text Color" :value="inputTextColor.hex"></v-text-field>
                          <v-menu left offset-y content-class="color-picker-menu" :close-on-content-click="false">
                            <template #activator="{ on }">
                              <span
                                class="show-color inputText-color"
                                :style="'background:' + inputTextColor.hex"
                                v-on="on"
                              ></span>
                            </template>
                            <v-list>
                              <v-list-tile>
                                <v-list-tile-title>
                                  <chrome-picker v-model="inputTextColor" data-testid="inputTextColorPicker" />
                                </v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-flex>

                      <!-- Step headers -->
                      <v-flex xs12>
                        <v-expansion-panel>
                          <v-expansion-panel-content class="expansion-header">
                            <template #header>
                              <h4 class="text-uppercase">Step Headers</h4>
                            </template>
                            <TemplateHeaders
                              :ct="clientTemplateModel"
                              :validation-scope="validationScope"
                              @updateHeader="clientTemplateModel[`${$event.field}Step${$event.step}`] = $event.value"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-flex>

                      <!-- Emergency types -->
                      <v-flex v-if="emergencyTypes.length > 0" xs12>
                        <v-autocomplete
                          v-model="topEmergencies"
                          :items="allEmergencyTypes"
                          multiple
                          chips
                          return-object
                          item-text="description"
                          item-value="emergencyId"
                          persistent-hint
                          label="Select Top Emergency"
                          hide-details
                          class="hide-option"
                          @change="onChangeEmergencyType(topEmergencies, $event)"
                        >
                          <template slot="item" slot-scope="data">
                            <v-list-tile>
                              <v-list-tile-action>
                                <v-checkbox
                                  v-model="emergencyCheckboxes[allEmergencyTypes.indexOf(data.item)].checked"
                                  color="primary"
                                ></v-checkbox>
                              </v-list-tile-action>
                              <v-list-tile-content>
                                <v-list-tile-title
                                  :class="data.item.isDeleted ? 'deleted-item' : ''"
                                  v-html="data.item.description"
                                ></v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </template>
                        </v-autocomplete>
                      </v-flex>

                      <!-- Fallback policy -->
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="selectedFallbackStarredPolicy"
                          v-validate="'required'"
                          label="Fallback Starred Policy"
                          required
                          :items="getStarredPolicy"
                          item-text="policyBasicDetail.name"
                          item-value="policyBasicDetail.policyId"
                          :data-vv-scope="validationScope"
                          data-vv-name="Fallback Starred Policy"
                          :error-messages="errors.collect('Fallback Starred Policy')"
                        >
                          <template slot="item" slot-scope="data">
                            <v-list-tile>
                              <v-list-tile-content>
                                <v-list-tile-title
                                  :class="data.item.policyBasicDetail.isDeleted ? 'deleted-item' : ''"
                                  v-html="data.item.policyBasicDetail.name"
                                ></v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </template>
                        </v-autocomplete>
                      </v-flex>

                      <!-- Policy Schedule -->
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="policySchedules"
                          v-validate="'required'"
                          :items="getPolicyList"
                          :loading="policySearchRunning"
                          label="Select Policy Schedule"
                          multiple
                          chips
                          return-object
                          item-text="policyBasicDetail.name"
                          item-value="policyBasicDetail.policyId"
                          required
                          :data-vv-scope="validationScope"
                          data-vv-name="Select Policy Schedule"
                          :error-messages="errors.collect('Select Policy Schedule')"
                        >
                          <template slot="item" slot-scope="data">
                            <v-list-tile>
                              <v-list-tile-action>
                                <v-checkbox
                                  v-model="policyCheckboxes[policyList.indexOf(data.item)].checked"
                                  color="primary"
                                ></v-checkbox>
                              </v-list-tile-action>
                              <v-list-tile-content>
                                <v-list-tile-title
                                  :class="data.item.policyBasicDetail.isDeleted ? 'deleted-item' : ''"
                                  v-html="data.item.policyBasicDetail.name"
                                ></v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </template>
                        </v-autocomplete>
                      </v-flex>

                      <v-flex xs12>
                        <v-layout wrap>
                          <!-- Cookie/Policy/Legal Notice -->
                          <v-flex v-for="notice in Object.keys(noticeConfig)" :key="notice">
                            <v-btn color="primary" @click="displayNoticeDialog = notice">
                              {{ noticeConfig[notice].buttonText }}
                            </v-btn>
                          </v-flex>

                          <!-- Cookie options -->
                          <v-flex xs12>
                            <CookieSelection
                              ref="cookieSelection"
                              :validation-scope="validationScope"
                              :client-template="clientTemplateModel"
                            />
                          </v-flex>

                          <v-flex xs12>
                            <v-flex md6>
                              <v-select
                                v-model="clientTemplateModel.status"
                                v-validate="'required'"
                                label="Template Status"
                                :items="templateStatuses"
                                required
                                :data-vv-scope="validationScope"
                                data-vv-name="TemplateStatus"
                                :error-messages="errors.collect('TemplateStatus')"
                                @change="resetHoldingMessage"
                              ></v-select>
                            </v-flex>
                            <v-flex v-if="clientTemplateModel.status === templateStatusEnum.TemporaryOffline" md6>
                              <v-textarea
                                v-model="clientTemplateModel.holdingPageMessage"
                                v-validate="{
                                  rules: {
                                    required: clientTemplateModel.status === templateStatusEnum.TemporaryOffline,
                                  },
                                }"
                                label="Holding Page Message"
                                :data-vv-scope="validationScope"
                                data-vv-name="HoldingPageMessage"
                                :error-messages="errors.collect('HoldingPageMessage')"
                              ></v-textarea>
                            </v-flex>
                          </v-flex>

                          <!-- Brand specific widget -->
                          <v-flex md12 lg4 xl3>
                            <v-checkbox
                              v-model="clientTemplateModel.dashboardWidgetEnabled"
                              :label="`Brand Specific Widget`"
                              class="mt-0 pt-0"
                              hide-details
                              color="primary"
                              @change="!clientTemplateModel.dashboardWidgetEnabled"
                            ></v-checkbox>
                          </v-flex>

                          <!-- Brand specific widegt preview -->
                          <v-flex tile-preview md6 lg4 xl3>
                            <div :class="clientTemplateModel.dashboardWidgetEnabled ? 'show' : 'hide'">
                              <span class="white pa-2 elevation-2 insurer-preview-window">
                                <v-card
                                  class="elevation-0 white--text pa-0"
                                  :style="
                                    'background:' +
                                    (dashboardWidgetColor.hex ? dashboardWidgetColor.hex : primaryColor.hex)
                                  "
                                >
                                  <v-card-text class="pa-0">
                                    <h2 class="pa-2">Insurer Name</h2>
                                    <div class="day-counts-section">
                                      <span class="text-xs-center lighten-1">
                                        <v-tooltip bottom>
                                          <template #activator="{ on }">
                                            <i class="icon" v-on="on">
                                              <v-icon color="white">call</v-icon>
                                            </i>
                                          </template>
                                          <span>Total number of calls</span>
                                        </v-tooltip>
                                        <span class="count">0</span>
                                      </span>
                                      <span class="text-xs-center lighten-1">
                                        <v-tooltip bottom>
                                          <template #activator="{ on }">
                                            <i class="icon" v-on="on">
                                              <v-icon color="white">work</v-icon>
                                            </i>
                                          </template>
                                          <span>Total number of jobs logged</span>
                                        </v-tooltip>
                                        <span class="count">0</span>
                                      </span>
                                      <span class="text-xs-center lighten-1">
                                        <v-tooltip bottom>
                                          <template #activator="{ on }">
                                            <i class="icon" v-on="on">
                                              <v-icon color="white">build</v-icon>
                                            </i>
                                          </template>
                                          <span>Total number of jobs deployed</span>
                                        </v-tooltip>
                                        <span class="count">0</span>
                                      </span>
                                    </div>
                                    <div class="num-call-announcing sys-call-info graph-image pt-2">
                                      <img src="img/insurer-graph.png" />
                                    </div>
                                    <div class="num-call-announcing sys-call-info pa-2">
                                      <label for>Calls in announcing</label>
                                      <span class="text-xs-right sys-call-content">
                                        <span class="num">0</span>
                                      </span>
                                      <span class="bar-chart">
                                        <img src="img/bar-chart.png" />
                                      </span>
                                    </div>
                                    <div class="new-call sys-call-info light-background lighten-3 pa-2">
                                      <label for>New</label>
                                      <span class="text-xs-right sys-call-content">
                                        <span class="num">0</span>
                                        <span class="time">0:00</span>
                                      </span>
                                      <span class="bar-chart">
                                        <img src="img/bar-chart.png" />
                                      </span>
                                    </div>
                                    <div class="existing-call sys-call-info pa-2">
                                      <label for>Existing</label>
                                      <span class="text-xs-right sys-call-content">
                                        <span class="num">0</span>
                                        <span class="time">0:00</span>
                                      </span>
                                      <span class="bar-chart">
                                        <img src="img/bar-chart.png" />
                                      </span>
                                    </div>
                                  </v-card-text>
                                </v-card>
                              </span>
                            </div>
                          </v-flex>

                          <!-- Brand specific color select -->
                          <v-flex md6 lg4 xl3>
                            <div
                              class="color-input"
                              :class="clientTemplateModel.dashboardWidgetEnabled ? 'show' : 'hide'"
                            >
                              <v-text-field
                                label="Select Tile Color"
                                :value="dashboardWidgetColor.hex ? dashboardWidgetColor.hex : primaryColor.hex"
                              ></v-text-field>
                              <v-menu left offset-y content-class="color-picker-menu" :close-on-content-click="false">
                                <template #activator="{ on }">
                                  <span
                                    class="show-color"
                                    :style="
                                      'background:' +
                                      (dashboardWidgetColor.hex ? dashboardWidgetColor.hex : primaryColor.hex)
                                    "
                                    v-on="on"
                                  ></span>
                                </template>
                                <v-list>
                                  <v-list-tile>
                                    <v-list-tile-title>
                                      <chrome-picker
                                        v-model="dashboardWidgetColor"
                                        data-testid="dashboardWidgetColorPicker"
                                      />
                                    </v-list-tile-title>
                                  </v-list-tile>
                                </v-list>
                              </v-menu>
                            </div>
                          </v-flex>

                          <v-flex lg12 text-xs-right>
                            <v-btn flat color="primary" @click="cancel">Cancel</v-btn>
                            <v-btn
                              :disabled="isSavingChanges"
                              :loading="isSavingChanges"
                              color="primary"
                              class="mr-0"
                              @click="submit"
                            >
                              Update
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>

              <!-- Template versions -->
              <v-expansion-panel-content>
                <div slot="header">
                  <h3>Template Versions</h3>
                </div>
                <v-divider />
                <v-data-table :items="historyItems" :headers="historyHeaders" class="gridView release-history-items">
                  <template slot="items" slot-scope="props">
                    <td>
                      <div v-if="props.item.status == 'Pending'">
                        <v-chip small color="blue" text-color="white">Pending</v-chip>
                      </div>
                      <div v-else-if="props.item.status == 'Error'">
                        <v-chip small color="red" text-color="white">Failed</v-chip>
                      </div>
                      <div v-else-if="props.item.status == 'Success'">
                        <v-chip
                          v-if="props.item.id == clientTemplateModel.stagingPortalRequestId"
                          small
                          color="orange"
                          text-color="white"
                        >
                          Staging
                        </v-chip>
                        <v-chip
                          v-if="props.item.id == clientTemplateModel.productionPortalRequestId"
                          small
                          color="green"
                          text-color="white"
                        >
                          Live
                        </v-chip>
                        <v-btn
                          v-if="!isUserRoleClientManager && props.item.id != clientTemplateModel.stagingPortalRequestId"
                          small
                          color="primary"
                          class="deploy-staging"
                          @click="deployTemplateVersion(props.item, 'Staging')"
                        >
                          Deploy Staging
                        </v-btn>
                        <v-btn
                          v-if="
                            !isUserRoleClientManager &&
                            props.item.id == clientTemplateModel.stagingPortalRequestId &&
                            props.item.id != clientTemplateModel.productionPortalRequestId
                          "
                          small
                          color="secondary"
                          @click="deployTemplateVersion(props.item, 'Production')"
                        >
                          Deploy Live
                        </v-btn>
                      </div>
                    </td>
                    <td v-if="props.item.createdAt">
                      {{ props.item.createdAt.format('Do MMM YYYY') }}
                      <br />
                      {{ props.item.createdAt.format('HH:mm:ss') }}
                    </td>
                    <td>{{ props.item.createdByName }}</td>
                    <td>{{ props.item.createdByNote }}</td>
                  </template>
                </v-data-table>
                <div class="text-xs-right pb-2">
                  <v-btn color="primary" @click="showDeploymentDialog = true">Deployment History</v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </div>
        </v-layout>
      </v-container>
    </div>

    <!-- Deployment modal -->
    <v-dialog
      v-model="showDeploymentDialog"
      content-class="v-dialog--scrollable"
      max-width="1000px"
      @keydown.esc="showDeploymentDialog = false"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Deployment History</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="showDeploymentDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-3 scroll-content-dialog">
          <v-data-table :items="deploymentItems" :headers="deploymentHeaders" class="gridView">
            <template slot="items" slot-scope="props">
              <td>
                <div v-if="props.item.status == 'Pending'">
                  <v-chip small color="blue" text-color="white">Pending</v-chip>
                </div>
                <div v-else-if="props.item.status == 'Error'">
                  <v-chip small color="red" text-color="white">Failed</v-chip>
                </div>
                <div v-else-if="props.item.status == 'Success'">
                  <v-chip v-if="props.item.request.PublishToStagingPortal" small color="orange" text-color="white">
                    Staging
                  </v-chip>
                  <v-chip
                    v-if="props.item.request.PublishToProductionPortal"
                    small
                    color="green"
                    text-color="white"
                    width="92px"
                  >
                    Live
                  </v-chip>
                </div>
              </td>
              <td>
                {{ props.item.createdAt.format('Do MMM YYYY') }}
                ({{ props.item.createdAt.format('HH:mm:ss') }})
              </td>
              <td>{{ props.item.createdByName }}</td>
              <td>{{ props.item.status == 'Error' ? props.item.errorDetail : props.item.createdByNote }}</td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add new template modal -->
    <v-dialog
      v-model="showAddDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
      @keydown.esc="showAddDialog = false"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Create New Template</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="showAddDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-container grid-list-md class="pa-0">
            <v-layout wrap>
              <v-flex lg12>
                <v-text-field
                  v-model="clientTemplateModel.displayName"
                  v-validate="'required|max:100'"
                  label="Display Name"
                  required
                  :disabled="newOrUpdateSubmitted"
                  maxlength="100"
                  data-vv-scope="frmAddNew"
                  data-vv-name="DisplayName"
                  :error-messages="errors.collect('DisplayName')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="clientTemplateModel.subDomain"
                  v-validate="'required|max:50|regex:^[a-z0-9]{1}[a-z0-9-]*[a-z0-9]{1}$'"
                  label="Portal Link"
                  required
                  :disabled="newOrUpdateSubmitted"
                  maxlength="50"
                  :prefix="portalDomainPrefix"
                  data-vv-scope="frmAddNew"
                  data-vv-name="SubDomain"
                  :error-messages="errors.collect('SubDomain')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  v-model="clientTemplateModel.fallBackStarPolicy"
                  v-validate="'required'"
                  label="Fallback Starred Policy"
                  required
                  :disabled="newOrUpdateSubmitted"
                  :items="getStarredPolicy"
                  item-text="policyBasicDetail.name"
                  item-value="policyBasicDetail.policyId"
                  data-vv-scope="frmAddNew"
                  data-vv-name="Fallback Starred Policy"
                  :error-messages="errors.collect('Fallback Starred Policy')"
                ></v-autocomplete>
              </v-flex>
              <v-flex v-if="newOrUpdateSubmitted" xs12>
                <v-progress-linear :indeterminate="true"></v-progress-linear>
              </v-flex>
              <v-flex v-if="newOrUpdateStatusMsg" xs12>
                <div :class="{ nsmError: newOrUpdateStatusMsgIsError }">{{ newOrUpdateStatusMsg }}</div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn :disabled="newOrUpdateSubmitted" color="primary" flat @click="newOrUpdateCancelled()">Cancel</v-btn>
          <v-btn :disabled="newOrUpdateSubmitted" color="primary" @click="submitNewTemplate()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Update template modal -->
    <v-dialog
      v-model="showUpdateDialog"
      persistent
      max-width="600px"
      content-class="v-dialog--scrollable"
      @keydown.esc="showUpdateDialog = false"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Update Template</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="showUpdateDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-container grid-list-md class="pa-0">
            <v-layout wrap>
              <v-flex v-if="newOrUpdateShowNote" xs12>
                <v-textarea
                  v-model="updateNote"
                  v-validate="'required|max:10000'"
                  label="Update Note"
                  placeholder="Please enter a description of your update"
                  required
                  :disabled="newOrUpdateSubmitted"
                  data-vv-scope="frmUpdateModel"
                  data-vv-name="UpdateNote"
                  :error-messages="errors.collect('UpdateNote')"
                ></v-textarea>
              </v-flex>
              <v-flex v-if="newOrUpdateSubmitted" xs12>
                <v-progress-linear :indeterminate="true"></v-progress-linear>
              </v-flex>
              <v-flex v-if="newOrUpdateStatusMsg" xs12>
                <div :class="{ nsmError: newOrUpdateStatusMsgIsError }">{{ newOrUpdateStatusMsg }}</div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="newOrUpdateSubmitted" color="primary" flat @click="newOrUpdateCancelled()">Cancel</v-btn>
          <v-btn :disabled="newOrUpdateSubmitted" color="primary" @click="submitUpdateTemplate()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit notice modal -->
    <v-dialog
      :value="displayNoticeDialog"
      persistent
      max-width="700px"
      content-class="v-dialog--scrollable"
      @keydown.esc="displayNoticeDialog = null"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>{{ displayNoticeConfig.header }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="displayNoticeDialog = null">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-container grid-list-md class="pa-0">
            <v-layout wrap>
              <v-flex xs12>
                <froala
                  id="froala"
                  v-model="displayNoticeText"
                  v-validate="'required'"
                  froala
                  data-vv-scope="templateformRef"
                  class="required"
                  :required="true"
                  :error-messages="ValidateEditor(displayNoticeConfig.validate)"
                  :data-vv-name="displayNoticeConfig.validate"
                  :config="froalaConfig"
                  :tag="'textarea'"
                />
                <div class="input-group__details">
                  <div class="input-group__messages input-group__error input-group--error error--text">
                    <span v-if="ValidateEditor(displayNoticeConfig.validate)">
                      {{ ValidateEditor(displayNoticeConfig.validate) }}
                    </span>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="displayNoticeDialog = null">Close</v-btn>
          <v-btn color="primary" class="mr-0" @click="updateNotice">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Provide } from 'vue-property-decorator'
import Store from '@/store'
import PolicyHolderController from '@/api/policyHolderController'
import JobController from '@/api/jobController'
import storeMutations from '@/storeMutations'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import storeGetters from '@/storeGetters'
import { Chrome } from 'vue-color'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import moment, { Moment, isMoment } from 'moment'
import DateTimePicker from '@/components/DateTimePicker.vue'
import PolicyModel from '@/models/policy/PolicyModel'
import Shared from '@/common/shared'
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import InsurerController, { ClientTemplateResponseCode } from '@/api/InsurerController'
import eventBus from '@/common/bus'
import { ICommandMessage, Settings } from '@/models/client/PortalSettings'
import EmergencyQA from '@/models/EmergencyQA'
import ClientTemplateReleaseModel from '@/models/client/ClientTemplateReleaseModel'
import ClientTemplateDeploymentModel, {
  PublishClientTemplateModel,
} from '@/models/client/ClientTemplateDeploymentModel'
import CreateNewClientTemplateRequestModel from '@/models/client/CreateNewClientTemplateRequestModel'
import UpdateClientTemplateRequestStatusModel from '@/models/client/UpdateClientTemplateRequestStatusModel'
import PublishClientTemplateRequestStatusModel from '@/models/client/PublishClientTemplateRequestStatusModel'
import UpdateClientTemplateRequest from '@/models/client/UpdateClientTemplateRequest'
import PublishClientTemplateRequestModel, {
  DeploymentEnvironment,
} from '@/models/client/PublishClientTemplateRequestModel'
import CookieSelection from '@/components/cookies/CookieSelection.vue'
import TemplateHeaders from '@/components/client/client-template/TemplateHeaders.vue'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import { AxiosError } from 'axios'
import { TemplateStatus } from '@/common/enums'

const noticeConfig = {
  privacy: {
    buttonText: 'Privacy Policy Details',
    header: 'Update Privacy Policy',
    validate: 'Privacy Policy Template',
    text: '',
    textKey: 'privacyPolicyText',
  },
  legal: {
    buttonText: 'Legal Notice Details',
    header: 'Update Legal Notice',
    validate: 'Legal Notice Template',
    text: '',
    textKey: 'legalNoticeText',
  },
  cookie: {
    buttonText: 'Cookie Notice Details',
    header: 'Update Cookie Notice',
    validate: 'Cookie Notice Template',
    text: '',
    textKey: 'cookieNoticeText',
  },
}

@Component({
  components: {
    'chrome-picker': Chrome,
    DateTimePicker,
    CookieSelection,
    TemplateHeaders,
  },
})
export default class ClientTemplate extends Vue {
  @Provide('validator') public validator = this.$validator

  @Prop() private selectedInsurerId: number
  @Prop() private polices: PolicyModel[]
  @Prop() private policySearchRunning: boolean
  @Prop() private templates: ClientTemplateModel[]
  private noticeConfig = noticeConfig
  private displayNoticeDialog: string | null = null
  private displayNoticeText = ''
  private showAddDialog = false
  private showUpdateDialog = false
  private updateNote = ''
  private newOrUpdateShowNote = false
  private newOrUpdateSubmitted = false
  private newOrUpdateStatusMsg = ''
  private newOrUpdateStatusMsgIsError = false
  private clientTemplateModel: ClientTemplateModel = new ClientTemplateModel()
  private Vibrant = require('node-vibrant')
  private imageName = ''
  private imageUrl = ''
  private logoErrorMsg = ''
  private primaryColor: any = { hex: this.defaultPrimaryColor }
  private secondaryColor: any = { hex: this.defaultSecondaryColor }
  private complementaryColor: any = { hex: this.defaultComplementaryColor }
  private dashboardWidgetColor: any = { hex: this.defaultPrimaryColor }
  private selectedImages: any = []
  private topEmergencies: EmergencyModel[] = []
  private isSavingChanges = false
  private allEmergencyTypes: EmergencyModel[] = []
  private policySchedules: PolicyModel[] = []
  private loader = true
  private editTemplate = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private favIconImageName = ''
  private favIconImageUrl = ''
  private favIconErrorMsg = ''
  private selectedFallbackStarredPolicy: number | null = null
  private isInsurerTileSelected = false
  private previewUrl: string | null = null
  private settings: Settings = new Settings()
  private frame: HTMLIFrameElement | null = null
  private config = ''
  private templateSitePrimaryColor = ''
  private templateSiteSecondaryColor = ''
  private templateSiteComplementaryColor = ''
  private templateSiteLogo = ''
  private templateSiteEmergencies: EmergencyModel[] = []
  private templateSitePolicySchedules: PolicyModel[] = []
  private templateSiteFallbackStarredPolicy = 0
  private allowToSelectLogo = false
  private existingPolicyLogoUrls: any[] = []
  private selectedPolicyLogo: any = null
  private tempLogoURL = ''
  private tempPolicyLogoUrl = ''
  private tempPrimaryColorFromFileUrl: any = { hex: this.defaultPrimaryColor }
  private tempSecondaryColorFromFileUrl: any = {
    hex: this.defaultSecondaryColor,
  }
  private tempPrimaryColorFromPolicyLogo: any = {
    hex: this.defaultPrimaryColor,
  }
  private tempSecondaryColorFromPolicyLogo: any = {
    hex: this.defaultSecondaryColor,
  }
  private historyHeaders: any[] = [
    { text: 'Deployment Status', value: 'id', sortable: false },
    { text: 'Created At (UTC)', value: 'createdAt', sortable: false },
    { text: 'Created By', value: 'createdByName', sortable: false },
    { text: 'Note', value: 'createdByNote', sortable: false },
  ]
  private historyItems: ClientTemplateReleaseModel[] = []
  private deploymentHeaders: any[] = [
    { text: 'Deployed To', value: 'id', sortable: false },
    { text: 'Deployed At (UTC)', value: 'createdAt', sortable: false },
    { text: 'Deployed By', value: 'createdByName', sortable: false },
    { text: 'Note', value: 'createdByNote', sortable: false },
  ]
  private deploymentItems: ClientTemplateDeploymentModel[] = []
  private showDeploymentDialog = false
  private templateDetailsPanel: any = [true, false]
  private clientTemplates: ClientTemplateModel[] = []
  private showPrivacyPolicyDialog = false
  private froalaConfig: any = Shared.froalaConfig()
  private defaultErrorColor = '#ff5252'
  private errorColor: any = { hex: this.defaultErrorColor }
  private defaultInputTextColor = '#000000'
  private inputTextColor: any = { hex: this.defaultInputTextColor }

  private emergencyCheckboxes: any = []
  private policyCheckboxes: any = []
  private tempTopEmergencies: EmergencyModel[] = []
  private policyList: PolicyModel[] = []
  private tempPolicySchedules: PolicyModel[] = []
  private tempSelectedFallbackStarredPolicy: number | null = null

  private validationScope = 'frmClientTemplate'

  private signalRHub: SignalRHubConnection = new SignalRHubConnection('clientTemplateUpdate')

  public templateStatusEnum = TemplateStatus

  private mounted() {
    this.resetClientTemplate()
    // reset client template from menu item click
    eventBus.$on('resetClientTemplate', () => {
      this.resetClientTemplate()
    })
  }

  private get portalDomainPrefix(): string {
    return `${Store.Instance.state.Environment.PHPortalsBaseDomain}/`
  }

  private get displayNoticeConfig() {
    return this.displayNoticeDialog ? noticeConfig[this.displayNoticeDialog] : {}
  }

  @Watch('displayNoticeDialog')
  private updateDialogModel(newValue) {
    if (newValue) {
      this.displayNoticeText = this.noticeConfig[newValue].text || ''
    }
  }

  private openStagingInNewTab() {
    if (this.previewUrl) {
      window.open(this.previewUrl, '_blank')
    }
  }

  private sanitizeHTML(value: string): string {
    return Shared.sanitizeHTML(value)
  }

  private async deployTemplateVersion(
    templateVersionReleaseDetail: ClientTemplateReleaseModel,
    deployToEnvironment: DeploymentEnvironment
  ) {
    const req = new PublishClientTemplateRequestModel()
    req.forSubDomain = this.clientTemplateModel.id
    req.updateClientTemplateRequestId = templateVersionReleaseDetail.id
    req.deployToEnvironment = deployToEnvironment

    this.newOrUpdateSubmitted = true
    this.newOrUpdateStatusMsg = 'please wait, submitting request ...'
    this.newOrUpdateStatusMsgIsError = false
    this.updateNote = ''
    this.newOrUpdateShowNote = false
    this.showUpdateDialog = true

    const res = await InsurerController.PublishClientTemplate(req)
    if (!res) {
      this.newOrUpdateStatusMsg = 'error, failed to submit publish request'
      this.newOrUpdateStatusMsgIsError = true
      this.newOrUpdateSubmitted = false
    }
  }

  private pickFile() {
    const logoError = this.$validator.errors.items.find((a) => a.field === 'logo')
    if (logoError) {
      logoError.msg = ''
    }
    const image = this.$refs.image as any
    image.click()
  }

  private pickFavIconFile() {
    const favIconErrorMsg = this.$validator.errors.items.find((a) => a.field === 'favicon')
    if (favIconErrorMsg) {
      favIconErrorMsg.msg = ''
    }
    const favIconImage = this.$refs.favIconImage as any
    favIconImage.click()
  }

  private removeImage() {
    this.imageName = ''

    this.imageUrl = ''
    this.tempLogoURL = ''
    this.primaryColor = { hex: this.defaultPrimaryColor }
    this.secondaryColor = { hex: this.defaultSecondaryColor }
    this.dashboardWidgetColor = this.primaryColor
    this.tempPrimaryColorFromFileUrl = this.primaryColor
    this.tempSecondaryColorFromFileUrl = this.secondaryColor
    const image = this.$refs.image as any
    if (image) {
      image.value = null
    }
    this.clientTemplateModel.logoURL = ''
  }

  private removeFavIconImage() {
    this.favIconImageName = ''
    this.favIconImageUrl = ''
    const favIconImage = this.$refs.favIconImage as any
    if (favIconImage) {
      favIconImage.value = null
    }
    this.clientTemplateModel.favIconURL = ''
  }

  private onFavIconFilePicked(e: any) {
    const files = e.target.files
    const self = this
    if (files[0] !== undefined) {
      self.favIconImageName = files[0].name
      if (self.favIconImageName.lastIndexOf('.') <= 0) {
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        self.favIconImageUrl = fr.result as string
      })
    }
  }

  private onFilePicked(e: any) {
    const files = e.target.files
    const self = this
    if (files[0] !== undefined) {
      self.imageName = files[0].name
      if (self.imageName.lastIndexOf('.') <= 0) {
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        self.imageUrl = fr.result as string
        this.tempLogoURL = this.imageUrl
        this.getPaletteColor(this.imageUrl, false)
      })
    }
  }

  private imageValidationMessage(err: any) {
    if (err && err.collect('logo').length > 0) {
      const message: string = err.collect('logo')[0]
      this.logoErrorMsg =
        message === 'The logo field must be an image.' ? 'Only JPEG, JPG, PNG, GIF files are allowed.' : message
      return true
    }
  }

  private favIconImageValidationMessage(err: any) {
    if (err && err.collect('favicon').length > 0) {
      const message: string = err.collect('favicon')[0]
      this.favIconErrorMsg =
        message === 'The favicon field must be an image.' ? 'Only JPEG, JPG, PNG, GIF files are allowed.' : message
      this.removeFavIconImage()
      return true
    }
  }

  private get LogoSize(): number {
    return Store.Instance.state.Environment.LogoSize
  }

  private get defaultPrimaryColor(): string {
    return Store.Instance.state.Environment.PrimaryColor
  }

  private get defaultSecondaryColor(): string {
    return Store.Instance.state.Environment.SecondaryColor
  }

  private get defaultComplementaryColor(): string {
    return Store.Instance.state.Environment.ComplementaryColor
  }

  private addNewTemplate() {
    this.clientTemplateModel = new ClientTemplateModel()
    this.previewUrl = null
    this.showAddDialog = true
  }

  private async submitUpdateTemplate() {
    const result: boolean = await this.$validator.validateAll('frmUpdateModel')
    if (result) {
      this.newOrUpdateStatusMsgIsError = false
      this.newOrUpdateSubmitted = true
      this.newOrUpdateStatusMsg = 'please wait, submitting request ...'
      this.clientTemplateModel.templateCookieRecord = storeGetters.getTemplateCookieRecord()

      const updateRequest: UpdateClientTemplateRequest = new UpdateClientTemplateRequest()
      updateRequest.request = this.clientTemplateModel
      updateRequest.updateNote = this.updateNote
      try {
        const isSuccess = await InsurerController.UpdateClientTemplate(updateRequest)
        if (isSuccess) {
          const index = this.clientTemplates.findIndex((e) => e.id === this.clientTemplateModel.id)
          if (index !== -1) {
            this.clientTemplates[index].logoURL = this.clientTemplateModel.logoURL
          }
        } else {
          this.newOrUpdateSubmitted = false
          this.newOrUpdateStatusMsg = 'Failed to update client template. Please contact support'
          this.newOrUpdateStatusMsgIsError = true
        }
      } catch (error) {
        const axError = error as AxiosError
        this.snackbarText = axError && axError.response ? axError.response.statusText : 'Unknown Error'
        this.snackbar = true
        this.isSavingChanges = false
        this.newOrUpdateSubmitted = false
        this.newOrUpdateStatusMsg = ''
      }
    }
  }

  private async submitNewTemplate() {
    const result: boolean = await this.$validator.validateAll('frmAddNew')
    if (result) {
      this.newOrUpdateStatusMsgIsError = false
      this.newOrUpdateSubmitted = true
      this.newOrUpdateStatusMsg = 'please wait, submitting request ...'
      const req = new CreateNewClientTemplateRequestModel()
      req.insurerId = this.selectedInsurerId
      req.displayName = this.clientTemplateModel.displayName
      req.subDomain = this.clientTemplateModel.subDomain
      req.fallBackStarPolicy = this.clientTemplateModel.fallBackStarPolicy

      // Send of request and wait for signal R response if successful
      const result = await InsurerController.CreateNewClientTemplate(req)
      if (result !== 'Success') {
        this.newOrUpdateSubmitted = false
        this.newOrUpdateStatusMsg = `Error creating client template - ${result}, please contact support`
        this.newOrUpdateStatusMsgIsError = true
      }
    }
  }

  // SignalR event handler for updating client templates
  private async updateClientTemplateResponse(status: string) {
    if (status !== 'Success') {
      // error case
      this.newOrUpdateSubmitted = false
      this.newOrUpdateStatusMsg = 'Error updating client template, please contact support'
      this.newOrUpdateStatusMsgIsError = true
      return
    }

    // if success - then wait for signal R deployment response.
    this.newOrUpdateStatusMsg = 'please wait, publishing site ...'
  }

  private async publishClientTemplateResponse(status: string, subDomain: string) {
    if (status !== 'Success') {
      // error case
      this.newOrUpdateSubmitted = false
      this.newOrUpdateStatusMsg = 'Error publishing client template, please contact support'
      this.newOrUpdateStatusMsgIsError = true
      return
    }

    this.newOrUpdateStatusMsg = 'please wait, site deployment successful, downloading record ...'
    const ct: ClientTemplateModel | null = await InsurerController.GetClientTemplate(subDomain)
    const index = ct ? this.clientTemplates.findIndex((c) => c.id === ct.id) : -1
    if (ct) {
      index !== -1 ? (this.clientTemplates[index] = ct) : this.clientTemplates.push(ct)
    } else {
      this.newOrUpdateSubmitted = false
      this.newOrUpdateStatusMsg = 'error downloading client detail, please contact support'
      this.newOrUpdateStatusMsgIsError = true
      return
    }

    // got the detail, load it and show the edit window
    this.newOrUpdateStatusMsg = 'please wait, loading record ...'
    await this.onEditTemplate(ct)
    this.newOrUpdateSubmitted = false
    this.showAddDialog = false
    this.showUpdateDialog = false
  }

  private async waitAsync(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  private onEmergencySelection(emergency: EmergencyModel): void {
    Vue.set(emergency, 'isLocal', !emergency.isLocal)
  }

  private get getStarredPolicy(): PolicyModel[] {
    return this.polices.filter(
      (policy: PolicyModel) =>
        policy.policyBasicDetail.isStarred &&
        (!policy.policyBasicDetail.isDeleted ||
          (policy.policyBasicDetail.isDeleted &&
            this.tempSelectedFallbackStarredPolicy &&
            this.tempSelectedFallbackStarredPolicy === policy.policyBasicDetail.policyId))
    )
  }

  private get emergencyTypes() {
    this.allEmergencyTypes = storeGetters
      .getEmergencies()
      .filter(
        (e) =>
          !e.hideOnCustomerPortals &&
          (!e.isDeleted ||
            (e.isDeleted && this.tempTopEmergencies.findIndex((t) => t.emergencyId === e.emergencyId) !== -1))
      )
    this.emergencyCheckboxes = this.allEmergencyTypes.map((code) => {
      return {
        checked: false,
      }
    })
    this.topEmergencies.forEach((t) => {
      this.emergencyCheckboxes[this.allEmergencyTypes.indexOf(t)].checked =
        this.allEmergencyTypes.indexOf(t) !== -1 ? true : false
    })
    return storeGetters.getEmergencies()
  }

  private setEmergencies() {
    PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        this.allEmergencyTypes = res.filter((e) => !e.hideOnCustomerPortals)
        storeMutations.setEmergencies(res)
      }
    })
  }

  private onChangeEmergencyType(previouslySelectedItems: EmergencyModel[], currentItems: EmergencyModel[]) {
    this.allEmergencyTypes = Shared.getSelectedItemFirstInList(
      this.allEmergencyTypes,
      currentItems.map((a) => a.emergencyId),
      'emergencyId'
    )
  }

  private async validate(): Promise<boolean> {
    const cookieSelection = this.$refs.cookieSelection as CookieSelection
    const clientTemplateValidation: boolean = await this.$validator.validateAll(this.validationScope)
    const cookieSelectionValidation: boolean = await cookieSelection.validateAll()

    const fullValidation = clientTemplateValidation && cookieSelectionValidation
    // set focus to non validate field
    if (!fullValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return fullValidation
  }

  private submit() {
    this.validate().then((result: boolean) => {
      if (result) {
        this.newOrUpdateSubmitted = false
        this.newOrUpdateStatusMsg = ''
        this.newOrUpdateShowNote = true
        this.updateNote = ''
        this.showUpdateDialog = true
        this.isSavingChanges = false
      } else {
        alert(this.$validator.errors.items[0].msg)
      }
    })
  }

  private newOrUpdateCancelled() {
    this.showAddDialog = false
    this.showUpdateDialog = false
  }

  private resetClientTemplate() {
    this.previewUrl = null
    this.historyItems = []
    this.deploymentItems = []
    this.showDeploymentDialog = false
    this.editTemplate = false
    this.showAddDialog = false
    this.newOrUpdateSubmitted = false
    this.newOrUpdateStatusMsg = ''
    this.showUpdateDialog = false
    this.updateNote = ''
    this.newOrUpdateStatusMsgIsError = false

    // fill default colors
    this.templateSitePrimaryColor
      ? (this.primaryColor = { hex: this.templateSitePrimaryColor })
      : (this.primaryColor = { hex: this.defaultPrimaryColor })
    this.templateSiteSecondaryColor
      ? (this.secondaryColor = { hex: this.templateSiteSecondaryColor })
      : (this.secondaryColor = { hex: this.defaultSecondaryColor })
    this.templateSiteComplementaryColor
      ? (this.complementaryColor = { hex: this.templateSiteComplementaryColor })
      : (this.complementaryColor = { hex: this.defaultComplementaryColor })
    this.templateSitePrimaryColor
      ? (this.dashboardWidgetColor = { hex: this.templateSitePrimaryColor })
      : (this.dashboardWidgetColor = { hex: this.defaultPrimaryColor })
    this.imageName = ''
    this.templateSiteLogo ? (this.imageUrl = this.templateSiteLogo) : (this.imageUrl = '')
    const img = this.$refs.image as any
    if (img) {
      img.value = null
    }
    this.favIconImageName = ''
    this.favIconImageName = ''
    const favIconImage = this.$refs.favIconImage as any
    if (favIconImage) {
      favIconImage.value = null
    }

    this.templateSiteFallbackStarredPolicy
      ? (this.selectedFallbackStarredPolicy = this.templateSiteFallbackStarredPolicy)
      : (this.selectedFallbackStarredPolicy = null)
    this.templateSitePolicySchedules
      ? (this.policySchedules = this.templateSitePolicySchedules)
      : (this.policySchedules = [])
    // fill default emergencies received at a time of new template creation
    this.templateSiteEmergencies ? (this.topEmergencies = this.templateSiteEmergencies) : (this.topEmergencies = [])

    // added for select logo from existing policy urls
    this.allowToSelectLogo = false
    this.selectedPolicyLogo = null
    this.tempPolicyLogoUrl = ''
    this.tempLogoURL = ''
    this.tempPrimaryColorFromFileUrl = { hex: this.defaultPrimaryColor }
    this.tempSecondaryColorFromFileUrl = { hex: this.defaultSecondaryColor }
    this.tempPrimaryColorFromPolicyLogo = { hex: this.defaultPrimaryColor }
    this.tempSecondaryColorFromPolicyLogo = { hex: this.defaultSecondaryColor }
    Object.keys(this.noticeConfig).forEach((key) => (this.noticeConfig[key].text = ''))
    this.isSavingChanges = false
    this.errorColor = { hex: this.defaultErrorColor }
    this.inputTextColor = { hex: this.defaultInputTextColor }
    this.tempPolicySchedules = []
    this.tempTopEmergencies = []
    this.policyList = []
    this.tempSelectedFallbackStarredPolicy = null
    setTimeout(() => {
      this.$validator.errors.items = []
    }, 0)
  }

  private cancel() {
    this.resetClientTemplate()
  }

  @Watch('templates')
  private onTemplateListChange() {
    this.clientTemplates = this.templates
  }

  private async onEditTemplate(ct: ClientTemplateModel) {
    this.clientTemplateModel = JSON.parse(JSON.stringify(ct))
    const deploymentsAndReleases = await Promise.all([
      InsurerController.GetClientTemplateDeployments(this.clientTemplateModel.id),
      InsurerController.GetClientTemplateReleaseHistory(this.clientTemplateModel.id),
    ])

    this.historyItems = deploymentsAndReleases[1]
    this.deploymentItems = deploymentsAndReleases[0]
    this.editTemplate = true

    if (this.clientTemplateModel.subDomain) {
      const portalDomain = Store.Instance.state.Environment.PHPortalsBaseDomain
      this.previewUrl =
        this.clientTemplateModel.subDomain === 'www'
          ? `https://www.${portalDomain}/preview`
          : `https://${portalDomain}/${this.clientTemplateModel.subDomain}/preview`
    }
    this.imageName =
      this.clientTemplateModel.logoURL &&
      this.clientTemplateModel.logoURL !== '' &&
      this.clientTemplateModel.logoURL !== null
        ? this.clientTemplateModel.logoURL.replace(/^.*[\\/]/, '')
        : ''
    this.imageUrl = this.clientTemplateModel.logoURL ? this.clientTemplateModel.logoURL : ''
    this.tempLogoURL = this.imageUrl
    this.favIconImageName =
      this.clientTemplateModel.favIconURL &&
      this.clientTemplateModel.favIconURL !== '' &&
      this.clientTemplateModel.favIconURL !== null
        ? this.clientTemplateModel.favIconURL.replace(/^.*[\\/]/, '')
        : ''
    this.favIconImageUrl = this.clientTemplateModel.favIconURL ? this.clientTemplateModel.favIconURL : ''
    this.selectedFallbackStarredPolicy = this.clientTemplateModel.fallBackStarPolicy
      ? this.clientTemplateModel.fallBackStarPolicy
      : null
    this.tempSelectedFallbackStarredPolicy = this.selectedFallbackStarredPolicy
    if (this.clientTemplateModel.primaryColor) {
      this.primaryColor = {}
      this.primaryColor.hex = this.clientTemplateModel.primaryColor
    }
    if (this.clientTemplateModel.secondaryColor) {
      this.secondaryColor = {}
      this.secondaryColor.hex = this.clientTemplateModel.secondaryColor
    }
    if (this.clientTemplateModel.complementaryColor) {
      this.complementaryColor = {}
      this.complementaryColor.hex = this.clientTemplateModel.complementaryColor
    }
    if (this.clientTemplateModel.dashboardWidgetColor) {
      this.dashboardWidgetColor = {}
      this.dashboardWidgetColor.hex = this.clientTemplateModel.dashboardWidgetColor
    }

    Vue.set(this.primaryColor, 'hex', this.clientTemplateModel.primaryColor)
    Vue.set(this.secondaryColor, 'hex', this.clientTemplateModel.secondaryColor)
    Vue.set(this.complementaryColor, 'hex', this.clientTemplateModel.complementaryColor)
    Vue.set(this.dashboardWidgetColor, 'hex', this.clientTemplateModel.dashboardWidgetColor)
    if (this.clientTemplateModel.errorColor) {
      this.errorColor = {}
      this.errorColor.hex = this.clientTemplateModel.errorColor
    }
    Vue.set(this.errorColor, 'hex', this.clientTemplateModel.errorColor)
    this.topEmergencies = []
    this.tempTopEmergencies = []
    if (this.clientTemplateModel.inputTextColor) {
      this.inputTextColor = {}
      this.inputTextColor.hex = this.clientTemplateModel.inputTextColor
    }
    Vue.set(this.inputTextColor, 'hex', this.clientTemplateModel.inputTextColor)

    if (this.clientTemplateModel.topEmergencies && this.clientTemplateModel.topEmergencies.length > 0) {
      this.clientTemplateModel.topEmergencies.forEach((topEmergency) => {
        const emergency: EmergencyModel | undefined = this.allEmergencyTypes.find(
          (emergencyType) => emergencyType.emergencyId === topEmergency
        )
        if (emergency !== undefined) {
          this.topEmergencies.push(emergency)
        }
      })
      this.tempTopEmergencies = JSON.parse(JSON.stringify(this.topEmergencies))
    }
    this.policySchedules = []
    this.tempPolicySchedules = []
    if (this.clientTemplateModel.policySchedules && this.clientTemplateModel.policySchedules.length > 0) {
      this.clientTemplateModel.policySchedules.forEach((policySchedule) => {
        const policy: PolicyModel | undefined = this.polices.find(
          (e) => e.policyBasicDetail.policyId === policySchedule
        )
        if (policy !== undefined) {
          this.policySchedules.push(policy)
        }
      })
      this.tempPolicySchedules = JSON.parse(JSON.stringify(this.policySchedules))
    }
    this.tempPrimaryColorFromFileUrl = this.primaryColor
    this.tempSecondaryColorFromFileUrl = this.secondaryColor

    // Set the text values to the values on the model
    Object.keys(this.noticeConfig).forEach((noticeKey) => {
      const notice = this.noticeConfig[noticeKey]
      notice.text = this.clientTemplateModel[notice.textKey]
    })
  }

  private get isLogoRequired() {
    if (!this.allowToSelectLogo) {
      return this.imageUrl === '' ? true : false
    } else {
      return this.imageUrl === '' && this.imageName === '' ? true : false
    }
  }

  private get isPolicyLogoUrlRequired() {
    if (this.allowToSelectLogo) {
      return this.imageUrl === '' ? true : false
    } else {
      return this.imageUrl === '' && this.imageName === '' ? true : false
    }
  }

  @Watch('primaryColor')
  private primaryColorChanged(): void {
    this.sendSettingToFrame('primaryColor', this.primaryColor.hex)
    this.clientTemplateModel.primaryColor = this.primaryColor.hex
  }

  @Watch('secondaryColor')
  private secondaryColorChanged(): void {
    this.sendSettingToFrame('secondaryColor', this.secondaryColor.hex)
    this.clientTemplateModel.secondaryColor = this.secondaryColor.hex
  }

  @Watch('complementaryColor')
  private complementaryColorChanged(): void {
    this.sendSettingToFrame('complementaryColor', this.complementaryColor.hex)
    this.clientTemplateModel.complementaryColor = this.complementaryColor.hex
  }

  @Watch('imageUrl')
  private logoUrlChanged(): void {
    this.sendSettingToFrame('logoURL', this.imageUrl)
    this.clientTemplateModel.logoURL = this.imageUrl
  }

  @Watch('favIconImageUrl')
  private favIconImageUrlChanged(): void {
    this.sendSettingToFrame('favIconURL', this.favIconImageUrl)
    this.clientTemplateModel.favIconURL = this.favIconImageUrl
  }

  @Watch('topEmergencies')
  private topEmergenciesChanged(): void {
    const emergencyIds = this.topEmergencies.map((x) => x.emergencyId)
    this.sendSettingToFrame('topEmergencies', emergencyIds)
    this.clientTemplateModel.topEmergencies = emergencyIds
  }

  @Watch('selectedFallbackStarredPolicy')
  private selectedFallbackStarredPolicyChanged(): void {
    // send selected FallbackStarredPolicy id
    this.sendSettingToFrame('fallBackStarPolicy', this.selectedFallbackStarredPolicy)
    if (this.selectedFallbackStarredPolicy) {
      this.clientTemplateModel.fallBackStarPolicy = this.selectedFallbackStarredPolicy
    }
  }

  @Watch('policySchedules')
  private policySchedulesChanged(): void {
    const policyScheduleIds = this.policySchedules.map((x) => x.policyBasicDetail.policyId)
    this.sendSettingToFrame('policySchedules', policyScheduleIds)
    this.clientTemplateModel.policySchedules = policyScheduleIds
  }

  @Watch('inputTextColor')
  private inputTextColorChanged(): void {
    this.sendSettingToFrame('inputTextColor', this.inputTextColor.hex)
    this.clientTemplateModel.inputTextColor = this.inputTextColor.hex
  }

  private sendSettingToFrame(name: string, value: any): void {
    if (this.frame && this.frame.contentWindow && this.previewUrl) {
      const msg: ICommandMessage = {
        command: 'UpdateSetting',
        data: { name, value },
      }
      this.frame.contentWindow.postMessage(msg, this.previewUrl)
    }
  }

  @Watch('polices')
  private onPoliciesChanged() {
    this.existingPolicyLogoUrls = []
    this.polices.forEach((policy) => {
      if (policy.policyBasicDetail.fileURL) {
        const fileObj: any = {}
        fileObj.fileURL = policy.policyBasicDetail.fileURL
        fileObj.policyName = policy.policyBasicDetail.name
        this.existingPolicyLogoUrls.push(fileObj)
      }
    })
  }

  @Watch('errorColor')
  private errorColorChanged(): void {
    this.sendSettingToFrame('ErrorColor', this.errorColor.hex)
    this.clientTemplateModel.errorColor = this.errorColor.hex
  }

  private getPaletteColor(imageUrl: string, isPolicyLogoUrl: boolean) {
    this.Vibrant.from(imageUrl).getPalette((err, palette) => {
      if (err) {
        this.templateSitePrimaryColor
          ? (this.primaryColor = { hex: this.templateSitePrimaryColor })
          : (this.primaryColor = { hex: this.defaultPrimaryColor })
        this.templateSiteSecondaryColor
          ? (this.secondaryColor = { hex: this.templateSiteSecondaryColor })
          : (this.secondaryColor = { hex: this.defaultSecondaryColor })
      } else {
        const swatchVibrant = 'Vibrant'
        const swatchLightVibrant = 'LightVibrant'
        const swatchMuted = 'Muted'
        const swatchLightMuted = 'LightMuted'

        if (palette && Object.prototype.hasOwnProperty.call(palette, swatchVibrant) && palette[swatchVibrant]) {
          this.primaryColor = { hex: palette[swatchVibrant].getHex() }
          this.dashboardWidgetColor = this.primaryColor
        } else if (
          palette &&
          Object.prototype.hasOwnProperty.call(palette, swatchLightMuted) &&
          palette[swatchLightMuted]
        ) {
          this.primaryColor = { hex: palette[swatchLightMuted].getHex() }
          this.dashboardWidgetColor = this.primaryColor
        }

        if (palette && Object.prototype.hasOwnProperty.call(palette, swatchMuted) && palette[swatchMuted]) {
          this.secondaryColor = { hex: palette[swatchMuted].getHex() }
        } else if (
          palette &&
          Object.prototype.hasOwnProperty.call(palette, swatchLightVibrant) &&
          palette[swatchLightVibrant]
        ) {
          this.secondaryColor = { hex: palette[swatchLightVibrant].getHex() }
        }
        if (isPolicyLogoUrl) {
          this.tempPrimaryColorFromPolicyLogo = this.primaryColor
          this.tempSecondaryColorFromPolicyLogo = this.secondaryColor
        } else {
          this.tempPrimaryColorFromFileUrl = this.primaryColor
          this.tempSecondaryColorFromFileUrl = this.secondaryColor
        }
      }
    })
  }

  private onChangeAllowToSelectLogo() {
    this.imageUrl = this.allowToSelectLogo ? this.tempPolicyLogoUrl : this.tempLogoURL
    this.primaryColor = this.allowToSelectLogo ? this.tempPrimaryColorFromPolicyLogo : this.tempPrimaryColorFromFileUrl
    this.secondaryColor = this.allowToSelectLogo
      ? this.tempSecondaryColorFromPolicyLogo
      : this.tempSecondaryColorFromFileUrl
    this.dashboardWidgetColor = this.primaryColor
  }

  private onSelectLogoFromExistingUrls(fileObj: any) {
    this.imageUrl = fileObj.fileURL
    this.tempPolicyLogoUrl = this.imageUrl
    this.getPaletteColor(this.imageUrl, true)
  }

  private get isUserRoleClientManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }

  private ValidateEditor(label: string): string {
    const message: string = this.$validator.errors.collect(label)[0]
    return message
  }

  // Set the value on the model to the local text value
  private updateNotice() {
    if (this.displayNoticeDialog) {
      this.noticeConfig[this.displayNoticeDialog].text = this.displayNoticeText
      const cleanHtml = this.sanitizeHTML(this.displayNoticeConfig.text)
      this.clientTemplateModel[this.displayNoticeConfig.textKey] = cleanHtml
      this.sendSettingToFrame(this.displayNoticeConfig.textKey, cleanHtml)
      this.displayNoticeDialog = null
    }
  }

  private get getPolicyList() {
    this.policyList = this.polices.filter(
      (p) =>
        !p.policyBasicDetail.isDeleted ||
        (p.policyBasicDetail.isDeleted &&
          this.tempPolicySchedules.findIndex((ps) => ps.policyBasicDetail.policyId === p.policyBasicDetail.policyId) !==
            -1)
    )
    this.policyCheckboxes = this.policyList.map((code) => {
      return {
        checked: false,
      }
    })
    this.policySchedules.forEach((t) => {
      this.policyCheckboxes[this.policyList.indexOf(t)].checked = this.policyList.indexOf(t) !== -1 ? true : false
    })
    return this.policyList
  }

  private setupSignalR() {
    this.signalRHub.addHandler('processUpdateClientTemplate', this.updateClientTemplateResponse)
    this.signalRHub.addHandler('processPublishClientTemplate', this.publishClientTemplateResponse)
    this.signalRHub.connect() // connect signalR Hub
  }

  private created() {
    if (this.emergencyTypes.length === 0) {
      this.setEmergencies()
    }
    this.setupSignalR()
  }

  public resetHoldingMessage(status: string) {
    if (status !== 'TemporaryOffline') {
      this.clientTemplateModel.holdingPageMessage = ''
    }
  }

  public get templateStatuses() {
    return Object.values(TemplateStatus)
  }
}
</script>

<style>
.nsmError {
  color: red;
}
.color-picker-menu .list__tile {
  height: auto !important;
  padding: 0px;
}
</style>

<style scoped>
.portal-form {
  min-width: 100%;
}
.client-section .inner-content {
  display: flex;
  height: 200px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}
.client-section .inner-content >>> .v-icon {
  margin: auto;
  font-size: 50px;
  transition: 0.3s all linear;
}
.client-section .inner-content img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  object-fit: contain;
  padding: 10px;
}
.client-section .inner-content .default-img {
  width: 200px;
}
.client-section .inner-content:hover .v-icon {
  font-size: 65px;
}
.client-section .clinet-inner-content {
  height: 100%;
}
.make-portal-templates {
  position: relative;
}
.make-template {
  position: absolute;
  left: 24px;
  top: 0;
  bottom: 0px;
  right: 24px;
  background-color: #fff;
  -webkit-transition: 0.3s all linear;
  transition: 0.3s all linear;
  -webkit-transform: scale(0);
  transform: scale(0);
  bottom: 0;
  overflow: auto;
}
.make-template::-webkit-scrollbar {
  width: 7px;
}
.make-template::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 10px;
}
.make-template.add-newtempalte {
  transform: scale(1);
}
.url-bar {
  padding: 1px 8px 0px 8px;
  background-color: #eee !important;
  text-align: center;
  z-index: 1;
  position: relative;
  left: 8px;
  width: calc(100% - 38px);
}
.url-bar .custom-text-filed {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  background-color: #fff;
  width: 95%;
  display: inline-block;
  padding: 3px 20px;
  margin: auto;
  height: 30px;
  margin: 8px 0px 4px;
  border-radius: 15px;
  cursor: pointer;
}
.url-bar >>> input {
  background-color: #fff;
  border-radius: 15px;
  padding: 0px 15px;
}
/* .url-bar >>> .input-group__input{padding:0px 5px;} */
.url-bar >>> .subdomain-text {
  color: #c9004b;
  font-weight: 600;
}
.template-tab > .v-tabs__items {
  width: 100%;
}
.show-color {
  height: 16px;
  width: 16px;
  display: inline-block;
}
.color-picker-menu >>> .v-list__tile__title {
  height: auto !important;
}
.color-picker-menu >>> .v-list__tile {
  height: auto !important;
  padding: 16px;
}
.color-input {
  position: relative;
}
.color-picker-menu {
  min-height: auto;
  position: absolute;
}
.color-input >>> .v-menu {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 14px;
  background-color: #eee;
  padding: 4px 4px 0px;
}
.preview-template >>> .Emergency-type .v-list > div,
.preview-template >>> .upload-files > div {
  width: 50% !important;
}
.preview-template >>> .Emergency-type .v-list > div {
  margin-bottom: 8px !important;
}
.preview-template >>> .display-1 {
  font-size: 20px !important;
  line-height: 1.3 !important;
}
.preview-template >>> .add-emergency label.pl-3 {
  padding-left: 0px !important;
}
.preview-template >>> .Emergency-type .v-list .v-list__tile {
  padding: 0px 4px !important;
}
.preview-template >>> .content-section.pa-4 {
  padding: 0px !important;
  padding-top: 8px !important;
}
.preview-template >>> .toolbar__content {
  margin-top: 8px;
}
.preview-template >>> .client-details .sm6 {
  max-width: 100% !important;
  width: 100% !important;
  flex-basis: 100% !important;
}
.preview-template >>> .client-details .sm3 {
  max-width: 50% !important;
  width: 50% !important;
  flex-basis: 50% !important;
}
.preview-template >>> .expansion-panel__header .title {
  font-size: 18px !important;
}
.preview-template >>> header .toolbar__title {
  max-width: 170px;
}
.make-template.add-newtempalte .container.grid-list-lg {
  position: relative;
  height: 100%;
}
.preview-content {
  overflow: hidden;
  position: absolute;
  left: 15px;
  top: 0px;
}
.mobile-section-content {
  height: 100%;
  width: 389px;
  margin: auto;
  position: relative;
  padding-left: 12px;
  padding-top: 63px;
  padding-bottom: 131px;
  height: 749px;
}
.back-mobile {
  background-image: url(/img/mobile-frame.png);
  background-size: contain;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.scroll-content {
  padding-right: 22px !important;
  padding-left: 17px !important;
}
.mobile-section-content >>> .vb > .vb-dragger {
  right: 30px !important;
}
.make-portal-templates > div {
  height: 100%;
}
.portal-form {
  padding-left: 31em;
  padding-top: 15px;
}
.policy-logo-clienttemplate >>> .v-avatar {
  height: 40px !important;
  width: 90px !important;
  border-radius: 0px !important;
  position: absolute;
  left: 0;
  top: 35px;
  z-index: 0;
  display: inline-block;
}
.policy-logo-clienttemplate >>> .v-avatar img {
  border-radius: 0px !important;
  object-fit: contain;
  border: 1px solid #e0e0e0;
}
.policy-logo-client >>> .input-group--select .input-group__selections {
  overflow: visible !important;
}
.policy-logo-select {
  height: 40px;
  width: 100px;
  margin-right: 10px;
}
.policy-logo-select img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #dcdcdc;
}
.policy-logo-select >>> .v-input__control .v-input__slot {
  margin-bottom: 5px;
}
.light-background {
  background-color: rgba(255, 255, 255, 0.2);
}
.insurer-preview-window {
  display: inline-block;
  left: 8px;
  top: 0px;
  right: 8px;
}
.sys-call-content .num,
.sys-call-content .time {
  font-size: 18px;
}
.sys-call-info {
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sys-call-info > .sys-call-content {
  -webkit-box-flex: 6;
  -ms-flex: 6;
  flex: 6;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.tile-preview {
  position: relative;
}
.insurer-preview-window h2 {
  width: calc(100% - 120px);
  display: inline-block;
  font-weight: 100;
  font-size: 14px;
}
.day-counts-section {
  display: inline-block;
  padding-top: 5px;
  padding-right: 5px;
  vertical-align: top;
}
.day-counts-section > span {
  height: 35px;
  width: 35px;
  display: inline-block;
  padding: 7px 0px;
  margin: 0px 2px;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.day-counts-section > span:last-child {
  margin-right: 0px;
}
.day-counts-section .count {
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
}
.day-counts-section .v-icon {
  font-size: 15px;
  line-height: 1.4;
  position: relative;
  top: -1px;
}
.graph-image {
  margin-top: 15px;
}
.graph-image img {
  opacity: 0.5;
  width: 100%;
}
.bar-chart img {
  height: 28px;
  opacity: 0.7;
}
.sub-domain {
  position: relative;
}
.sub-domain .icons {
  position: absolute;
  top: 16px;
  right: 0px;
  height: 28px;
}
.erorr-msg {
  position: absolute;
  left: 0px;
  bottom: 0px;
  font-size: 12px;
}
.icon-validation .input-group__details {
  display: flex;
}
.img-with-input {
  min-height: 84px;
}
.underline {
  text-decoration: underline;
}
.portal-name {
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-height: 864px) {
  .mobile-section-content {
    width: 367px;
    height: 725px;
    padding-top: 59px;
  }
  .url-bar {
    width: calc(100% - 29px);
  }
  .scroll-content {
    padding-right: 13px !important;
    padding-left: 15px !important;
  }
  .mobile-section-content >>> .vb > .vb-dragger {
    right: 21px !important;
  }
  .client-section .insureractions >>> .v-btn {
    margin: 6px 5px;
  }
  .client-section .insureractions >>> .v-btn .v-btn__content {
    padding: 0 8px;
  }
  .emergency-details .time-access-content >>> .input-group label {
    max-width: 55%;
  }
}
@media (max-height: 768px) {
  .mobile-section-content {
    width: 321px;
    height: 645px;
    padding-top: 51px;
  }
  .url-bar {
    width: calc(100% - 26px);
  }
  .scroll-content {
    padding-right: 10px !important;
    padding-left: 12px !important;
  }
  .mobile-section-content >>> .vb > .vb-dragger {
    right: 18px !important;
  }
}
@media (max-height: 720px) {
  .mobile-section-content {
    width: 293px;
    height: 597px;
    padding-top: 47px;
  }
  .url-bar {
    width: calc(100% - 21px);
    left: 4px;
  }
  .scroll-content {
    padding-right: 10px !important;
    padding-left: 12px !important;
  }
  .mobile-section-content >>> .vb > .vb-dragger {
    right: 18px !important;
  }
}
@media (max-width: 1263px) {
  .insurer-preview-window {
    left: -476%;
    right: auto;
    min-width: 151px;
    top: 70px;
  }
  .vc-chrome {
    width: 175px;
  }
}
@media (max-width: 1100px) {
  .day-counts-section {
    display: block;
  }
  .insurer-preview-window h2 {
    display: block;
    width: 100%;
  }
}
</style>
